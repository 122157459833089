import axios from "axios";

import SERVER_CONSTANTS from "../../constants/airstoreServer";

export const USER_LOADING = "USER_LOADING";
export const FETCHED_PROFILE = "FETCHED_PROFILE";

////////// FETCH PROFILE ////////////////////////////////////////////
export const fetchProfile = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    try {
      dispatch({ type: USER_LOADING });
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/users/get-profile",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (!response.data.profile) {
      }

      // Dispatch information to redux state
      dispatch({
        type: FETCHED_PROFILE,
        profile: response.data.profile,
        notifications: response.data.notifications,
      });
      return;
    } catch (error) {
      console.log(error);
    }
  };
};
