import React from "react";
import { StyleSheet, View } from "react-native";

// IMPORT GLOBAL STYLES
import Colors from "../../styles/Colors";

const ListRow = (props) => {
  return <View style={[styles.listRow, props.style]}>{props.children}</View>;
};

const styles = StyleSheet.create({
  listRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: 5,
    marginBottom: 5,
    borderBottomColor: Colors.lightBorder,
    borderBottomWidth: 1,
  },
});

export default ListRow;
