import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  FlatList,
  Text,
  ActivityIndicator,
  Dimensions,
} from "react-native";

// IMPORT GLOBAL COMPONENTS
import Screen from "../../components/containers/Screen";
import Divider from "../../components/dividers/Divider";
import Card from "../../components/containers/Card";
import Ionicons from "@expo/vector-icons/Ionicons";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// IMPORT SECTION COMPONENTS
import OrderRow from "../components/OrderRow";

// Importing Redux state components
import { connect, useDispatch } from "react-redux";
import * as ordersActions from "../../redux/actions/orders";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    currentChain: state.chains.currentChain,
    chainOrders: state.orders.chainOrders,
    isFetchingOrders: state.orders.isFetchingOrders,
    isError: state.orders.isError,
    errorMessage: state.orders.errorMessage,
  };
};

///////////// START FUNCTION CLASS //////////////////
const OrdersScreen = (props) => {
  const dispatch = useDispatch();

  // Fetch the chain orders
  useEffect(() => {
    dispatch(ordersActions.fetchChainOrders());
  }, [props.currentChain]);

  // RENDER LIST
  const renderList = (itemData) => {
    return <OrderRow order={itemData.item} />;
  };

  // DETERMINE SCREEN SIZE
  const [device, setDevice] = useState("mobile");

  useEffect(() => {
    const { height, width } = Dimensions.get("window");
    const aspectRatio = height / width;
    // mobile device
    if (aspectRatio > 1.6) {
      setDevice("mobile");
      // tablet
    } else if (aspectRatio > 1) {
      setDevice("tablet");
      // desktop
    } else {
      setDevice("desktop");
    }
  }, []);

  //////////// RETURN VIEW //////////////////////
  const tableHeaders = [
    { title: "Source", flexSize: 1 },
    { title: "Date", flexSize: 1 },
    { title: "Order", flexSize: 3 },
    { title: "Status", flexSize: 3 },
    { title: "Total", flexSize: 1 },
  ];
  return (
    <Screen>
      <View style={[Styles.row, styles.header]}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text style={Styles.H3}>Online Orders</Text>
        </View>
        <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
          <Ionicons
            name={"refresh-outline"}
            size={25}
            onPress={() => dispatch(ordersActions.fetchChainOrders())}
          />
        </View>
      </View>
      <Divider />
      <Card style={styles.ordersSection}>
        {device === "mobile" ? null : (
          <View style={Styles.row}>
            {tableHeaders.map((header) => (
              <View style={[styles.col, { flex: header.flexSize }]}>
                <Text style={styles.tableHeader}>{header.title}</Text>
              </View>
            ))}
          </View>
        )}
        <Divider />
        {props.isFetchingOrders ? (
          <ActivityIndicator size="large" color={Colors.primary} />
        ) : props.isError ? (
          <Text style={styles.errorMessage}>{props.errorMessage}</Text>
        ) : (
          <FlatList
            key={"list"}
            data={props.chainOrders}
            keyExtractor={(item) => item.id}
            renderItem={renderList}
            contentContainerStyle={Styles.listContainer}
          />
        )}
      </Card>
    </Screen>
  );
};

const styles = StyleSheet.create({
  header: {
    marginTop: 10,
  },

  ordersSection: {
    marginVertical: 10,
  },

  col: {
    justifyContent: "center",
    alignItems: "flex-start",
    paddingStart: 10,
  },

  tableHeader: {
    fontSize: 14,
    fontWeight: "600",
  },

  screen: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },

  errorMessage: {
    fontSize: 20,
    color: Colors.accent,
    fontWeight: 600,
    marginVertical: 20,
  },
});

export default connect(mapStateToStore)(OrdersScreen);
