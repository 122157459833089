import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Platform } from "react-native";
import { CheckBox } from "react-native-elements";

//// IMPORT FORM LIBS
import { Formik } from "formik";
import * as yup from "yup";

// Importing Redux state components
import { connect, useDispatch } from "react-redux";
import * as authActions from "../../redux/actions/auth";

// IMPORT GLOBAL COMPONENTS
import InputText from "../../components/form/InputText";
import FlatButton from "../../components/buttons/FlatButton";
import AttentionBox from "../../components/containers/AttentionBox";
import SuccessBox from "../../components/containers/SuccessBox";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

const loginSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email")
    .required("Please enter your email"),
  password: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&~])[A-Za-z\d@$!%*#?&~]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
});

// Connect login screen with the redux state
const mapStateToStore = (state) => {
  return {
    successMessage: state.auth.successMessage,
    isError: state.auth.isError,
    errorMessage: state.auth.errorMessage,
    isEmailError: state.auth.isEmailError,
  };
};

////////// START FUNCTION CLASS /////////
const LoginScreen = (props) => {
  // Initiate dispatch
  const dispatch = useDispatch();

  //////////// RETURN VIEW ////////
  return (
    <View style={styles.content}>
      {/* Messages section */}
      {props.successMessage ? (
        <SuccessBox>
          <Text>{props.successMessage}</Text>
        </SuccessBox>
      ) : props.isError ? (
        <AttentionBox>
          <Text>{props.errorMessage}</Text>
        </AttentionBox>
      ) : null}
      {/* Form section */}
      <Formik
        initialValues={{
          email: props.userEmail,
          password: "",
          rememberMe: true,
        }}
        validationSchema={loginSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await dispatch(
            authActions.login(values.email, values.password, values.rememberMe)
          );
          setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <View style={styles.form}>
            <InputText
              containerStyle={{ marginBottom: 10 }}
              placeholder="Email"
              onChangeText={formikProps.handleChange("email")}
              value={formikProps.values.email}
              onBlur={formikProps.handleBlur("email")}
              errorMessage={
                formikProps.touched.email && formikProps.errors.email
              }
              editable={props.userEmail ? false : true}
              selectTextOnFocus={props.userEmail ? false : true}
              returnKeyType="next"
            />
            <InputText
              containerStyle={{ marginBottom: 10 }}
              placeholder="Password"
              onChangeText={formikProps.handleChange("password")}
              value={formikProps.values.password}
              onBlur={formikProps.handleBlur("password")}
              errorMessage={
                formikProps.touched.password && formikProps.errors.password
              }
              secureTextEntry={true}
              returnKeyType="send"
              onSubmitEditing={formikProps.handleSubmit}
            />
            {/* Checkbox from react native element */}
            <CheckBox
              title="Remember me"
              checked={formikProps.values.rememberMe}
              onIconPress={() =>
                formikProps.setFieldValue(
                  "rememberMe",
                  !formikProps.values.rememberMe
                )
              }
              containerStyle={styles.checkbox}
            />
            <Text>{formikProps.values.rememberMe}</Text>
            <View style={(Styles.col, { flex: 1 })}>
              <FlatButton
                title="Login"
                onPress={formikProps.handleSubmit}
                isLoading={formikProps.isSubmitting}
                buttonStyle={{ height: 55 }}
              />
            </View>
          </View>
        )}
      </Formik>
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    width: "100%",
    alignItems: "center",
  },

  form: {
    width: "100%",
  },

  checkbox: {
    backgroundColor: "transparent",
    borderWidth: 0,
    padding: 0,
  },
});

export default connect(mapStateToStore)(LoginScreen);
