import React from "react";
import { StyleSheet, View, Text } from "react-native";

// IMPORT GLOBAL COMPONENTS
import CloseButton from "../../components/buttons/CloseButton";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";
import InputText from "../../components/form/InputText";
import AttentionBox from "../../components/containers/AttentionBox";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as storesActions from "../../redux/actions/stores";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    currentChain: state.chains.currentChain,
    storesLeft: state.subscription.storesLimit - state.subscription.storesUsage,
  };
};

//// IMPORT FORM LIBS
import { Formik } from "formik";
import * as yup from "yup";

/// SET FORM VALIDATION SCHEMA
const storeSchema = yup.object({
  storeName: yup
    .string()
    .min(4, "Store name must be at least 4 characters")
    .required("Please enter store name"),
});

///////// START FUNCTION CLASS ///////////////////////
const AddStoreModal = (props) => {
  const dispatch = useDispatch();

  ////////////// RETURN VIEW /////////////////////
  return (
    <View style={styles.centeredModal}>
      <Card style={styles.modalBox}>
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 5 }]}>
            <Text style={Styles.H2}>Add New Store</Text>
          </View>
          <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
            <CloseButton onClose={() => props.onClose()} />
          </View>
        </View>
        <Divider />
        <Text style={[Styles.H5, { marginBottom: 10 }]}>
          Add store to retail chain - {props.currentChain.name}
        </Text>
        {props.storesLeft ? (
          <Text style={[Styles.H5, { marginBottom: 10 }]}>
            {props.storesLeft} stores left to add to this chain for your current
            subscription plan.
          </Text>
        ) : null}
        {props.storesLeft <= 0 ? (
          <AttentionBox>
            <Text style={Styles.H5}>
              Please upgrade subsrciption plan to add more stores
            </Text>
          </AttentionBox>
        ) : (
          <Formik
            initialValues={{
              storeName: "",
              retailChainId: props.currentChain.id,
            }}
            validationSchema={storeSchema}
            onSubmit={async (values, { setSubmitting }) => {
              await dispatch(
                storesActions.addStore(values.storeName, values.retailChainId)
              );
              setSubmitting(false);
              props.onClose();
            }}
          >
            {(formikProps) => (
              <View style={styles.form}>
                <InputText
                  placeholder="Store Name"
                  onChangeText={formikProps.handleChange("storeName")}
                  value={formikProps.values.storeName}
                  onBlur={formikProps.handleBlur("storeName")}
                  errorMessage={
                    formikProps.touched.storeName &&
                    formikProps.errors.storeName
                  }
                />

                <View>
                  <FlatButton
                    onPress={formikProps.handleSubmit}
                    title="Submit"
                    isLoading={formikProps.isSubmitting}
                  />
                </View>
              </View>
            )}
          </Formik>
        )}

        <Divider />
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 1 }]}>
            <Text style={Styles.H3}>Note</Text>
            <Text style={Styles.p}>
              A store is where you manage all your products. It can be an actual
              store or a warehouse.
            </Text>
          </View>
        </View>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredModal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingHorizontal: "5%",
  },

  modalBox: {
    maxWidth: 600,
    alignItems: "flex-start",
    marginTop: 15,
  },

  form: {
    width: "100%",
    justifyContent: "flex-start",
  },
});

export default connect(mapStateToStore)(AddStoreModal);
