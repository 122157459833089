import React from "react";
import { StyleSheet, View, Text } from "react-native";

const Divider = (props) => {
  return <View style={[styles.divider, props.style]} />;
};

const styles = StyleSheet.create({
  divider: {
    width: "100%",
    borderColor: "#cccccc",
    borderBottomWidth: 1,
    paddingTop: 10,
    marginBottom: 10,
  },
});

export default Divider;
