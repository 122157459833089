import React from "react";
import { StyleSheet, View } from "react-native";

const Card = (props) => {
  return <View style={[styles.card, props.style]}>{props.children}</View>;
};

const styles = StyleSheet.create({
  card: {
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 3,
    shadowOpacity: 0.26,
    backgroundColor: "#ffffff",
    elevation: 1,
    padding: 10,
    borderRadius: 6,
    width: "100%",
  },
});

export default Card;
