import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  FlatList,
  Modal,
  Linking,
  Platform,
  Alert,
} from "react-native";

// IMPORT GLOBAL COMPONENTS
import Card from "../../components/containers/Card";
import Loader from "../../components/loaders/facebookLoader";
import Divider from "../../components/dividers/Divider";
import SimpleSwitch from "../../components/form/SimpleSwitch";
import AttentionBox from "../../components/containers/AttentionBox";
import FlatButton from "../../components/buttons/FlatButton";
import ProductsCounter from "../../shopify/components/ProductsCounter";

// IMPORT LOCALL COMPONENTS
import ShopifyLocationRow from "./ShopifyLocationRow";
import ChooseStoreModal from "./ChooseStoreModal";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as shopifyActions from "../../redux/actions/shopify";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    isLoading: state.shopify.isLoading,
    isShopifyActive: state.shopify.isActive,
    currentChain: state.chains.currentChain,
    shopifyLocations: state.shopify.shopifyLocations,
    defaultStockSync: state.shopify.defaultStockSync,
    syncProducts: state.shopify.syncProducts,
    shopId: state.shopify.shopId,
  };
};

///////// START FUNCTION CLASS ///////////////////////
const ShopifySettings = (props) => {
  const dispatch = useDispatch();

  // Set Shopify sync state
  const [shopifySync, setShopifySync] = useState(props.syncProducts);

  useEffect(() => {
    if (props.currentChain.integrations.shopify) {
      dispatch(shopifyActions.fetchShopifySettings());
    }
  }, []);

  // MODAL STATE
  const [modalVisible, setModalVisible] = useState(false);

  // OPEN CONNECT STORE MODAL
  const connectStore = async (location) => {
    dispatch(shopifyActions.setLocation(location));
    setModalVisible(true);
  };

  // CLOSE MODAL FUNCTION
  const closeModal = () => {
    setModalVisible(false);
  };

  // Handle Shopify Sync switch change
  const HandleShopifySync = (switchState) => {
    // Set messages
    let syncMessage = `Make sure to add barcodes to your Shopify products.`;
    let stopMessage =
      "No products will be added to Shopify and inventory won't update.";

    // Start function
    setShopifySync(switchState);
    // If on web we use standard confirm module
    if (Platform.OS === "web") {
      // User want to start sync
      if (switchState) {
        // Ask the user if they are sure
        let confirmedSync = confirm("Start sync? " + syncMessage);
        if (confirmedSync) {
          // Confirmed start sync
          dispatch(shopifyActions.shopifySetSync(true));
        } else {
          // Canceled, switching to OFF
          setShopifySync(false);
        }
        // User want to stop sync
      } else {
        // Ask the user if they are sure
        let confirmedStop = confirm("Stop sync? " + stopMessage);
        if (confirmedStop) {
          // Confirmed stop sync
          dispatch(shopifyActions.shopifySetSync(false));
        } else {
          // Canceled, switching back to ON
          setShopifySync(true);
        }
      }
      // If user no on web we use RN Alert
    } else {
      // User want to start sync
      if (switchState) {
        // Ask the user if they are sure
        Alert.alert("Start sync?", syncMessage, [
          {
            text: "OK",
            onPress: () => dispatch(shopifyActions.shopifySetSync(true)),
          },
          { text: "Cancel", onPress: () => setShopifySync(false) },
        ]);
        // User want to stop sync
      } else {
        // Ask the user if they are sure
        Alert.alert("Stop sync?", "", [
          {
            text: "OK",
            onPress: () => dispatch(shopifyActions.shopifySetSync(false)),
          },
          {
            text: "Cancel",
            onPress: () => setShopifySync(true),
          },
        ]);
      }
    }
  };

  /// RENDER SHOPIFY LOCATIONS LIST
  const renderLocations = (itemData) => {
    return (
      <ShopifyLocationRow
        location={itemData.item}
        connectStore={connectStore}
      />
    );
  };

  ////////////// RETURN VIEW /////////////////////

  // WHEN LOADING
  if (props.isLoading) {
    return <Loader />;
  }

  // WHEN SHOPIFY CONNECTION IS NOT ACTIVE
  if (!props.isShopifyActive) {
    return (
      <Card style={styles.container}>
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 3 }]}>
            <View style={Styles.row}>
              <Image
                source={require("../../../assets/shopify-icon.png")}
                style={styles.connectionIcon}
                accessible={false}
              />
              <Text style={Styles.H1}>Shopify online store</Text>
            </View>
          </View>
        </View>
        <Divider />
        <View style={Styles.row}>
          <View style={[Styles.col]}>
            <Text style={Styles.H3}>Shopify store disconnected</Text>

            <Text>Shop name: {props.shopId}</Text>
            <FlatButton
              onPress={() =>
                Linking.openURL(
                  `https://api.grabstore.app/shopify/new-connection?shop=${props.shopId}.myshopify.com`
                )
              }
              title="Reconnect Store"
            />
          </View>
        </View>
      </Card>
    );
  }

  // WHEN SETTINGS FETCHED
  return (
    <Card style={styles.container}>
      {/* ///// CONNECT STORE MODAL */}
      <Modal animationType="fade" transparent={true} visible={modalVisible}>
        <ChooseStoreModal onClose={closeModal} />
      </Modal>
      {/* ///// END STORE MODAL */}
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 3 }]}>
          <View style={Styles.row}>
            <Image
              source={require("../../../assets/shopify-icon.png")}
              style={styles.connectionIcon}
              accessible={false}
            />
            <Text style={Styles.H1}>Shopify online store</Text>
          </View>
        </View>
        <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
          <SimpleSwitch
            switchState={props.syncProducts}
            onChange={(switchState) => HandleShopifySync(switchState)}
          />
        </View>
      </View>
      <Divider />
      <View style={Styles.row}>
        <Text style={Styles.H2}>Shop name:</Text>
        <Text style={{ marginStart: 20 }}>{props.shopId}</Text>
      </View>
      <Divider />
      <ProductsCounter />
      <Divider />
      <View style={Styles.row}>
        <Text style={[Styles.H2, { marginBottom: 20 }]}>Shopify locations</Text>
      </View>
      {props.defaultStockSync ? (
        <AttentionBox>
          <Text style={Styles.H4}>Default Inventory Sync is activated</Text>
          <Text>
            All the inventory of this chain will be aggregated and synced to the
            default Shopify location.
          </Text>
          <Text>To change it simply connect locations to stores.</Text>
        </AttentionBox>
      ) : null}
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 1 }]}></View>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text style={[Styles.H3]}>Connected store</Text>
        </View>
      </View>
      <View style={{ width: "100%" }}>
        <FlatList
          data={props.shopifyLocations}
          keyExtractor={(item) => item.id}
          renderItem={renderLocations}
          numColumns={1}
        />
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    paddingHorizontal: 20,
    paddingVertical: 20,
  },

  header: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },

  connectionIcon: {
    width: 40,
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
    alignItems: "center",
    position: "relative",
    marginEnd: 20,
  },
});

export default connect(mapStateToStore)(ShopifySettings);
