import React, { useState } from "react";
import { StyleSheet, View, Text, Modal } from "react-native";
import { connect, useDispatch } from "react-redux";

// IMPORT GLOBAL COMPONENTS
import BarcodeInput from "../../components/form/BarcodeInput";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Screen from "../../components/containers/Screen";
import Divider from "../../components/dividers/Divider";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// IMPORT SECTION COMPONENTS
import ProductModal from "../components/ProductModal";

// Import actions
import * as singleProductActions from "../../redux/actions/singleProduct";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    isUpdatingProduct: state.singleProduct.isUpdatingProduct,
    currentProduct: state.singleProduct.currentProduct,
  };
};

//// IMPORT FORM LIBS
import { Formik } from "formik";
import * as yup from "yup";

/// SET FORM VALIDATION SCHEMA
const barcodeSchema = yup.object({
  barcode: yup
    .number()
    .test(
      "len",
      "Please enter a valid global barcode (UPC, EAN, etc.)",
      (val) => (val ? val.toString().length > 8 : false)
    )
    .required("Barcode is requried"),
});

///////// START FUNCTION CLASS ///////////////////////
const AddProductScreen = (props) => {
  // Initiate state
  const [modalVisible, setModalVisible] = useState(false);

  const dispatch = useDispatch();

  const resetHandler = () => {
    setModalVisible(false);
    dispatch(singleProductActions.setCurrentProduct(null));
  };

  ////////////// RETURN VIEW /////////////////////
  return (
    <Screen>
      {/* Setting up modal for product edit */}
      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <ProductModal onClose={resetHandler} />
      </Modal>
      <View style={[Styles.col, { flex: 1, marginTop: 20 }]}>
        <Card>
          <View style={Styles.row}>
            <View style={[Styles.col, { flex: 1 }]}>
              <Text style={Styles.H2}>Add Product</Text>
            </View>
          </View>
          <Divider />
          <Formik
            initialValues={{
              barcode: "",
            }}
            validationSchema={barcodeSchema}
            onSubmit={async (values, actions) => {
              await dispatch(
                singleProductActions.fetchScanned(values.barcode, "upc")
              );
              actions.resetForm();
              actions.setSubmitting(false);
              setModalVisible(true);
            }}
          >
            {(formikProps) => (
              <View style={styles.column}>
                <View style={styles.row}>
                  <BarcodeInput
                    placeholder="Enter barcode (UPC/EAN)"
                    onChangeText={formikProps.handleChange("barcode")}
                    value={formikProps.values.barcode}
                    onBlur={formikProps.handleBlur("barcode")}
                    errorMessage={
                      formikProps.touched.barcode && formikProps.errors.barcode
                    }
                  />
                </View>
                <View style={styles.buttonBar}>
                  <FlatButton
                    title="Add Product"
                    onPress={formikProps.handleSubmit}
                    isLoading={formikProps.isSubmitting}
                  />
                </View>
              </View>
            )}
          </Formik>
          <Divider />
          <View style={Styles.row}>
            <View style={[Styles.col, { flex: 1 }]}>
              <Text style={Styles.H3}>Note</Text>
              <Text style={Styles.p}>
                Add products faster with the barcode scanner in our mobile app.
              </Text>
            </View>
          </View>
        </Card>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default connect(mapStateToStore)(AddProductScreen);
