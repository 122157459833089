import React from "react";
import { StyleSheet, View, Text } from "react-native";

// IMPORT GLOBAL STYLES
import Colors from "../../styles/Colors";
import Styles from "../../styles/Styles";

// IMPORT GLOBAL COMPONENTS
import Ionicons from "@expo/vector-icons/Ionicons";

const BarcodeBox = (props) => {
  return (
    <View style={[Styles.row, styles.barcodeBox]}>
      <Ionicons name={"barcode-outline"} size={30} style={styles.icon} />
      <View style={[Styles.col, { flex: 1, alignItems: "center" }]}>
        <Text style={styles.barcode} numberOfLines={1}>
          {props.barcode}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  barcodeBox: {
    borderColor: Colors.lightBorder,
    borderWidth: 1,
    borderRadius: 5,
    paddingVertical: 4,
  },

  icon: {
    marginHorizontal: 6,
    color: Colors.accent,
  },

  barcode: {
    fontSize: 14,
  },
});

export default BarcodeBox;
