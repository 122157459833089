import axios from "axios";
import { Linking } from "react-native";

export const FETCHED_SUBSCRIPTION = "FETCHED_SUBSCRIPTION";
export const SUB_LOADING = "SUB_LOADING";
export const SUB_ERROR = "SUB_ERROR";

// import constants
import SERVER_CONSTANTS from "../../constants/airstoreServer";

////////// GET CHAIN SUBSCRIPTION ////////////////////////////////////////////
export const fetchSubscription = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;
    try {
      dispatch({
        type: SUB_LOADING,
      });
      // STEP 1: Get information from the server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/subscriptions/chain-plan",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: {
            chainId: chainId,
          },
        }
      );

      // STEP 2: Update information
      dispatch({
        type: FETCHED_SUBSCRIPTION,
        planName: response.data.planName,
        planId: response.data.planId,
        productsLimit: response.data.productsLimit,
        productsUsage: response.data.productsUsage,
        storesLimit: response.data.storesLimit,
        storesUsage: response.data.storesUsage,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: SUB_ERROR,
        errorMessage: error.response.data.message,
      });
    }
  };
};

////////// UPGRADE PLAN ////////////////////////////////////////////
export const upgradePlan = (planId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;
    try {
      dispatch({
        type: SUB_LOADING,
      });
      // STEP 1: Send upgrade request to server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/subscriptions/upgrade-plan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            planId: planId,
            chainId: chainId,
          }),
        }
      );

      // STEP 2: Update information
      dispatch(fetchSubscription());
      if (response.data.planId === "002") {
        Linking.openURL(response.data.redirectUrl);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: SUB_ERROR,
        errorMessage: error.response.data.message,
      });
    }
  };
};

////////// UPGRADE PLAN ////////////////////////////////////////////
export const confirmShopifySubscription = (subscriptionId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    try {
      // STEP 1: Send upgrade request to server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/subscriptions/confirm",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            paymentProvider: "shopify",
            subscriptionId: subscriptionId,
          }),
        }
      );
    } catch (error) {
      console.error(error);
      dispatch({
        type: SUB_ERROR,
        errorMessage: error.response.data.message,
      });
    }
  };
};
