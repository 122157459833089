// IMPORT UPDATE HELPER
import update from "immutability-helper";

import { LOGOUT } from "../actions/auth";
import { RESET_CHAIN } from "../actions/chains";

import {
  TOGGLE_SCANNED,
  UPDATE_SINGLE_PRODUCT,
  SET_PRODUCT,
  SINGLE_LOADING,
  ADDED_PRODUCT,
} from "../actions/singleProduct";

const initialState = {
  isUpdatingProduct: false,
  barcode: "Nothing Scanned",
  currentProduct: null,
};

const singleProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case SINGLE_LOADING:
      return {
        ...state,
        isUpdatingProduct: true,
      };
    case TOGGLE_SCANNED:
      return {
        ...state,
        barcode: action.scannedBarcode,
        currentProduct: action.productScanned,
      };
    case SET_PRODUCT:
      return {
        ...state,
        currentProduct: action.currentProduct,
        isUpdatingProduct: false,
      };
    case ADDED_PRODUCT: {
      return {
        ...state,
        currentProduct: action.currentProduct,
        isUpdatingProduct: false,
      };
    }
    case UPDATE_SINGLE_PRODUCT:
      return update(state, {
        isUpdatingProduct: { $set: false },
        currentProduct: {
          stock: { $set: action.updatedStock },
          price: { $set: action.updatedPrice },
        },
      });
    case LOGOUT:
      return {
        ...initialState,
      };
    case RESET_CHAIN:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default singleProductReducer;
