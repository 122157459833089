import React from "react";
import { StyleSheet, View } from "react-native";

import ProductsListLoader from "./ProductsListLoader";

const ContentLoader = (props) => {
  return (
    <View style={styles.loader}>
      <ProductsListLoader />
    </View>
  );
};

const styles = StyleSheet.create({
  loader: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
export default ContentLoader;
