import React from "react";
import { View, Text, StyleSheet } from "react-native";

// IMPORT GLOBAL COMPONENTS
import FlatButton from "../../components/buttons/FlatButton";
import InputText from "../../components/form/InputText";

//// IMPORT FORM LIBS
import { Formik } from "formik";
import * as yup from "yup";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as connectionsActions from "../../redux/actions/connections";

const emailSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email")
    .required("Please enter your email"),
});

////////// START FUNCTION CLASS /////////
const SubmitConnection = (props) => {
  // Initiate dispatch
  const dispatch = useDispatch();

  //////////// RETURN VIEW ////////
  return (
    <View style={(Styles.col, { width: "100%" })}>
      <Text style={{ fontSize: 16, marginBottom: 5 }}>
        Simply enter your email address to get started.
      </Text>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={emailSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await dispatch(
            connectionsActions.updateConnectionRequest(values.email)
          );
          setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <View>
            <InputText
              containerStyle={{ marginBottom: 10 }}
              placeholder="Email"
              onChangeText={formikProps.handleChange("email")}
              value={formikProps.values.email}
              onBlur={formikProps.handleBlur("email")}
              errorMessage={
                formikProps.touched.email && formikProps.errors.email
              }
            />
            <View style={(Styles.col, { flex: 1 })}>
              <FlatButton
                title="Get Started"
                onPress={formikProps.handleSubmit}
                isLoading={formikProps.isSubmitting}
              />
            </View>
          </View>
        )}
      </Formik>
    </View>
  );
};

const styles = StyleSheet.create({});

export default SubmitConnection;
