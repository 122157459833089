import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, Modal } from "react-native";

// IMPORT GLOBAL COMPONENTS
import Ionicons from "@expo/vector-icons/Ionicons";

// IMPORT LOCAL COMPONENTS
import NewTicketModal from "../../support/components/NewTicketModal";

// IMPORT STYLES
import Colors from "../../styles/Colors";

const SupportButton = (props) => {
  // HANDLE MODAL STATE
  const [modalVisible, setModalVisible] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  ///////// RETURN VIEW ///////////
  return (
    <TouchableOpacity
      style={styles.button}
      onPress={() => setModalVisible(true)}
    >
      {/* //// START NEW SUPPORT TICKET MODAL ///// */}
      <Modal animationType="fade" transparent={true} visible={modalVisible}>
        <NewTicketModal onClose={closeModal} />
      </Modal>
      {/* //// END MODAL ///// */}
      <Ionicons
        name={"help-circle-outline"}
        size={30}
        color={Colors.primaryLight}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    marginEnd: 10,
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default SupportButton;
