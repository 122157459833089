// IMPORT UPDATE HELPER
import update from "immutability-helper";

import { LOGOUT } from "../actions/auth";

import {
  FETCH_CHAINS,
  UPDATE_CHAINS,
  CHAINS_ERROR,
  ADD_CHAIN,
  SELECT_RETAIL_CHAIN,
  UPDATE_STATS,
  RESET_CHAIN,
  FETCH_STATS,
} from "../actions/chains";

const initialState = {
  userRetailChains: [],
  currentChain: null,
  isFetchigChains: false,
  isError: false,
  errorMessage: null,
  chainStats: null,
  isFetchingStats: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHAINS:
      return {
        ...state,
        isError: false,
        isFetchigChains: true,
      };
    case ADD_CHAIN:
      return update(state, {
        userRetailChains: {
          $push: [action.addedChain],
        },
      });
    case UPDATE_CHAINS:
      return {
        ...state,
        isFetchigChains: false,
        userRetailChains: action.userRetailChains,
      };
    case SELECT_RETAIL_CHAIN:
      return {
        ...state,
        isError: false,
        currentChain: action.currentChain,
      };
    case RESET_CHAIN:
      return {
        ...state,
        currentChain: null,
        chainStats: null,
        isFetchigChains: false,
        isError: false,
        isFetchingStats: true,
      };
    case CHAINS_ERROR:
      return {
        ...state,
        isError: true,
        isFetchigChains: false,
        errorMessage: action.errorMessage,
      };
    case FETCH_STATS:
      return {
        ...state,
        isFetchingStats: true,
      };
    case UPDATE_STATS:
      return {
        ...state,
        isFetchingStats: false,
        chainStats: action.chainStats,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
