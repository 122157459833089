import React, { useState } from "react";
import { Platform } from "react-native";

import AppLoading from "expo-app-loading";

// import redux libraries
import { useDispatch, connect } from "react-redux";
import * as startupActions from "../redux/actions/startup";

// Import react navigation
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { navigationRef } from "./RootNavigation";

// Initiate native stack navigator
const Stack = createNativeStackNavigator();

// IMPORT NAVIGATORS
import LoginNavigator from "../auth/routes/LoginNavigator";
import AppNavigator from "./AppNavigator";

// IMPORT SCREENS
import ConnectionsScreen from "../connections/screens/ConnectionsScreen";
import SelectChainScreen from "../chains/screens/SelectChainScreen";
import ThankYouScreen from "../subscription/screens/ThankYouScreen";

const linking = {
  config: {
    screens: {
      LoginNavigator: {
        screens: {
          Login: "login/:action?/:email?/:token?/:tokenId?/",
          SignUp: "signup/",
          ResetPassword: "reset-password/:email?/:token?/:tokenId?/",
        },
      },
      SelectChain: "chain-login",
      AppNavigator: {
        screens: {
          Dashboard: "dashboard",
          ChainSettings: {
            screens: {
              SettingsScreen: "chain-settings",
            },
          },
          ProductsManagementNavigator: {
            screens: {
              ProductsNavigator: {
                screens: {
                  AllProducts: "products",
                  EditProduct: "products/edit",
                },
              },
              Scanner: "products/scanner",
              AddManualy: "products/add-product",
            },
          },
          Orders: "orders",
        },
      },
      ConnectionsScreen: "connections/:type?/:shop?/:timestamp?/:hmac?/",
      ThankYouScreen: "thank-you",
    },
  },
};

const mapStateToStore = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    currentChain: state.chains.currentChain,
  };
};

////////// START FUNCTION CLASS /////////
const MainNavigator = (props) => {
  const [isReady, setIsReady] = useState(false);
  // Initiate dispatch
  const dispatch = useDispatch();

  const appLoading = async () => {
    await dispatch(startupActions.startup());
  };

  //////////// RETURN VIEW ////////
  if (!isReady) {
    return (
      <AppLoading
        startAsync={appLoading}
        onFinish={() => setIsReady(true)}
        onError={console.warn}
      />
    );
  }

  return (
    <NavigationContainer linking={linking} ref={navigationRef}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {props.isSignedIn && props.currentChain ? (
          <Stack.Screen name="AppNavigator" component={AppNavigator} />
        ) : props.isSignedIn ? (
          <Stack.Screen
            name="SelectChain"
            component={SelectChainScreen}
            options={{ title: "Select Retail Chain | Airstore Cloud" }}
          />
        ) : (
          <Stack.Screen name="LoginNavigator" component={LoginNavigator} />
        )}
        {Platform.OS === "web" ? (
          <>
            <Stack.Screen
              name="ConnectionsScreen"
              component={ConnectionsScreen}
              options={{ title: "New Connection To Airstore Cloud" }}
            />
            <Stack.Screen
              name="ThankYouScreen"
              component={ThankYouScreen}
              options={{ title: "Thank You | Airstore Cloud" }}
            />
          </>
        ) : null}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default connect(mapStateToStore)(MainNavigator);
