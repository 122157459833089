import React from "react";
import { View, Text, StyleSheet, FlatList } from "react-native";

// IMPORT GLOBAL COMPONENTS
import Ionicons from "@expo/vector-icons/Ionicons";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

const renderList = (itemData) => {
  return (
    <View style={[Styles.row, styles.benefitRow]}>
      <Ionicons
        name={"checkmark-outline"}
        color={Colors.activeGreen}
        size={25}
      />
      <Text style={styles.benefit}>{itemData.item}</Text>
    </View>
  );
};

////////// START FUNCTION CLASS /////////
const MarketingBox = (props) => {
  /////////////////////// RETURN VIEW //////////////////////////////
  return (
    <View style={Styles.col}>
      <FlatList
        key="benefits"
        data={[
          "Get acces to the largest products catalog on earth",
          "Manage your inventory, we take care of the rest",
          "Run your entire Retail Stack from your phone",
          "Sync inventory between your POS, E-Commerce and more",
        ]}
        keyExtractor={(item) => item}
        renderItem={renderList}
        contentContainerStyle={Styles.listContainer}
      />
      <Text style={Styles.H3}>FREE for life with the starter package</Text>
      <FlatList
        key="included"
        data={[
          "10 new automated products listings per month",
          "Manage inventory in 10 different locations",
          "Sync unlimited SKUs",
        ]}
        keyExtractor={(item) => item}
        renderItem={renderList}
        contentContainerStyle={Styles.listContainer}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  benefit: {
    fontSize: 16,
    marginStart: 10,
  },

  benefitRow: {
    marginBottom: 10,
  },
});

export default MarketingBox;
