import { LOGOUT } from "../actions/auth";

import { USER_LOADING, FETCHED_PROFILE } from "../actions/user";

const initialState = {
  isLoading: false,
  profile: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCHED_PROFILE:
      return {
        ...state,
        profile: action.profile,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
