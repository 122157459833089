import axios from "axios";

import {
  LOADING_CONNECTION,
  REQUEST_VERIFIED,
  REQUEST_FAILED,
  CHAIN_CONNECTED,
} from "./connections";

export const SHOPIFY_LOADING = "SHOPIFY_LOADING";
export const FETCHED_SETTINGS = "FETCHED_SETTINGS";
export const SET_LOCATION = "SET_LOCATION";
export const CONNECTED_STORE = "CONNECTED_STORE";
export const IMPORTED_LOCATIONS = "IMPORTED_LOCATIONS";
export const SHOPIFY_ERROR = "SHOPIFY_ERROR";
export const PRODUCTS_SYNC = "PRODUCTS_SYNC";
export const SHOPIFY_PRODUCTS_LOAD = "SHOPIFY_PRODUCTS_LOAD";
export const SHOPIFY_PRODUCTS = "SHOPIFY_PRODUCTS";
export const SHOPIFY_DISCONNECTED = "SHOPIFY_DISCONNECTED";

// IMPORT CONSTANTS
import SERVER_CONSTANTS from "../../constants/airstoreServer";

// IMPORT ACTIONS
import * as chainsActions from "./chains";
import * as storesActions from "./stores";
import * as notificationsActions from "./notifications";

////////// GET SHOPIFY SETTINGS ////////////////////////////////////////////
export const fetchShopifySettings = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;
    try {
      dispatch({
        type: SHOPIFY_LOADING,
      });
      // STEP 1: Get information from the server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/shopify/settings",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: {
            chainId: chainId,
          },
        }
      );

      if (response.status !== 200) {
        dispatch({
          type: SHOPIFY_ERROR,
          errorMessage: response.data.message,
        });
      }

      // STEP 2: Update information
      dispatch({
        type: FETCHED_SETTINGS,
        isActive: response.data.isActive,
        shopifyLocations: response.data.shopifyLocations,
        shopId: response.data.shopId,
        defaultStockSync: response.data.defaultStockSync,
        syncProducts: response.data.syncProducts,
        defaultStoreOnlineOrders: response.data.defaultStoreOnlineOrders,
      });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({
          type: SHOPIFY_DISCONNECTED,
          shopId: error.response.data.shopId,
        });
        return;
      }
      console.error(error);
      dispatch({
        type: SHOPIFY_ERROR,
        errorMessage: error.response.data.message,
      });
    }
  };
};

////////// SHOPIFY SET SYNC ////////////////////////////////////////////
export const shopifySetSync = (setSync) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;
    try {
      dispatch({
        type: PRODUCTS_SYNC,
        syncProducts: setSync,
      });
      // STEP 1: Update the server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/shopify/sync",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            chainId: chainId,
            syncProducts: setSync,
          }),
        }
      );
      dispatch(notificationsActions.fetchChainNotifications());
    } catch (error) {
      console.log(error);
    }
  };
};

////////// SHOPIFY SET LOCATION ////////////////////////////////////////////
export const setLocation = (location) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCATION,
      currentLocation: location,
    });
  };
};

////////// SHOPIFY CONNECT LOCATION TO STORE ////////////////////////////////////////////
export const connectStoreLocation = (chainId, storeId, locationId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const shopifyLocations = getState().shopify.shopifyLocations;
    try {
      // STEP 1: Update the server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/shopify/connect-location",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            chainId: chainId,
            storeId: storeId,
            locationId: locationId,
          }),
        }
      );
      if (response.status !== 200) {
        console.log("Problem");
        return;
      }

      const locationIndex = shopifyLocations.findIndex(
        (location) => location.id === locationId
      );
  
      dispatch({
        type: CONNECTED_STORE,
        storeId: storeId,
        locationIndex: locationIndex,
        defaultStockSync: response.data.defaultStockSync,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

////////// SHOPIFY IMPORT LOCATIONS ////////////////////////////////////////////
export const importLocations = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;
    try {
      dispatch({ type: SHOPIFY_LOADING });
      // STEP 1: Update the server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/shopify/import-locations",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            chainId: chainId,
          }),
        }
      );
      if (response.status !== 201) {
        console.log("Problem");
        return;
      }

      dispatch(storesActions.fetchChainStores());
      dispatch(notificationsActions.fetchChainNotifications());
      dispatch({
        type: IMPORTED_LOCATIONS,
      });
    } catch (error) {
      if (error.response.status === 400) {
        dispatch({
          type: SHOPIFY_ERROR,
          errorMessage: error.response.data.message,
        });
      }
      console.log(error);
    }
  };
};

////////// VERIFY NEW SHOPIFY CONNECTION ////////////////////////////////////////////
export const verifyShopifyRequest = (requestParams) => {
  return async (dispatch, getState) => {
    const userChains = getState().chains.userRetailChains;
    try {
      dispatch({
        type: LOADING_CONNECTION,
      });
      // STEP 1: Send the shopify request to grabstore server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/shopify/verify-connection",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            requestParams: requestParams,
          }),
        }
      );

      // STEP 2: If failed show relevant message
      if (response.status !== 200) {
        console.error("Shopify connection failed");
        dispatch({
          type: REQUEST_FAILED,
          errorMessage:
            "Shopify connection failed, try to reinstall the app on Shopify.",
        });
        return;
      }
      // STEP 2: If request verified update redux state
      console.log("Shopify is now connected");

      // STEP 3: Check if the user logged in, if it does fetch chains
      const isSignedIn = getState().auth.isSignedIn;
      if (isSignedIn) {
        dispatch(chainsActions.fetchUserChains());
      }

      // STEP 4: Dispatch connection username to redux
      dispatch({
        type: REQUEST_VERIFIED,
        connectionUsername: requestParams.shop.split(".")[0],
      });
    } catch (error) {
      // Check if failed because this shopify store is already connected to a chain
      if (error.response.status === 303) {
        dispatch({
          type: CHAIN_CONNECTED,
        });
        // Check if the connected chain belongs to the user
        if (userChains) {
          const connectedChain = userChains.find(
            (chain) => chain.id === error.response.data.chainId
          );
          if (connectedChain) {
            dispatch(chainsActions.selectChain(connectedChain));
          }
        }
        return;
      }
      dispatch({
        type: REQUEST_FAILED,
        errorMessage:
          "Shopify connection failed, try to reinstall the app on Shopify.",
      });
    }
  };
};

////////// SET DEFAULT STORE FOR ONLINE ORDERS ////////////////////////////////////////////
export const setDefaultStoreOrders = (storeId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;
    try {
      dispatch({
        type: SHOPIFY_LOADING,
      });
      // STEP 1: send connection request to server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/shopify/default-store",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            chainId: chainId,
            storeId: storeId,
          }),
        }
      );

      // STEP 2: If request verified reload shopify settings
      dispatch(fetchShopifySettings());
    } catch (error) {
      console.log(error);
    }
  };
};

////////// START ORDERS SYNC ////////////////////////////////////////////
export const startOrdersSync = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;

    try {
      dispatch({
        type: SHOPIFY_LOADING,
      });
      // STEP 1: send connection request to server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/shopify/sync-orders",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            chainId: chainId,
          }),
        }
      );

      // STEP 2: If successful reload notifications
      dispatch(notificationsActions.fetchChainNotifications());
    } catch (error) {
      console.log(error);
    }
  };
};

////////// START ORDERS SYNC ////////////////////////////////////////////
export const shopifyProductsCounter = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;
    try {
      dispatch({
        type: SHOPIFY_PRODUCTS_LOAD,
      });
      // STEP 1: send connection request to server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/shopify/products-count",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: {
            chainId: chainId,
          },
        }
      );
      if (!response) {
        dispatch({
          type: SHOPIFY_ERROR,
          errorMessage: "Error",
        });
        return;
      }

      dispatch({
        type: SHOPIFY_PRODUCTS,
        shopifyProducts: response.data.productsCount,
        missingBarcodes: response.data.missingBarcodes,
      });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({
          type: SHOPIFY_DISCONNECTED,
        });
        return;
      }
      console.log(error);
      dispatch({
        type: SHOPIFY_ERROR,
        errorMessage: "Error",
      });
      return;
    }
  };
};
