// IMPORT UPDATE HELPER
import update from "immutability-helper";

import { LOGOUT } from "../actions/auth";
import { RESET_CHAIN } from "../actions/chains";

import {
  FETCH_PRODUCTS,
  UPDATE_PRODUCTS,
  PRODUCTS_ERROR,
} from "../actions/products";

import { UPDATE_SINGLE_PRODUCT, ADDED_PRODUCT } from "../actions/singleProduct";

const initialState = {
  isFetchingProducts: false,
  currentStoreProducts: [],
  isError: false,
  errorMessage: "",
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        isFetchingProducts: true,
        isError: false,
        errorMessage: "",
      };
    case UPDATE_PRODUCTS:
      return {
        ...state,
        isFetchingProducts: false,
        currentStoreProducts: action.currentStoreProducts,
      };
    case PRODUCTS_ERROR:
      return {
        ...state,
        isFetchingProducts: false,
        isError: true,
        currentStoreProducts: [],
        errorMessage: action.errorMessage,
      };
    case UPDATE_SINGLE_PRODUCT:
      return update(state, {
        currentStoreProducts: {
          [action.productIndex]: {
            stock: { $set: action.updatedStock },
            price: { $set: action.updatedPrice },
          },
        },
      });
    case ADDED_PRODUCT:
      return update(state, {
        currentStoreProducts: {
          $push: [action.currentProduct],
        },
      });
    case LOGOUT:
      return {
        ...initialState,
      };
    case RESET_CHAIN:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default productsReducer;
