// Initiate cases
export const SET_PRODUCTS_VIEW = "SET_PRODUCTS_VIEW";

////////// SELECT STORE ////////////////////////////////////////////
export const setProductsView = (view) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PRODUCTS_VIEW,
      productsView: view,
    });
  };
};
