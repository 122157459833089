import {
  SUBMITED_TICKET,
  SUPPORT_ERROR,
  RESET_TICKET,
} from "../actions/support";

const initialState = {
  ticketSubmited: false,
  ticketId: null,
  isError: false,
  errorMessage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBMITED_TICKET:
      return {
        ...state,
        isError: false,
        ticketSubmited: true,
        ticketId: action.ticketId,
      };
    case SUPPORT_ERROR:
      return {
        ...state,
        isError: true,
        errorMessage: action.errorMessage,
      };
    case RESET_TICKET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
