import React from "react";
import { StyleSheet, View, Text } from "react-native";

// IMPORT GLOBAL STYLES
import Colors from "../../styles/Colors";
import Styles from "../../styles/Styles";

// IMPORT GLOBAL COMPONENTS

const getMonthWord = (month) => {
  if (month === 0) return "Jan";
  if (month === 1) return "Feb";
  if (month === 2) return "Mar";
  if (month === 3) return "Apr";
  if (month === 4) return "May";
  if (month === 5) return "Jun";
  if (month === 6) return "Jul";
  if (month === 7) return "Aug";
  if (month === 8) return "Sep";
  if (month === 9) return "Oct";
  if (month === 10) return "Nov";
  if (month === 11) return "Dec";
};

///////////// START FUNCTION CLASS //////////////////
const DateBox = (props) => {
  const currentDate = new Date(props.date);
  return (
    <View style={styles.datebox}>
      <Text style={styles.unit}>{getMonthWord(currentDate.getMonth())}</Text>
      <Text style={styles.value}>{currentDate.getDate()}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  datebox: {
    justifyContent: "center",
    alignItems: "center",
  },

  unit: {
    fontSize: 16,
    color: Colors.accent,
  },

  value: {
    fontSize: 16,
  },
});

export default DateBox;
