import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Linking } from "react-native";

// IMPORT GLOBAL COMPONENTS
import CloseButton from "../../components/buttons/CloseButton";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";

// IMPORT LOCAL COMPONENTS
import PlanBox from "./PlanBox";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Importing Redux state components
import { connect } from "react-redux";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    redirectUrl: state.subscription.redirectUrl,
    planId: state.subscription.planId,
  };
};

///////// START FUNCTION CLASS ///////////////////////
const PlanUpgradeBox = (props) => {
  ////////////// RETURN VIEW  /////////////////////
  return (
    <View style={styles.centeredModal}>
      <Card style={styles.modalBox}>
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 5 }]}>
            <Text style={Styles.H2}>Choose Plan</Text>
          </View>
          <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
            <CloseButton onClose={() => props.onClose()} />
          </View>
        </View>
        <Divider />
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 1, alignItems: "center" }]}>
            <FlatButton
              title="More information on plans and pricing"
              onPress={() => Linking.openURL(`https://airstore.app/pricing`)}
              icon="information-circle-outline"
              iconStyle={{ color: Colors.primaryLight }}
              buttonStyle={{ backgroundColor: "#ffffff" }}
              titleStyle={{ color: Colors.primary }}
            />
          </View>
        </View>
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 1, alignItems: "center" }]}>
            {props.planId === "001" ? (
              <PlanBox planId="002" onClose={() => props.onClose()} />
            ) : (
              <PlanBox planId="001" onClose={() => props.onClose()} />
            )}
          </View>
          <View style={[Styles.col, { flex: 1, alignItems: "center" }]}>
            <PlanBox planId="003" />
          </View>
        </View>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredModal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingHorizontal: "5%",
  },

  modalBox: {
    maxWidth: 600,
    alignItems: "flex-start",
    marginTop: 15,
  },

  form: {
    width: "100%",
    justifyContent: "flex-start",
  },
});

export default connect(mapStateToStore)(PlanUpgradeBox);
