import React, { useEffect } from "react";
import { StyleSheet, View, Text, ActivityIndicator } from "react-native";

// IMPORT GLOBAL COMPONENTS

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as shopifyActions from "../../redux/actions/shopify";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    shopifyProducts: state.shopify.shopifyProducts,
    missingBarcodes: state.shopify.missingBarcodes,
    currentChain: state.chains.currentChain,
    isLoading: state.shopify.isFetchingProducts,
  };
};

///////// START FUNCTION CLASS ///////////////////////
const ProductsCounter = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(shopifyActions.shopifyProductsCounter());
  }, [props.currentChain]);
  ////////////// RETURN VIEW /////////////////////
  return (
    <View style={[Styles.row]}>
      <View style={styles.counter}>
        <Text style={styles.counterTitle}>Total Products</Text>
        {props.isLoading ? (
          <ActivityIndicator size="small" color={Colors.primary} />
        ) : (
          <Text style={styles.counterNum}>{props.shopifyProducts}</Text>
        )}
      </View>
      <View style={styles.counter}>
        <Text style={styles.counterTitle}>Missing Barcodes</Text>
        {props.isLoading ? (
          <ActivityIndicator size="small" color={Colors.primary} />
        ) : (
          <Text style={styles.counterNum}>{props.missingBarcodes}</Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  counter: {
    flex: 1,
    borderWidth: 1,
    borderColor: Colors.lightBorder,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 10,
    paddingVertical: 10,
  },

  counterTitle: {
    fontSize: 16,
    marginBottom: 10,
  },

  counterNum: {
    fontSize: 18,
    fontWeight: "600",
    color: Colors.primary,
  },
});

export default connect(mapStateToStore)(ProductsCounter);
