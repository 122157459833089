import React from "react";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";

const ProductsListLoader = (props) => (
  <ContentLoader
    speed={1}
    width={600}
    height={150}
    viewBox="0 0 600 150"
    backgroundColor="#d2d1d1"
    foregroundColor="#ecebeb"
    {...props}
  >
    <Rect x="92" y="10" rx="3" ry="3" width="496" height="14" />
    <Rect x="6" y="8" rx="0" ry="0" width="69" height="61" />
    <Rect x="50" y="46" rx="0" ry="0" width="3" height="0" />
    <Rect x="92" y="38" rx="3" ry="3" width="496" height="14" />
    <Rect x="93" y="70" rx="3" ry="3" width="332" height="14" />
    <Rect x="11" y="119" rx="3" ry="3" width="570" height="5" />
  </ContentLoader>
);

export default ProductsListLoader;
