// IMPORT UPDATE HELPER
import update from "immutability-helper";

import { LOGOUT } from "../actions/auth";
import { RESET_CHAIN } from "../actions/chains";

import {
  SHOPIFY_LOADING,
  FETCHED_SETTINGS,
  SET_LOCATION,
  CONNECTED_STORE,
  SHOPIFY_ERROR,
  IMPORTED_LOCATIONS,
  PRODUCTS_SYNC,
  SHOPIFY_PRODUCTS,
  SHOPIFY_PRODUCTS_LOAD,
  SHOPIFY_DISCONNECTED,
} from "../actions/shopify";

const initialState = {
  isLoading: false,
  isActive: false,
  isFetchingProducts: false,
  isError: false,
  errorMessage: "",
  shopifyProducts: null,
  missingBarcodes: null,
  shopifyLocations: [],
  currentLocation: {},
  defaultStockSync: false,
  syncProducts: false,
  shopId: null,
  defaultStoreOnlineOrders: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOPIFY_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case SHOPIFY_PRODUCTS_LOAD:
      return {
        ...state,
        isFetchingProducts: true,
        isError: false,
      };
    case FETCHED_SETTINGS:
      return {
        ...state,
        isLoading: false,
        isActive: action.isActive,
        shopifyLocations: action.shopifyLocations,
        defaultStockSync: action.defaultStockSync,
        shopId: action.shopId,
        syncProducts: action.syncProducts,
        defaultStoreOnlineOrders: action.defaultStoreOnlineOrders,
      };
    case SHOPIFY_DISCONNECTED:
      return {
        ...state,
        shopId: action.shopId,
        isLoading: false,
      };
    case SHOPIFY_PRODUCTS:
      return {
        ...state,
        isFetchingProducts: false,
        shopifyProducts: action.shopifyProducts,
        missingBarcodes: action.missingBarcodes,
      };
    case SET_LOCATION:
      return {
        ...state,
        currentLocation: action.currentLocation,
      };
    case CONNECTED_STORE:
      return update(state, {
        shopifyLocations: {
          [action.locationIndex]: {
            connectedStore: { $set: action.storeId },
          },
        },
        defaultStockSync: { $set: action.defaultStockSync },
        isLoading: { $set: false },
      });
    case IMPORTED_LOCATIONS:
      return {
        ...state,
        isLoading: false,
      };
    case SHOPIFY_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.errorMessage,
      };
    case PRODUCTS_SYNC:
      return {
        ...state,
        syncProducts: action.syncProducts,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case RESET_CHAIN:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
