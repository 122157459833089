import React from "react";
import { StyleSheet, View } from "react-native";

const AttentionBox = (props) => {
  return <View style={[styles.box, props.style]}>{props.children}</View>;
};

const styles = StyleSheet.create({
  box: {
    marginVertical: 10,
    backgroundColor: "#fff596",
    borderStartWidth: 3,
    borderStartColor: "#ffa200",
    paddingHorizontal: 10,
    paddingVertical: 15,
    width: "100%",
    borderRadius: 4,
  },
});

export default AttentionBox;
