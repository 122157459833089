import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Modal } from "react-native";

// IMPORT GLOBAL COMPONENTS
import Divider from "../../components/dividers/Divider";
import Card from "../../components/containers/Card";
import FlatButton from "../../components/buttons/FlatButton";
import Loader from "../../components/loaders/facebookLoader";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// IMPORT LOCAL COMPONENTS
import PlanUpgradeBox from "../../subscription/components/PlanUpgradeBox";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as subscriptionActions from "../../redux/actions/subscription";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    planName: state.subscription.planName,
    isLoading: state.subscription.isLoading,
    productsLimit: state.subscription.productsLimit,
    productsUsage: state.subscription.productsUsage,
    storesLimit: state.subscription.storesLimit,
    storesUsage: state.subscription.storesUsage,
  };
};

////////// START FUNCTION CLASS /////////
const SubscriptionBox = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  // Initiate dispatch
  const dispatch = useDispatch();

  // LOAD SUBSCRIPTION
  useEffect(() => {
    dispatch(subscriptionActions.fetchSubscription());
  }, []);

  ////////////////// RETURN VIEW ///////////////////
  if (props.isLoading) {
    return (
      <Card style={styles.section}>
        <Loader />
      </Card>
    );
  }

  return (
    <Card style={styles.section}>
      {/* ///// UPGRADE PLAN MODAL */}
      <Modal animationType="fade" transparent={true} visible={modalVisible}>
        <PlanUpgradeBox onClose={closeModal} />
      </Modal>
      {/* ///// END OF MODAL */}
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 1 }]}>
          <View style={Styles.row}>
            <Text style={Styles.H2}>Active Plan:</Text>
            <Text style={[Styles.H5, { marginStart: 20 }]}>
              {props.planName}
            </Text>
          </View>
        </View>
        <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
          <FlatButton
            title="Upgrade"
            onPress={() => setModalVisible(true)}
            icon="ios-trending-up-outline"
          />
        </View>
      </View>
      <Divider />
      <View style={[Styles.row]}>
        <View style={styles.counter}>
          <Text style={styles.counterTitle}>Products Usage</Text>
          <Text style={styles.counterNum}>
            {props.productsUsage} / {props.productsLimit}
          </Text>
        </View>
        <View style={styles.counter}>
          <Text style={styles.counterTitle}>Stores Usage</Text>
          <Text style={styles.counterNum}>
            {props.storesUsage} / {props.storesLimit}
          </Text>
        </View>
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  section: {
    width: "100%",
    marginTop: 15,
    paddingHorizontal: 20,
    paddingVertical: 20,
  },

  counter: {
    flex: 1,
    borderWidth: 1,
    borderColor: Colors.lightBorder,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 10,
    paddingVertical: 10,
  },

  counterTitle: {
    fontSize: 16,
    marginBottom: 10,
  },

  counterNum: {
    fontSize: 18,
    fontWeight: "600",
    color: Colors.primary,
  },
});

export default connect(mapStateToStore)(SubscriptionBox);
