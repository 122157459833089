import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Modal } from "react-native";

// IMPORT GLOBAL COMPONENTS
import FlatButton from "../../components/buttons/FlatButton";
import AddChainModal from "../../chains/components/AddChainModal";
import AttentionBox from "../../components/containers/AttentionBox";
import SuccessBox from "../../components/containers/SuccessBox";
import Loader from "../../components/loaders/ContentLoader";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

//// IMPORT FORM LIBS
import RNPickerSelect from "react-native-picker-select";
import { Formik } from "formik";
import * as yup from "yup";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as connectionsActions from "../../redux/actions/connections";
import * as chainsActions from "../../redux/actions/chains";

// IMPORT NAVIGATION
import * as RootNavigation from "../../routes/RootNavigation";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userRetailChains: state.chains.userRetailChains,
    isFetchigStores: state.stores.isFetchigStores,
    isRequestVerified: state.connections.isRequestVerified,
    isLoadingConnection: state.connections.isLoading,
    errorMessage: state.connections.errorMessage,
    connectionUsername: state.connections.connectionUsername,
    isChainConnected: state.connections.isChainConnected,
  };
};

const selectChainSchema = yup.object({
  retailChainId: yup
    .string()
    .test("len", "Please choose a chain", (val) => val.length > 2)
    .required("Please choose a chain"),
});

////////// START FUNCTION CLASS /////////
const ConnectUserBox = (props) => {
  // Initiate dispatch
  const dispatch = useDispatch();

  const [alreadyConnected, setAlreadyConnected] = useState(false);
  const [connectionMessage, setConnectionMessage] = useState("");
  const [connectionType, setConnectionType] = useState(null);
  const [userChains, setUserChains] = useState([]);

  // FETCH PARAMS
  useEffect(async () => {
    if (props.queryParams) {
      if (props.queryParams.type === "shopify") {
        setConnectionType("Shopify");
        let connectedChain = null;
        props.userRetailChains.forEach((chain) => {
          if (chain.integrations) {
            if (
              chain.integrations.shopify.shopId ===
              props.queryParams.shop.split(".")[0]
            ) {
              connectedChain = chain;
            }
          }
        });
        if (connectedChain) {
          dispatch(chainsActions.selectChain(connectedChain.id, null));
          setAlreadyConnected(true);
          setConnectionMessage(
            "This Shopify store is already connected to the chain: " +
              connectedChain.retailChainId.name
          );
        }
      }
    }
  }, []);

  // GENERATE THE RETAIL CHAINS DATA
  useEffect(() => {
    let chains = props.userRetailChains.filter(
      (chain) => !chain.integrations.shopify
    );
    chains = chains.map((chain) => {
      return {
        label: chain.name,
        value: chain.id,
        key: chain.id,
      };
    });
    setUserChains(chains);
  }, [props.userRetailChains]);

  // MODAL STATE TO HANDLE NO CHAINS
  const [modalVisible, setModalVisible] = useState(false);

  // CLOSE MODAL FUNCTION
  const closeModal = () => {
    setModalVisible(false);
  };

  // FUNCTION TO GENERATE NEW CHAIN
  const newChainFromConnection = () => {
    dispatch(connectionsActions.newChainFromConnection());
  };

  /////////////////////// RETURN VIEW //////////////////////////////

  // LOADING SECTION
  if (props.isLoadingConnection || props.isFetchigStores) {
    return (
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Loader />
        </View>
      </View>
    );
  }

  // THIS ENTITIY IS ALREADY CONNECTED SECTION
  if (alreadyConnected) {
    return (
      <View>
        <Text>{connectionMessage}</Text>
        <RoundedButton
          onPress={() =>
            RootNavigation.navigate("AppNavigator", {
              screen: "Dashboard",
            })
          }
        >
          <Text>Connection Settings</Text>
        </RoundedButton>
      </View>
    );
  }

  // CHAIN CONNECTED SECTION
  if (props.isChainConnected) {
    return (
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 1 }]}>
          <SuccessBox>
            <Text>Chain is now connected!</Text>
          </SuccessBox>
          <FlatButton
            title="Get Started"
            onPress={() => window.open("https://cloud.airstore.app/", "_self")}
          />
        </View>
      </View>
    );
  }

  // IS VERIFIED SECTION
  if (props.isRequestVerified) {
    return (
      <View style={[Styles.col, { width: "100%" }]}>
        {/* ///// ADD CHAIN MODAL */}
        <Modal animationType="fade" transparent={true} visible={modalVisible}>
          <AddChainModal onClose={closeModal} />
        </Modal>
        {/* ///// END STORE MODAL */}
        <Text style={{ fontSize: 16 }}>Connecting {connectionType}:</Text>
        <View style={styles.connectionBox}>
          <Text>{props.connectionUsername}</Text>
        </View>
        <Formik
          initialValues={{
            retailChainId: 0,
          }}
          validationSchema={selectChainSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const selectedChain = props.userRetailChains.find(
              (chain) => chain.id === values.retailChainId
            );
            await dispatch(
              connectionsActions.setConnection(
                props.queryParams.type,
                values.retailChainId
              )
            );
            setSubmitting(false);
            dispatch(chainsActions.selectChain(selectedChain));
          }}
        >
          {(formikProps) => (
            <View style={Styles.row}>
              <View style={[Styles.col, { flex: 1 }]}>
                <Text style={{ fontSize: 16, marginBottom: 10 }}>
                  To Retail Chain:
                </Text>
                {userChains.length > 0 ? (
                  <View style={{ width: "100%" }}>
                    <RNPickerSelect
                      placeholder={{
                        label: "Select a retail chain...",
                        value: 0,
                      }}
                      style={{
                        inputIOS: Styles.picker,
                        inputAndroid: Styles.picker,
                        inputWeb: Styles.picker,
                      }}
                      items={userChains}
                      onValueChange={(value) => {
                        formikProps.setFieldValue("retailChainId", value);
                      }}
                    />
                    <Text style={styles.errorMessageText}>
                      {formikProps.touched.retailChainId}
                      {formikProps.errors.retailChainId}
                    </Text>
                    <View style={{ width: "100%" }}>
                      <FlatButton
                        onPress={formikProps.handleSubmit}
                        title="Connect"
                        isLoading={formikProps.isSubmitting}
                      />
                    </View>
                  </View>
                ) : (
                  <FlatButton
                    title="Add Retail Chain"
                    onPress={() => setModalVisible(true)}
                    icon="add-outline"
                  />
                )}
              </View>
            </View>
          )}
        </Formik>
        <View style={{ width: "100%" }}>
          <Text>
            Create a new Retail Chain that matches the details of -{" "}
            {props.connectionUsername}{" "}
          </Text>
          <FlatButton
            onPress={newChainFromConnection}
            title="Create Retail Chain From Connection"
          />
        </View>
      </View>
    );
  }

  // NO CONNECTION SECTION
  if (!connectionType) {
    return (
      <AttentionBox>
        <Text style={Styles.p}>No connection request</Text>
      </AttentionBox>
    );
  }

  // CONNECTION FAILED SECTION
  return (
    <AttentionBox>
      <Text style={Styles.p}>Failed to connect</Text>
      <Text style={Styles.p}>{props.errorMessage}</Text>
    </AttentionBox>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },

  imageContainer: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
  },

  content: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
    paddingHorizontal: 10,
  },

  form: {
    maxWidth: 600,
  },

  image: {
    width: 270,
    height: 65,
    resizeMode: "contain",
  },

  connectionBox: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderRadius: 5,
    backgroundColor: Colors.activeItem,
    marginVertical: 10,
  },

  connectionsList: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  errorMessage: {
    paddingVertical: "2%",
    justifyContent: "center",
    alignItems: "center",
  },

  errorMessageText: {
    color: "crimson",
    padding: 5,
    fontWeight: "bold",
  },
});

export default connect(mapStateToStore)(ConnectUserBox);
