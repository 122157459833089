import React, { useState } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  Modal,
  View,
  ActivityIndicator,
} from "react-native";

// IMPORT GLOBAL COMPONENTS
import DropdownSelector from "../../components/form/DropdownSelector";
import * as RootNavigation from "../../routes/RootNavigation";

// IMPORT LOCAL COMPONENTS
import SelectStoreModal from "./SelectStoreModal";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Importing Redux state components
import { connect, useDispatch } from "react-redux";
import * as chainsActions from "../../redux/actions/chains";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    currentStore: state.stores.currentStore,
    currentChain: state.chains.currentChain,
    isLoading: state.stores.isFetchigStores,
    isError: state.stores.isError,
    errorMessage: state.stores.errorMessage,
  };
};

///////////// START FUNCTION CLASS //////////////////
const SelectStoreWidget = (props) => {
  // Initiate dispatch
  const dispatch = useDispatch();

  // modal state
  const [modalVisible, setModalVisible] = useState(false);

  // CLOSE MODAL FUNCTION
  const closeModal = () => {
    setModalVisible(false);
  };
  //////////////////// RETURN VIEW ///////////////////
  return (
    <View style={styles.widget}>
      {/* Setting up modal for store selection */}
      <Modal animationType="fade" transparent={true} visible={modalVisible}>
        <SelectStoreModal onClose={closeModal} />
      </Modal>
      <View style={[Styles.row, { marginBottom: 20 }]}>
        <Text style={Styles.H5}>{props.currentChain.name}</Text>
        <Text
          style={styles.link}
          onPress={() => {
            dispatch(chainsActions.resetChain());
            RootNavigation.navigate("SelectChain");
          }}
        >
          Change
        </Text>
      </View>
      {props.isLoading ? (
        <ActivityIndicator size="large" color={Colors.primary} />
      ) : props.isError ? (
        <TouchableOpacity
          onPress={() =>
            RootNavigation.navigate("AppNavigator", {
              screen: "ChainSettings",
            })
          }
        >
          <DropdownSelector text={"Add New Store"} />
        </TouchableOpacity>
      ) : (
        <TouchableOpacity onPress={() => setModalVisible(true)}>
          <DropdownSelector text={props.currentStore.name} />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  widget: {
    width: "100%",
  },

  selectStoreBar: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },

  link: {
    marginStart: 10,
    fontSize: 12,
    textDecorationLine: "underline",
  },
});

export default connect(mapStateToStore)(SelectStoreWidget);
