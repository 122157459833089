export default {
  primary: "#710DBF",

  primaryLight: "#765af2",

  accent: "#ccc",

  lightBorder: "#e3e1e1",

  danger: "#d62f2f",

  activeGreen: "#34c759",

  activeItem: "#ece9f0",

  blueLink: "#3575de",
};
