import React, { useState, useEffect } from "react";
import {
  ScrollView,
  StyleSheet,
  KeyboardAvoidingView,
  View,
  Text,
  Image,
  Platform,
  Modal,
} from "react-native";

// Importing Redux state components
import { connect, useDispatch } from "react-redux";
import * as authActions from "../../redux/actions/auth";

// IMPORT GLOBAL COMPONENTS
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";
import { LinearGradient } from "expo-linear-gradient";
import AttentionBox from "../../components/containers/AttentionBox";
import ErrorBox from "../../components/containers/ErrorBox";

// IMPORT LOCAL COMPONENTS
import LoginBox from "../components/LoginBox";
import PasswordResetModal from "../components/PasswordResetModal";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Connect login screen with the redux state
const mapStateToStore = (state) => {
  return {
    successMessage: state.auth.successMessage,
    isLoginError: state.auth.isLoginError,
    errorMessage: state.auth.errorMessage,
    isEmailError: state.auth.isEmailError,
  };
};

////////// START FUNCTION CLASS /////////
const LoginScreen = (props) => {
  // Hide header
  useEffect(() => {
    props.navigation.setOptions({ headerShown: false });
  }, []);

  // Initiate dispatch
  const dispatch = useDispatch();

  // HANDLE MODAL STATE
  const [modalVisible, setModalVisible] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };

  // Handle email confirmation
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    if (props.route.params) {
      const queryParams = props.route.params;
      if (queryParams.action === "confirmEmail") {
        if (queryParams.token && queryParams.tokenId) {
          dispatch(
            authActions.confirmEmail(queryParams.token, queryParams.tokenId)
          );
        }
        setUserEmail(queryParams.email);
      }
    }
  }, []);

  //////////// RETURN VIEW ////////
  return (
    <LinearGradient
      // Background Linear Gradient
      colors={["#6a0ed1", "#9c06b9"]}
      start={{ x: 0.2, y: 0.2 }}
      end={{ x: 0.7, y: 0.6 }}
      locations={[0.1, 0.9]}
      style={styles.background}
    >
      {/* //// START PASSWORD RESET MODAL ///// */}
      <Modal animationType="fade" transparent={true} visible={modalVisible}>
        <PasswordResetModal onClose={closeModal} />
      </Modal>
      {/* //// END MODAL ///// */}
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <ScrollView>
          <View style={Styles.row}>
            <View style={[Styles.col, styles.imageContainer]}>
              <Image
                source={require("../../../assets/company-logo.png")}
                style={styles.image}
              />
            </View>
          </View>
          <View style={Styles.row}>
            <View style={[Styles.col, styles.content]}>
              <Card style={styles.card}>
                <View style={Styles.row}>
                  <View style={[Styles.col]}>
                    <Text style={Styles.H1}>Login</Text>
                  </View>
                </View>
                <Divider />
                {/* Messages section */}
                {props.isEmailError ? (
                  <AttentionBox>
                    <Text>
                      {props.errorMessage}
                      <Text
                        onPress={() =>
                          dispatch(
                            authActions.resendConfirmationEmail(userEmail)
                          )
                        }
                        style={styles.resendLink}
                      >
                        Resend confirmation email
                      </Text>
                    </Text>
                  </AttentionBox>
                ) : props.isLoginError ? (
                  <ErrorBox>
                    <Text>{props.errorMessage}</Text>
                  </ErrorBox>
                ) : null}
                {/* Form section */}
                <LoginBox />
                {/* Password reset link */}
                <Text
                  style={styles.resetPassword}
                  onPress={() => setModalVisible(true)}
                >
                  Forgot password?
                </Text>
                <Divider />
                <FlatButton
                  title="Sign Up"
                  onPress={() => props.navigation.navigate("SignUp")}
                  buttonStyle={{
                    backgroundColor: "transparent",
                    borderColor: Colors.primary,
                    borderWidth: 1,
                  }}
                  titleStyle={{ color: Colors.primary }}
                />
              </Card>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },

  inner: {
    flex: 1,
    justifyContent: "center",
  },

  imageContainer: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
  },

  content: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
    paddingHorizontal: 10,
  },

  card: {
    maxWidth: 600,
  },

  image: {
    width: 270,
    height: 65,
    resizeMode: "contain",
  },

  checkbox: {
    backgroundColor: "transparent",
    borderWidth: 0,
    padding: 0,
  },

  resendLink: {
    color: "blue",
    fontWeight: "500",
    paddingLeft: 10,
  },

  resetPassword: {
    fontSize: 16,
    color: Colors.blueLink,
    fontWeight: "400",
    paddingLeft: 10,
    textDecorationLine: "underline",
    alignSelf: "center",
  },
});

export default connect(mapStateToStore)(LoginScreen);
