import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  Image,
  Dimensions,
  View,
} from "react-native";

// IMPORT GLOBAL COMPONENTS
import Card from "../../components/containers/Card";
import UnitBox from "../../components/containers/UnitBox";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

const ProductTile = (props) => {
  return (
    <TouchableOpacity style={[styles.container]} onPress={props.onPress}>
      <Card>
        <Image
          source={{ uri: props.product.catalogInformation.media.mainImageUrl }}
          style={styles.image}
        />
        <Text numberOfLines={2} style={styles.title}>
          {props.product.catalogInformation.title}
        </Text>
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 1 }]}>
            <UnitBox unit={"Qt."} value={props.product.stock} />
          </View>
          <View style={[Styles.col, { flex: 1 }]}>
            <UnitBox unit={"$"} value={props.product.price} />
          </View>
        </View>
      </Card>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 5,
    justifyContent: "center",
  },

  image: {
    maxWidth: 300,
    width: "100%",
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: "center",
  },

  title: {
    color: "#000000",
    fontSize: 15,
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "500",
  },
});

export default ProductTile;
