import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";

// IMPORT GLOBAL COMPONENTS
import DropdownSelector from "../../components/form/DropdownSelector";

// IMPORT LOCAL COMPONENTS

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// Importing Redux state components
import { connect } from "react-redux";

const mapStateToStore = (state) => {
  return {
    chainStores: state.stores.chainStores,
  };
};

///////// START FUNCTION CLASS ///////////////////////
const ShopifyLocationRow = (props) => {
  // Match location to connected store
  let connectedStoreName = "No store connected";
  if (props.location.connectedStore) {
    let connectedStore = props.chainStores.find(
      (store) =>
        store.id.toString() === props.location.connectedStore.toString()
    );
    if (connectedStore) {
      connectedStoreName = connectedStore.name;
    }
  }

  ////////////// RETURN VIEW /////////////////////
  return (
    <View style={styles.locationRow}>
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text style={[Styles.H3]}>{props.location.name}</Text>
          <Text>
            {props.location.address}, {props.location.city}
          </Text>
        </View>
        <View style={[Styles.col, { flex: 1 }]}>
          <TouchableOpacity
            onPress={() => props.connectStore(props.location)}
            style={{ width: "100%" }}
          >
            <DropdownSelector text={connectedStoreName} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  locationRow: {
    paddingVertical: 10,
    borderColor: "#c0c0c0",
    borderBottomWidth: 1,
    width: "100%",
  },
});

export default connect(mapStateToStore)(ShopifyLocationRow);
