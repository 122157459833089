import { LOGOUT } from "../actions/auth";

import { RESET_CHAIN } from "../actions/chains";

import {
  LOADING_CONNECTION,
  REQUEST_VERIFIED,
  REQUEST_FAILED,
  CHAIN_CONNECTED,
  READY_FOR_LOGIN,
} from "../actions/connections";

const initialState = {
  isLoading: false,
  isRequestVerified: false,
  errorMessage: "",
  connectionUsername: null,
  userEmail: null,
  signedUp: false,
  isChainConnected: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_CONNECTION:
      return {
        ...state,
        isLoading: true,
      };
    case REQUEST_VERIFIED:
      return {
        ...state,
        isLoading: false,
        isRequestVerified: true,
        connectionUsername: action.connectionUsername,
      };
    case READY_FOR_LOGIN:
      return {
        ...state,
        signedUp: action.signedUp,
        userEmail: action.userEmail,
      };
    case REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isRequestVerified: false,
        errorMessage: action.errorMessage,
      };
    case CHAIN_CONNECTED:
      return {
        ...state,
        isLoading: false,
        isChainConnected: true,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case RESET_CHAIN:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
