import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Modal } from "react-native";

// IMPORT LOCAL COMPONENTS
import ShopifySettings from "../../shopify/components/ShopifySettings";
import StoresList from "../components/StoresList";
import SubscriptionBox from "../components/SubscriptionBox";
import AddStoreModal from "../../stores/components/AddStoreModal";

// IMPORT GLOBAL COMPONENTS
import Screen from "../../components/containers/Screen";
import Divider from "../../components/dividers/Divider";
import FlatButton from "../../components/buttons/FlatButton";

// IMPORT GLOBAL STYLE
import Styles from "../../styles/Styles";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    isLoading: state.connections.isLoading,
    currentChain: state.chains.currentChain,
    shopifyProducts: state.connections.shopifyProducts,
    shopifyProductsWithBarcodes: state.connections.shopifyProductsWithBarcodes,
  };
};

////////// START FUNCTION CLASS /////////
const ChainSettingsScreen = (props) => {
  // Initiate dispatch
  const dispatch = useDispatch();

  // MODAL STATE
  const [modalVisible, setModalVisible] = useState(false);

  // CLOSE MODAL FUNCTION
  const closeModal = () => {
    setModalVisible(false);
  };

  // Hide header
  useEffect(() => {
    props.navigation.setOptions({ headerShown: false });
  }, []);

  //////////// RETURN VIEW ////////
  return (
    <Screen>
      {/* ///// ADD STORE MODAL */}
      <Modal animationType="fade" transparent={true} visible={modalVisible}>
        <AddStoreModal onClose={closeModal} />
      </Modal>
      {/* ///// END STORE MODAL */}
      <View style={[Styles.row, styles.header]}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text style={Styles.smallNote}>Chain Settings</Text>
          <Text style={Styles.H1}>{props.currentChain.name}</Text>
        </View>
      </View>
      <Divider />

      {/* /////// Stores section //////// */}
      <View style={styles.section}>
        <View style={[Styles.row]}>
          <View style={[Styles.col, { flex: 1 }]}>
            <Text style={Styles.H2}>Stores</Text>
          </View>
          <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
            <FlatButton
              title="Add Store"
              onPress={() => setModalVisible(true)}
              icon="add-outline"
            />
          </View>
        </View>
        <Divider />
        <StoresList />
      </View>
      {/* /////// Subscription section //////// */}
      <View style={styles.section}>
        <Text style={Styles.H2}>Subscription</Text>
        <Divider />
        <SubscriptionBox />
      </View>
      {/* /////// Integrations section //////// */}
      <View style={styles.section}>
        <Text style={Styles.H2}>Integrations</Text>
        <Divider />
        {props.currentChain.integrations.shopify ? (
          <ShopifySettings />
        ) : (
          <Text>No integrations</Text>
        )}
      </View>
 
    </Screen>
  );
};

const styles = StyleSheet.create({
  section: {
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingHorizontal: 10,
    marginBottom: 30,
  },

  header: {
    marginTop: 20,
  },
});

export default connect(mapStateToStore)(ChainSettingsScreen);
