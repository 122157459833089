import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  ActivityIndicator,
} from "react-native";

import Ionicons from "@expo/vector-icons/Ionicons";
import Colors from "../../styles/Colors";

const FlatButton = (props) => {
  return (
    <TouchableOpacity
      style={[styles.button, props.buttonStyle]}
      onPress={() => props.onPress()}
    >
      {props.icon ? (
        <Ionicons
          name={props.icon}
          size={25}
          style={[styles.icon, props.iconStyle]}
        />
      ) : null}
      {!props.isLoading ? (
        <Text style={[styles.title, props.titleStyle]}>{props.title}</Text>
      ) : (
        <ActivityIndicator color="#ffffff" size="small" />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primaryLight,
    marginVertical: 10,
  },

  title: {
    color: "#ffffff",
    fontSize: 16,
    fontWeight: "500",
  },

  icon: {
    color: "#ffffff",
    marginEnd: 5,
  },
});

export default FlatButton;
