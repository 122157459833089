import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

// / IMPORT GLOBAL COMPONENTS
import ListRow from "../../components/containers/ListRow";
import Ionicons from "@expo/vector-icons/Ionicons";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as storesActions from "../../redux/actions/stores";

// Connect the selected store with the state
const mapStateToStore = (state) => {
  return {
    currentStore: state.stores.currentStore,
  };
};

/////////// START FUNCTION CLASS /////////
const StoreRow = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  // Initiate dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.store.id === props.currentStore.id) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [props.currentStore]);

  //////////// RETURN VIEW ////////
  return (
    <ListRow>
      <TouchableOpacity
        onPress={() => {
          dispatch(storesActions.selectStore(props.store));
        }}
        style={[
          styles.selectRow,
          isSelected ? { backgroundColor: Colors.activeItem } : null,
        ]}
      >
        {/* <Image source={{ uri: props.store.iconUrl }} style={styles.storeIcon} /> */}
        <View style={[Styles.col, { flex: 3 }]}>
          <Text style={Styles.H3}>{props.store.name}</Text>
        </View>
        <View
          style={[
            Styles.col,
            { flex: 1, alignItems: "flex-end", paddingEnd: 20 },
          ]}
        >
          {/* /////////// DELETE BUTTON ////////////////// */}
          <Ionicons
            name={"trash-outline"}
            size={24}
            color="red"
            onPress={() => {
              dispatch(storesActions.deleteStore(props.store.id));
              setIsLoading(true);
            }}
          />
        </View>
      </TouchableOpacity>
    </ListRow>
  );
};

const styles = StyleSheet.create({
  selectRow: {
    width: "100%",
    flexDirection: "row",
    borderRadius: 5,
    paddingStart: 10,
    paddingVertical: 10,
  },
});

export default connect(mapStateToStore)(StoreRow);
