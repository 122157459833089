import React from "react";
import { StyleSheet, View } from "react-native";

import Colors from "../../styles/Colors";

const VerticalDivider = (props) => {
  return <View style={[styles.divider, props.style]} />;
};

const styles = StyleSheet.create({
  divider: {
    height: "80%",
    borderColor: Colors.lightBorder,
    borderStartWidth: 2,
    paddingStart: 10,
    marginStart: 10,
  },
});

export default VerticalDivider;
