import React from "react";
import { StyleSheet, Text } from "react-native";

// IMPORT LOCAL COMPONENTS
import ShopifyStoresBox from "./ShopifyStoresBox";
import ShopifyOrdersBox from "./ShopifyOrdersBox";
import ShopifyProductsBox from "./ShopifyProductsBox";
import ShopifyLostConnectionBox from "./ShopifyLostConnectionBox";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

///////// START FUNCTION CLASS ///////////////////////
const ActionBox = (props) => {
  ////////////// RETURN VIEW /////////////////////
  if (props.notification.id === "01") {
    return <ShopifyStoresBox notification={props.notification} />;
  }
  if (props.notification.id === "02") {
    return <ShopifyProductsBox notification={props.notification} />;
  }
  if (props.notification.id === "03") {
    return <ShopifyOrdersBox notification={props.notification} />;
  }
  if (props.notification.id === "04") {
    return <ShopifyLostConnectionBox notification={props.notification} />;
  }
  return <Text>No notifications</Text>;
};

const styles = StyleSheet.create({});

export default ActionBox;
