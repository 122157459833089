import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

// IMPORT GLOBAL COMPONENTS
import Ionicons from "@expo/vector-icons/Ionicons";

const BackButton = (props) => {
  return (
    <TouchableOpacity style={styles.backButton} onPress={props.navigation}>
      <Ionicons name={"arrow-back-outline"} size={21} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  backButton: {
    backgroundColor: "#ffffff",
    padding: 3,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 3,
    shadowOpacity: 0.26,
    elevation: 1,
  },
});

export default BackButton;
