import {
  LOGIN,
  LOGOUT,
  AUTH_SUCCESS,
  AUTH_ERROR,
  EMAIL_ERROR,
  RESET_ERROR,
} from "../actions/auth";

const initialState = {
  isSignedIn: false,
  token: "",
  successMessage: null,
  isLoginError: false,
  isEmailError: false,
  isResetError: false,
  errorMessage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.token,
        isSignedIn: action.isSignedIn,
        isLoginError: false,
      };
    case LOGOUT:
      return {
        ...state,
        isSignedIn: false,
        token: "",
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        isLoginError: false,
        successMessage: action.successMessage,
      };
    case AUTH_ERROR:
      return {
        ...state,
        isLoginError: true,
        errorMessage: action.errorMessage,
      };
    case EMAIL_ERROR:
      return {
        ...state,
        isEmailError: true,
        errorMessage: action.errorMessage,
      };
    case RESET_ERROR:
      return {
        ...state,
        isResetError: true,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};
