import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  Image,
  View,
  Dimensions,
} from "react-native";

// IMPORT GLOBAL COMPONENTS
import ListRow from "../../components/containers/ListRow";
import DateBox from "../../components/containers/DateBox";

// IMPORT LOCAL COMPONENTS
import StatusCapsule from "./StatusCapsule";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

const OrderRow = (props) => {
  // DETERMINE SCREEN SIZE
  const [device, setDevice] = useState("mobile");

  useEffect(() => {
    const { height, width } = Dimensions.get("window");
    const aspectRatio = height / width;
    // mobile device
    if (aspectRatio > 1.6) {
      setDevice("mobile");
      // tablet
    } else if (aspectRatio > 1) {
      setDevice("tablet");
      // desktop
    } else {
      setDevice("desktop");
    }
  }, []);

  //////////////////// RETURN VIEW ///////////////////
  /// FOR Mobile devices
  if (device === "mobile") {
    return (
      <ListRow>
        <TouchableOpacity style={[Styles.col, { flex: 1 }]} onPress={() => {}}>
          <View style={[Styles.col, { flex: 5 }]}>
            <View style={Styles.row}>
              {/* Column 1 - Source */}
              <View style={[Styles.col, { flex: 2 }]}>
                <DateBox date={props.order.orderDate} />
              </View>
              {/* Column 3 - Customer and order number */}
              <View style={[Styles.col, { flex: 6 }]}>
                <Text style={styles.orderNumber}>
                  # {props.order.orderNumber}
                </Text>
                <Text style={Styles.H3}>
                  {props.order.customer.firstName}{" "}
                  {props.order.customer.lastName}
                </Text>
              </View>
              <View style={[Styles.col, { flex: 2 }]}>
                {/* Column 5 - Order total */}
                <Text style={Styles.H5}>$ {props.order.totalPrice}</Text>
                <Text style={Styles.smallNote}>
                  {props.order.itemsCount} Items
                </Text>
              </View>
            </View>
            <View style={[Styles.row, { marginTop: 10 }]}>
              <View style={[Styles.col, { flex: 1 }]}>
                {/* Column 1 - Order date */}
                {props.order.source === "Shopify" ? (
                  <Image
                    source={require("../../../assets/shopify-icon.png")}
                    style={styles.orderSourceIconMobile}
                    accessible={false}
                  />
                ) : (
                  <Text>N/A</Text>
                )}
              </View>
              <View style={[Styles.col, { flex: 6 }]}>
                <View style={[Styles.row]}>
                  {/* Column 4 - Order status */}
                  <StatusCapsule
                    type="payment"
                    status={props.order.payments.paymentStatus}
                  />
                  <StatusCapsule
                    type="fulfillment"
                    status={props.order.fulfillments.fulfillmentStatus}
                  />
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </ListRow>
    );
  }

  /// For Tablet and Desktop
  return (
    <ListRow>
      <TouchableOpacity style={[Styles.col, { flex: 1 }]} onPress={() => {}}>
        <View style={Styles.row}>
          {/* Column 1 - Source */}
          <View style={[Styles.col, { flex: 1 }]}>
            {props.order.source === "Shopify" ? (
              <Image
                source={require("../../../assets/shopify-icon.png")}
                style={styles.orderSourceIcon}
                accessible={false}
              />
            ) : (
              <Text>N/A</Text>
            )}
          </View>
          {/* Column 2 - Order date */}
          <View style={[Styles.col, { flex: 1 }]}>
            <DateBox date={props.order.orderDate} />
          </View>
          {/* Column 3 - Customer and order number */}
          <View style={[Styles.col, { flex: 3 }]}>
            <Text style={styles.orderNumber}># {props.order.orderNumber}</Text>
            <Text style={Styles.H3}>
              {props.order.customer.firstName} {props.order.customer.lastName}
            </Text>
          </View>
          {/* Column 4 - Order status */}
          <View style={[Styles.col, { flex: 3 }]}>
            <View style={Styles.row}>
              <StatusCapsule
                type="payment"
                status={props.order.payments.paymentStatus}
              />
              <StatusCapsule
                type="fulfillment"
                status={props.order.fulfillments.fulfillmentStatus}
              />
            </View>
          </View>
          {/* Column 5 - Order total */}
          <View style={[Styles.col, { flex: 1 }]}>
            <Text style={Styles.H5}>$ {props.order.totalPrice}</Text>
            <Text style={Styles.smallNote}>{props.order.itemsCount} Items</Text>
          </View>
        </View>
      </TouchableOpacity>
    </ListRow>
  );
};

const styles = StyleSheet.create({
  orderSourceIcon: {
    maxWidth: 35,
    width: "100%",
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: "flex-start",
  },

  orderSourceIconMobile: {
    maxWidth: 25,
    width: "100%",
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: "flex-start",
  },

  orderNumber: {
    fontSize: 16,
    color: Colors.accent,
  },
});

export default OrderRow;
