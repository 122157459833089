import React, { useEffect } from "react";
import { StyleSheet, View, Text, Platform } from "react-native";

//// IMPORT FORM LIBS
import { Formik } from "formik";
import * as yup from "yup";

// IMPORT GLOBAL COMPONENTS
import InputText from "../../components/form/InputText";
import FlatButton from "../../components/buttons/FlatButton";
import AttentionBox from "../../components/containers/AttentionBox";
import SuccessBox from "../../components/containers/SuccessBox";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as authActions from "../../redux/actions/auth";

// Connect login screen with the redux state
const mapStateToStore = (state) => {
  return {
    successMessage: state.auth.successMessage,
    isError: state.auth.isResetError,
    errorMessage: state.auth.errorMessage,
  };
};

const singupSchema = yup.object({
  newPassword: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&~])[A-Za-z\d@$!%*#?&~]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

////////// START FUNCTION CLASS /////////
const ChangePasswordBox = (props) => {
  // Initiate dispatch
  const dispatch = useDispatch();

  //////////// RETURN VIEW ////////
  return (
    <View style={styles.content}>
      {/* Messages section */}
      {props.successMessage ? (
        <SuccessBox>
          <Text>{props.successMessage}</Text>
        </SuccessBox>
      ) : props.isError ? (
        <AttentionBox>
          <Text>{props.errorMessage}</Text>
        </AttentionBox>
      ) : (
        <Formik
          initialValues={{
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={singupSchema}
          onSubmit={async (values, { setSubmitting }) => {
            let form = {
              username: props.username,
              token: props.token,
              tokenId: props.tokenId,
              newPassword: values.newPassword,
            };
            await dispatch(authActions.changePassword(form));
            setSubmitting(false);
          }}
        >
          {(formikProps) => (
            <View style={styles.form}>
              <InputText
                placeholder="New Password"
                onChangeText={formikProps.handleChange("newPassword")}
                value={formikProps.values.newPassword}
                onBlur={formikProps.handleBlur("newPassword")}
                errorMessage={
                  formikProps.touched.newPassword &&
                  formikProps.errors.newPassword
                }
                secureTextEntry={true}
              />
              <InputText
                placeholder="Confirm Password"
                onChangeText={formikProps.handleChange("confirmPassword")}
                value={formikProps.values.confirmPassword}
                onBlur={formikProps.handleBlur("confirmPassword")}
                errorMessage={
                  formikProps.touched.confirmPassword &&
                  formikProps.errors.confirmPassword
                }
                secureTextEntry={true}
              />
              <View style={(Styles.col, { flex: 1 })}>
                <FlatButton
                  title="Submit"
                  onPress={formikProps.handleSubmit}
                  isLoading={formikProps.isSubmitting}
                />
              </View>
            </View>
          )}
        </Formik>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  avoidingView: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  content: {
    width: "100%",
    alignItems: "center",
  },

  form: {
    width: "100%",
  },

  image: {
    width: 270,
    height: 65,
    resizeMode: "contain",
  },
});

export default connect(mapStateToStore)(ChangePasswordBox);
