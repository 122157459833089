import React from "react";
import { StyleSheet, TouchableOpacity, Platform } from "react-native";

// IMPORT GLOBAL COMPONENTS
import Ionicons from "@expo/vector-icons/Ionicons";

import * as RootNavigation from "../../routes/RootNavigation";

const BarcodeButton = (props) => {
  // Handle click
  const clickHandler = () => {
    RootNavigation.navigate("AppNavigator", {
      screen: "ProductsManagementNavigator",
      params: { screen: "Scanner" },
    });
  };
  ///////// RETURN VIEW ///////////
  return (
    <TouchableOpacity style={styles.button} onPress={clickHandler}>
      <Ionicons name={"barcode-outline"} size={30} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    marginEnd: 10,
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default BarcodeButton;
