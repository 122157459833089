import React from "react";
import { StyleSheet, ScrollView, Dimensions, Platform } from "react-native";

const Screen = (props) => {
  return (
    <ScrollView contentContainerStyle={styles.screen}>
      <ScrollView contentContainerStyle={styles.body}>
        {props.children}
      </ScrollView>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  screen: {
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },

  body: {
    maxWidth: Platform.OS === "web" ? 1200 : "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    width: Dimensions.get("window").width,
    paddingHorizontal: "1%",
  },
});

export default Screen;
