import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text } from "react-native";

// IMPORT GLOBAL COMPONENTS
import CloseButton from "../../components/buttons/CloseButton";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as storesActions from "../../redux/actions/stores";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    chainStores: state.stores.chainStores,
    currentStore: state.stores.currentStore,
  };
};

//// IMPORT FORM LIBS
import RNPickerSelect from "react-native-picker-select";
import { Formik } from "formik";

///////// START FUNCTION CLASS ///////////////////////
const SelectStoreModal = (props) => {
  const dispatch = useDispatch();

  const [selectStores, setSelectStores] = useState([]);

  useEffect(() => {
    let stores = props.chainStores.map((store) => {
      return {
        label: store.name,
        value: store.id,
        key: store.id,
      };
    });
    setSelectStores(stores);
  }, []);

  ////////////// RETURN VIEW  /////////////////////
  return (
    <View style={styles.centeredModal}>
      <Card style={styles.modalBox}>
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 5 }]}>
            <Text style={Styles.H2}>Select Store</Text>
          </View>
          <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
            <CloseButton onClose={() => props.onClose()} />
          </View>
        </View>
        <Divider />
        <Text style={[Styles.H4, { marginBottom: 10 }]}>
          In which store are you working at the moment?
        </Text>
        <Formik
          initialValues={{
            selectedStore: props.currentStore,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            dispatch(storesActions.selectStore(null, values.selectedStore));
            setSubmitting(false);
            props.onClose();
          }}
        >
          {(formikProps) => (
            <View style={styles.form}>
              <RNPickerSelect
                placeholder={{
                  key: "0",
                  label: "Select a store...",
                  value: null,
                }}
                style={{
                  inputIOS: Styles.picker,
                  inputAndroid: Styles.picker,
                  inputWeb: Styles.picker,
                }}
                items={selectStores}
                onValueChange={(value) => {
                  formikProps.setFieldValue("selectedStore", value);
                }}
              />
              <View>
                <FlatButton onPress={formikProps.handleSubmit} title="Submit" />
              </View>
            </View>
          )}
        </Formik>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredModal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingHorizontal: "5%",
  },

  modalBox: {
    maxWidth: 600,
    alignItems: "flex-start",
    marginTop: 15,
  },

  form: {
    width: "100%",
    justifyContent: "flex-start",
  },
});

export default connect(mapStateToStore)(SelectStoreModal);
