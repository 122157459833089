import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

import SERVER_CONSTANTS from "../../constants/airstoreServer";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const EMAIL_ERROR = "EMAIL_ERROR";
export const RESET_ERROR = "RESET_ERROR";

////////// LOGIN ////////////////////////////////////////////
export const login = (email, password, rememberMe) => {
  return async (dispatch) => {
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/auth/login",
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + password,
          },
          data: JSON.stringify({
            email: email,
          }),
        }
      );
      if (response.status === 200) {
        // Dispatch information to redux state
        dispatch({
          type: LOGIN,
          isSignedIn: true,
          token: response.data.token,
        });
        if (rememberMe === true) {
          await AsyncStorage.setItem("userToken", response.data.token);
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
          errorMessage: error.response.data.error,
        });
      }
      console.log(error);
    }
  };
};

////////// LOGOUT ////////////////////////////////////////////
export const logout = () => {
  return async (dispatch) => {
    try {
      await AsyncStorage.removeItem("userToken");
      await AsyncStorage.removeItem("currentChain");
      await AsyncStorage.removeItem("currentStore");
      dispatch({ type: LOGOUT });
    } catch (error) {
      console.error(error);
    }
  };
};

////////// SIGNUP ////////////////////////////////////////////
export const signUp = (form) => {
  return async (dispatch) => {
    let data = JSON.stringify({
      email: form.email,
      password: form.password,
    });
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/auth/signup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        }
      );
      if (response.status !== 201) {
        dispatch({
          type: AUTH_ERROR,
          errorMessage: response.data.message,
        });
      }
      dispatch({
        type: AUTH_SUCCESS,
        successMessage: response.data.message,
      });
    } catch (error) {
      console.log(error);
    }

    // saveDataToStorage(resData.token);
  };
};

////////// CONFIRM USER EMAIL ////////////////////////////////////////////
export const confirmEmail = (token, tokenId) => {
  return async (dispatch) => {
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/auth/confirm-email",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          params: { token: token, tokenId: tokenId },
        }
      );
      dispatch({
        type: AUTH_SUCCESS,
        successMessage: "Email confirmed, welcome aboard!",
      });
    } catch (error) {
      console.log(error.response.data.message);
      dispatch({
        type: EMAIL_ERROR,
        errorMessage: error.response.data.message,
      });
    }
  };
};

////////// CONFIRM USER EMAIL ////////////////////////////////////////////
export const resendConfirmationEmail = (email) => {
  return async (dispatch) => {
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/auth/resend-confirm-email",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          params: { email },
        }
      );
      dispatch({
        type: AUTH_SUCCESS,
        successMessage:
          "Email address confirmed. You can now login with your email and password.",
      });
    } catch (error) {
      console.log(error.response.data.message);
      dispatch({
        type: AUTH_ERROR,
        errorMessage: error.response.data.message,
      });
    }
  };
};

////////// CHECK IF USER HAS TOKEN STORED LOCALLY ////////////////////////////////////////////
export const checkLocalToken = () => {
  return async (dispatch) => {
    try {
      const token = await AsyncStorage.getItem("userToken");
      if (token) {
        dispatch({
          type: LOGIN,
          isSignedIn: true,
          token: token,
        });
        // STEP 2: Check token validity from server
        const response = await axios(
          SERVER_CONSTANTS.SERVER_URI + "/auth/check-token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        // STEP 3: If token is not valid remove from local storage
        if (response.data.message == "Token Expired") {
          dispatch(logout());
          dispatch({
            type: LOGOUT,
          });
          return;
        }
      }
      return;
    } catch (error) {
      console.log(error);
    }
  };
};

////////// REQUEST PASSWORD RESET ////////////////////////////////////////////
export const requestPasswordReset = (email) => {
  return async (dispatch) => {
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/auth/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            email: email,
          }),
        }
      );
      if (response.status !== 201) {
        dispatch({
          type: AUTH_ERROR,
          errorMessage: response.data.message,
        });
        return;
      }
      dispatch({
        type: AUTH_SUCCESS,
        successMessage: response.data.message,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

////////// CHANGE PASSWORD ////////////////////////////////////////////
export const changePassword = (form) => {
  return async (dispatch) => {
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/auth/change-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            username: form.username,
            token: form.token,
            tokenId: form.tokenId,
            newPassword: form.newPassword,
          }),
        }
      );

      dispatch({
        type: AUTH_SUCCESS,
        successMessage: response.data.message,
      });
    } catch (error) {
      dispatch({
        type: RESET_ERROR,
        errorMessage: error.response.data.message,
      });
      return;
    }
  };
};
