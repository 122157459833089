import React from "react";
import { StyleSheet, View, Text, TextInput } from "react-native";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

///////////// START FUNCTION CLASS //////////////////
const TextArea = (props) => {
  // UI element
  return (
    <View style={[Styles.col, props.containerStyle]}>
      <Text style={styles.label}>{props.label}</Text>
      <TextInput
        style={[styles.textInput, props.inputStyle]}
        placeholder={props.placeholder}
        onChangeText={props.onChangeText}
        value={props.value}
        onBlur={props.onBlur}
        editable={props.editable}
        selectTextOnFocus={props.selectTextOnFocus}
        onSubmitEditing={props.onSubmitEditing ? props.onSubmitEditing : null}
        returnKeyType={props.returnKeyType}
        multiline={true}
        numberOfLines={props.numberOfLines}
      />
      {props.errorMessage ? (
        <Text style={styles.errorMessage}>{props.errorMessage}</Text>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  textInput: {
    borderColor: "#000000",
    borderWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 10,
    borderRadius: 5,
    width: "100%",
    height: 120,
  },

  errorMessage: {
    color: "crimson",
    padding: 5,
    fontWeight: "bold",
  },
});

export default TextArea;
