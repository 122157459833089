import React, { useState } from "react";
import { StyleSheet, View, Text } from "react-native";

// IMPORT GLOBAL COMPONENTS
import CloseButton from "../../components/buttons/CloseButton";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";
import InputText from "../../components/form/InputText";
import SuccessBox from "../../components/containers/SuccessBox";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as authActions from "../../redux/actions/auth";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    currentChain: state.chains.currentChain,
    storesLeft: state.subscription.storesLimit - state.subscription.storesUsage,
  };
};

//// IMPORT FORM LIBS
import { Formik } from "formik";
import * as yup from "yup";

/// SET FORM VALIDATION SCHEMA
const emailSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email")
    .required("Please enter your email"),
});

///////// START FUNCTION CLASS ///////////////////////
const PasswordResetModal = (props) => {
  const dispatch = useDispatch();

  const [emailSent, setEmailSent] = useState(false);

  ////////////// RETURN VIEW /////////////////////
  return (
    <View style={styles.centeredModal}>
      <Card style={styles.modalBox}>
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 5 }]}>
            <Text style={Styles.H2}>Password Reset</Text>
          </View>
          <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
            <CloseButton onClose={() => props.onClose()} />
          </View>
        </View>
        <Divider />
        <Text style={[Styles.H5, { marginBottom: 10 }]}>
          Enter your email to change password
        </Text>
        {!emailSent ? (
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={emailSchema}
            onSubmit={async (values, { setSubmitting }) => {
              await dispatch(authActions.requestPasswordReset(values.email));
              setSubmitting(false);
              setEmailSent(true);
            }}
          >
            {(formikProps) => (
              <View style={styles.form}>
                <InputText
                  containerStyle={{ marginBottom: 10 }}
                  placeholder="Email"
                  onChangeText={formikProps.handleChange("email")}
                  value={formikProps.values.email}
                  onBlur={formikProps.handleBlur("email")}
                  errorMessage={
                    formikProps.touched.email && formikProps.errors.email
                  }
                  editable={true}
                  onSubmitEditing={formikProps.handleSubmit}
                  returnKeyType="done"
                />
                <View>
                  <FlatButton
                    onPress={formikProps.handleSubmit}
                    title="Submit"
                    isLoading={formikProps.isSubmitting}
                  />
                </View>
              </View>
            )}
          </Formik>
        ) : (
          <SuccessBox>
            <Text>Please check your inbox for a password reset link</Text>
            <Text>
              If you did not receive any email, make sure you entered the right
              email or contact support
            </Text>
          </SuccessBox>
        )}
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredModal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingHorizontal: "5%",
  },

  modalBox: {
    maxWidth: 600,
    alignItems: "flex-start",
    marginTop: 15,
  },

  form: {
    width: "100%",
    justifyContent: "flex-start",
  },
});

export default connect(mapStateToStore)(PasswordResetModal);
