import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text } from "react-native";

// IMPORT GLOBAL COMPONENTS
import FlatButton from "../../components/buttons/FlatButton";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// Importing Redux state components
import { connect } from "react-redux";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    chainStores: state.stores.chainStores,
  };
};

//// IMPORT FORM LIBS
import RNPickerSelect from "react-native-picker-select";
import { Formik } from "formik";

///////// START FUNCTION CLASS ///////////////////////
const StoreSelector = (props) => {
  const [selectStores, setSelectStores] = useState([]);

  useEffect(() => {
    let stores = props.chainStores.map((store) => {
      return {
        label: store.name,
        value: store.id,
        key: store.id,
      };
    });
    setSelectStores(stores);
  }, []);

  ////////////// RETURN VIEW  /////////////////////
  return (
    <View style={styles.selectorBox}>
      <Formik
        initialValues={{
          storeId: null,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          props.onPress(values.storeId);
          setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <View style={styles.form}>
            <RNPickerSelect
              placeholder={{
                key: "0",
                label: "Select a store...",
                value: undefined,
              }}
              style={{
                inputIOS: Styles.picker,
                inputAndroid: Styles.picker,
                inputWeb: Styles.picker,
              }}
              items={selectStores}
              onValueChange={(value) => {
                formikProps.setFieldValue("storeId", value);
              }}
            />
            <View>
              <FlatButton
                onPress={formikProps.handleSubmit}
                title={props.submitText}
              />
            </View>
          </View>
        )}
      </Formik>
    </View>
  );
};

const styles = StyleSheet.create({
  selectorBox: {
    width: "100%",
    marginVertical: 10,
  },

  form: {
    width: "100%",
    justifyContent: "flex-start",
  },
});

export default connect(mapStateToStore)(StoreSelector);
