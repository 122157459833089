import React from "react";
import { StyleSheet, View, Text } from "react-native";

// IMPORT GLOBAL STYLES
import Colors from "../../styles/Colors";
import Styles from "../../styles/Styles";

// IMPORT GLOBAL COMPONENTS

const fulfillmentDesign = (status) => {
  if (!status) return { color: "#e8b5b5", text: "Not Fulfilled" };
  if (status === "partial")
    return { color: "#ffd79d", text: "Partially Fulfilled" };
  if (status === "fulfilled") return { color: "#aee9d1", text: "Fulfilled" };
};

const paymentDesign = (status) => {
  if (status === "partially_paid")
    return { color: "#ffd79d", text: "Partially Paid" };
  if (status === "paid") return { color: "#aee9d1", text: "Paid" };
};

///////////// START FUNCTION CLASS //////////////////
const StatusCapsule = (props) => {
  let statusDesign;
  if (props.type === "fulfillment") {
    statusDesign = fulfillmentDesign(props.status);
  }
  if (props.type === "payment") {
    statusDesign = paymentDesign(props.status);
  }
  return (
    <View
      style={[
        styles.capsule,
        {
          backgroundColor: statusDesign.color,
        },
      ]}
    >
      <Text style={Styles.p}>{statusDesign.text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  capsule: {
    borderRadius: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginHorizontal: 5,
  },
});

export default StatusCapsule;
