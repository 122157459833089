import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  KeyboardAvoidingView,
  ActivityIndicator,
} from "react-native";

// IMPORT GLOBAL COMPONENTS
import InputText from "../../components/form/InputText";
import HtmlTextArea from "../../components/form/HtmlTextArea";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";
import Screen from "../../components/containers/Screen";
import BarcodeBox from "../../components/containers/BarcodeBox";
import BackButton from "../../components/buttons/BackButton";
import Ionicons from "@expo/vector-icons/Ionicons";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// IMPORT REDUX ACTIONS
import { connect, useDispatch } from "react-redux";
import * as singleProductActions from "../../redux/actions/singleProduct";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    isUpdatingProduct: state.singleProduct.isUpdatingProduct,
    currentProduct: state.singleProduct.currentProduct,
  };
};

//// IMPORT FORM LIBS
import { Formik } from "formik";
import * as yup from "yup";

/// SET FORM VALIDATION SCHEMA
const productSchema = yup.object({
  stock: yup.string(),
  price: yup.string(),
});

///////// START FUNCTION CLASS ///////////////////////
const EditProductScreen = (props) => {
  const [barcodeIssue, setBarcodeIssue] = useState(false);

  const dispatch = useDispatch();

  ////////////// RETURN VIEW /////////////////////
  return (
    <Screen>
      <View style={styles.editScreen}>
        <KeyboardAvoidingView behavior="padding">
          <View style={[Styles.row, styles.header]}>
            <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
              <BackButton navigation={() => props.navigation.goBack()} />
            </View>
            <View style={[Styles.col, { flex: 3 }]}>
              <Text style={Styles.H1}>Edit Product</Text>
            </View>
            <View style={[Styles.col, { flex: 3 }]}>
              <BarcodeBox barcode={props.currentProduct.internalId} />
            </View>
            <View style={[Styles.col, { flex: 1 }]}>
              <Ionicons
                name={"trash-outline"}
                size={21}
                colors={"red"}
                onPress={() => {
                  dispatch(
                    singleProductActions.deleteInventory(
                      props.currentProduct.internalId
                    )
                  );
                  props.navigation.navigate("AllProducts");
                }}
              />
            </View>
          </View>
          <Divider />
          <View style={Styles.row}>
            <View style={[Styles.col, { flex: 4 }]}>
              <Card style={styles.card}>
                <View style={styles.from}>
                  <Formik
                    id="productDescription"
                    initialValues={{
                      title: props.currentProduct.catalogInformation.title,
                      stock: props.currentProduct.stock,
                      price: props.currentProduct.price,
                    }}
                    validationSchema={productSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      await dispatch(
                        singleProductActions.updateInventory(
                          props.currentProduct.internalId,
                          values.stock,
                          values.price
                        )
                      );
                      setSubmitting(false);
                    }}
                  >
                    {(formikProps) => (
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <InputText
                            label="Title"
                            placeholder={props.currentProduct.title}
                            onChangeText={formikProps.handleChange("title")}
                            value={formikProps.values.title}
                            onBlur={formikProps.handleBlur("title")}
                            errorMessage={
                              formikProps.touched.title &&
                              formikProps.errors.title
                            }
                          />
                          <HtmlTextArea numberOfLines={10} />
                        </View>
                        <View style={Styles.row}>
                          <View style={[Styles.col, { flex: 1 }]}>
                            <Image
                              source={{
                                uri: props.currentProduct.catalogInformation
                                  .media.mainImageUrl,
                              }}
                              style={styles.pImage}
                            />
                          </View>
                        </View>
                      </View>
                    )}
                  </Formik>
                </View>
              </Card>
            </View>
            {/* STOCK AND PRICE SECTION */}
            <View style={[Styles.col, { flex: 1 }]}>
              <Card style={styles.card}>
                <View style={styles.from}>
                  <Formik
                    id="productInventory"
                    initialValues={{
                      stock: props.currentProduct.stock,
                      price: props.currentProduct.price,
                    }}
                    validationSchema={productSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      await dispatch(
                        singleProductActions.updateInventory(
                          props.currentProduct.internalId,
                          values.stock,
                          values.price
                        )
                      );
                      setSubmitting(false);
                    }}
                  >
                    {(formikProps) => (
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <InputText
                            label="Stock"
                            keyboardType="number-pad"
                            placeholder={props.currentProduct.stock.toString()}
                            onChangeText={formikProps.handleChange("stock")}
                            value={formikProps.values.stock}
                            onBlur={formikProps.handleBlur("stock")}
                            errorMessage={
                              formikProps.touched.stock &&
                              formikProps.errors.stock
                            }
                          />
                          <InputText
                            label="Price"
                            keyboardType="decimal-pad"
                            placeholder={
                              props.currentProduct.price
                                ? props.currentProduct.price.toString()
                                : "Add Price"
                            }
                            onChangeText={formikProps.handleChange("price")}
                            value={formikProps.values.price}
                            onBlur={formikProps.handleBlur("price")}
                            errorMessage={
                              formikProps.touched.price &&
                              formikProps.errors.price
                            }
                          />
                        </View>
                        <FlatButton
                          onPress={formikProps.isSubmitting}
                          style={styles.submitButton}
                        />
                      </View>
                    )}
                  </Formik>
                </View>
              </Card>
            </View>
          </View>
        </KeyboardAvoidingView>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  editScreen: {
    width: "100%",
    marginTop: 20,
  },

  pImage: {
    width: "100%",
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
  },

  inputCol: {
    flex: 1,
    padding: 0,
  },

  form: {
    width: "100%",
  },

  buttonRow: {
    width: 300,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },

  removeProduct: {
    backgroundColor: "red",
  },

  removeProductTitle: {
    color: "white",
  },
});

export default connect(mapStateToStore)(EditProductScreen);
