import React, { useEffect } from "react";
import { StyleSheet, View, Text, Image, Linking } from "react-native";

// IMPORT GLOBAL COMPONENTS
import CloseButton from "../../components/buttons/CloseButton";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";

// IMPORT LOCAL COMPONENTS

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as shopifyActions from "../../redux/actions/shopify";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    shopId: state.shopify.shopId,
  };
};

///////// START FUNCTION CLASS ///////////////////////
const ShopifyBox = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(shopifyActions.fetchShopifySettings());
  });

  ////////////// RETURN VIEW /////////////////////
  return (
    <Card style={styles.card}>
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 5 }]}>
          <View style={Styles.row}>
            <Image
              source={require("../../../assets/shopify-icon.png")}
              style={styles.icon}
              accessible={false}
            />
            <Text style={Styles.H2}>Shopify connection lost!</Text>
          </View>
        </View>
        <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
          <CloseButton onClose={() => console.log("Close")} />
        </View>
      </View>
      <Divider />
      <View style={[Styles.row, { paddingBottom: 20 }]}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text style={Styles.H5}>
            Connection to Shopify store is not working anymore.
          </Text>
          <Text style={Styles.H5}>
            Usually it means that the Airstore App got removed from the Shopify
            store.
          </Text>
        </View>
      </View>
      <Divider />
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text style={Styles.H5}>
            Want to restablish connection with the store?
          </Text>
          <View style={styles.connectionBox}>
            <Text>{props.shopId}</Text>
          </View>

          <FlatButton
            onPress={() =>
              Linking.openURL(
                `https://api.grabstore.app/shopify/new-connection?shop=${props.shopId}.myshopify.com`
              )
            }
            title="Reconnect Store"
          />
        </View>
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 20,
    width: "100%",
  },

  icon: {
    width: 40,
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
    alignItems: "center",
    position: "relative",
    marginEnd: 20,
  },

  connectionBox: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderRadius: 5,
    backgroundColor: Colors.activeItem,
    marginVertical: 10,
  },
});

export default connect(mapStateToStore)(ShopifyBox);
