import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  ActivityIndicator,
} from "react-native";

// IMPORT GLOBAL COMPONENTS
import StockInput from "../../components/form/StockInput";
import PriceInput from "../../components/form/PriceInput";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import CloseButton from "../../components/buttons/CloseButton";
import Divider from "../../components/dividers/Divider";
import BarcodeBox from "../../components/containers/BarcodeBox";

// IMPORT GLOBAL STYLES
import Colors from "../../styles/Colors";
import Styles from "../../styles/Styles";

// IMPORT REDUX
import { connect, useDispatch } from "react-redux";
import * as singleProductActions from "../../redux/actions/singleProduct";

// IMPORT NAVIGATION
import * as RootNavigation from "../../routes/RootNavigation";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    currentProduct: state.singleProduct.currentProduct,
    isUpdatingProduct: state.singleProduct.isUpdatingProduct,
  };
};

//// IMPORT FORM LIBS
import { Formik } from "formik";
import * as yup from "yup";

/// SET FORM VALIDATION SCHEMA
const productSchema = yup.object({
  stock: yup.number().typeError("Must be a number"),
  price: yup.number().typeError("Must be a number"),
});

///////// START FUNCTION CLASS ///////////////////////
const ProductModal = (props) => {
  const dispatch = useDispatch();

  ////////////// RETURN VIEW /////////////////////

  return (
    <View style={styles.centeredModal}>
      <KeyboardAvoidingView style={styles.avoidingView} behavior="padding">
        <Card style={styles.modalBox}>
          <View style={Styles.row}>
            <View style={[Styles.col, { flex: 5 }]}>
              <Text style={Styles.H2}>Update Product</Text>
            </View>
            <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
              <CloseButton onClose={() => props.onClose()} />
            </View>
          </View>
          <Divider />

          {props.currentProduct === null && props.isUpdatingProduct ? (
            <ActivityIndicator color={Colors.primary} size="large" />
          ) : props.currentProduct === null ? null : (
            <View style={[Styles.col, { width: "100%" }]}>
              <View style={Styles.row}>
                <View style={[Styles.col, { flex: 1 }]}>
                  <Image
                    source={{
                      uri: props.currentProduct.catalogInformation.media
                        .mainImageUrl,
                    }}
                    style={styles.pImage}
                  />
                </View>
                <View style={[Styles.col, { flex: 2 }]}>
                  <Text style={Styles.H3}>
                    {props.currentProduct.catalogInformation.title}
                  </Text>
                </View>
              </View>
              <View style={Styles.row}>
                <BarcodeBox barcode={props.currentProduct.internalId} />
              </View>
              <View style={Styles.row}>
                <Formik
                  initialValues={{
                    stock: props.currentProduct.stock,
                    price: props.currentProduct.price
                      ? props.currentProduct.price
                      : null,
                  }}
                  validationSchema={productSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    await dispatch(
                      singleProductActions.updateInventory(
                        props.currentProduct.internalId,
                        values.stock,
                        values.price
                      )
                    );
                    setSubmitting(false);
                  }}
                >
                  {(formikProps) => (
                    <View style={[Styles.col, { flex: 1, padding: 0 }]}>
                      <View style={[Styles.row, { paddingTop: 20 }]}>
                        <View style={[Styles.col, styles.inputCol]}>
                          <StockInput
                            placeholder={props.currentProduct.stock.toString()}
                            onChangeText={formikProps.handleChange("stock")}
                            value={formikProps.values.stock.toString()}
                            onBlur={formikProps.handleBlur("stock")}
                            errorMessage={
                              formikProps.touched.stock &&
                              formikProps.errors.stock
                            }
                          />
                        </View>
                        <View style={[Styles.col, styles.inputCol]}>
                          <PriceInput
                            placeholder={
                              props.currentProduct.price
                                ? props.currentProduct.price.toString()
                                : "Add Price"
                            }
                            onChangeText={formikProps.handleChange("price")}
                            value={formikProps.values.price}
                            onBlur={formikProps.handleBlur("price")}
                            errorMessage={
                              formikProps.touched.price &&
                              formikProps.errors.price
                            }
                          />
                        </View>
                      </View>
                      <View style={Styles.row}>
                        <FlatButton
                          onPress={formikProps.handleSubmit}
                          title="Update"
                          buttonStyle={{ width: "100%" }}
                          isLoading={formikProps.isSubmitting}
                        />
                      </View>
                    </View>
                  )}
                </Formik>
              </View>
              <Divider />
              {/* <FlatButton
                onPress={() => {
                  props.onClose();
                  RootNavigation.navigate("AppNavigator", {
                    screen: "ProductsManagementNavigator",
                    params: {
                      screen: "ProductsNavigator",
                      params: { screen: "EditProduct" },
                    },
                  });
                }}
                title="Advanced Editing"
                buttonStyle={{ backgroundColor: "#ffffff" }}
                titleStyle={{ color: Colors.primary }}
              /> */}
            </View>
          )}
        </Card>
      </KeyboardAvoidingView>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredModal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingHorizontal: "5%",
  },

  avoidingView: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  modalBox: {
    width: "100%",
    maxWidth: 600,
    alignItems: "flex-start",
    marginTop: 15,
  },

  pImage: {
    width: "100%",
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
  },

  inputCol: {
    flex: 1,
    padding: 0,
  },
});

export default connect(mapStateToStore)(ProductModal);
