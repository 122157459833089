// IMPORT UPDATE HELPER
import update from "immutability-helper";

import { LOGOUT } from "../actions/auth";
import { RESET_CHAIN } from "../actions/chains";

import { FETCH_ORDERS, UPDATE_ORDERS, ORDERS_ERROR } from "../actions/orders";

const initialState = {
  isFetchingOrders: false,
  chainOrders: [],
  isError: false,
  errorMessage: "",
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS:
      return {
        ...state,
        isFetchingOrders: true,
        isError: false,
        errorMessage: "",
      };
    case UPDATE_ORDERS:
      return {
        ...state,
        isFetchingOrders: false,
        chainOrders: action.chainOrders,
      };
    case ORDERS_ERROR:
      return {
        ...state,
        isFetchingOrders: false,
        isError: true,
        chainOrders: [],
        errorMessage: action.errorMessage,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case RESET_CHAIN:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default ordersReducer;
