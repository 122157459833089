import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text } from "react-native";

// IMPORT GLOBAL COMPONENTS
import CloseButton from "../../components/buttons/CloseButton";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as shopifyActions from "../../redux/actions/shopify";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    chainId: state.chains.currentChain.id,
    chainStores: state.stores.chainStores,
    currentLocation: state.shopify.currentLocation,
  };
};

//// IMPORT FORM LIBS
import RNPickerSelect from "react-native-picker-select";
import { Formik } from "formik";
import * as yup from "yup";

// FORM VALIDATION SCHEMA
const selectStoreSchema = yup.object({
  storeId: yup
    .string()
    .test("len", "Please choose a store", (val) => val.length > 2)
    .required("Please choose a store"),
});

///////// START FUNCTION CLASS ///////////////////////
const ChooseStoreModal = (props) => {
  const dispatch = useDispatch();

  const [selectStores, setSelectStores] = useState([]);

  useEffect(() => {
    let stores = props.chainStores.map((store) => {
      return {
        label: store.name,
        value: store.id,
        key: store.id,
      };
    });
    setSelectStores([
      { label: "Disconnect", value: "disconnect", key: 0 },
      ...stores,
    ]);
  }, []);

  ////////////// RETURN VIEW  /////////////////////
  return (
    <View style={styles.centeredModal}>
      <Card style={styles.modalBox}>
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 5 }]}>
            <Text style={Styles.H2}>Choose Store</Text>
          </View>
          <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
            <CloseButton onClose={() => props.onClose()} />
          </View>
        </View>
        <Divider />
        <Text style={[Styles.H4, { marginBottom: 10 }]}>
          Connect Shopify location - {props.currentLocation.name}
        </Text>
        <Formik
          initialValues={{
            chainId: props.chainId,
            locationId: props.currentLocation.id,
            storeId: props.currentLocation.connectedStore,
          }}
          validationSchema={selectStoreSchema}
          onSubmit={async (values, { setSubmitting }) => {
            dispatch(
              shopifyActions.connectStoreLocation(
                values.chainId,
                values.storeId,
                values.locationId
              )
            );
            setSubmitting(false);
            props.onClose();
          }}
        >
          {(formikProps) => (
            <View style={styles.form}>
              <RNPickerSelect
                placeholder={{
                  key: "0",
                  label: "Select a store...",
                  value: 0,
                }}
                style={{
                  inputIOS: Styles.picker,
                  inputAndroid: Styles.picker,
                  inputWeb: Styles.picker,
                }}
                items={selectStores}
                onValueChange={(value) => {
                  formikProps.setFieldValue("storeId", value);
                }}
              />
              <Text style={styles.errorMessageText}>
                {formikProps.touched.storeId}
                {formikProps.errors.storeId}
              </Text>
              <View>
                <FlatButton onPress={formikProps.handleSubmit} title="Submit" />
              </View>
            </View>
          )}
        </Formik>
        <Divider />
        <View style={Styles.row}>
          <View style={Styles.col}>
            <Text style={Styles.H3}>Note</Text>
            <Text>
              The inventory of this Shopify location will be fully synchronized
              with the inventory of the selected store.
            </Text>
          </View>
        </View>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredModal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingHorizontal: "5%",
  },

  modalBox: {
    maxWidth: 600,
    alignItems: "flex-start",
    marginTop: 15,
  },

  form: {
    width: "100%",
    justifyContent: "flex-start",
  },

  errorMessage: {
    paddingVertical: "2%",
    justifyContent: "center",
    alignItems: "center",
  },

  errorMessageText: {
    color: "crimson",
    padding: 5,
    fontWeight: "bold",
  },
});

export default connect(mapStateToStore)(ChooseStoreModal);
