import React, { useState } from "react";
import { StyleSheet, View, Text, Image, Modal } from "react-native";

// IMPORT GLOBAL COMPONENTS
import CloseButton from "../../components/buttons/CloseButton";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";

// IMPORT LOCAL COMPONENTS
import AddStoreModal from "../../stores/components/AddStoreModal";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as shopifyActions from "../../redux/actions/shopify";
import * as storesActions from "../../redux/actions/stores";
import * as userActions from "../../redux/actions/user";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    userRetailChains: state.chains.userRetailChains,
    shopifyLoading: state.shopify.isLoading,
  };
};

///////// START FUNCTION CLASS ///////////////////////
const ShopifyBox = (props) => {
  const dispatch = useDispatch();

  const importHandler = async () => {
    console.log("Importing");
    await dispatch(shopifyActions.importLocations());
    dispatch(storesActions.fetchChainStores());
    dispatch(userActions.fetchProfile());
  };

  // MODAL STATE
  const [modalVisible, setModalVisible] = useState(false);

  // CLOSE MODAL FUNCTION
  const closeModal = () => {
    setModalVisible(false);
  };

  ////////////// RETURN VIEW /////////////////////
  return (
    <Card style={styles.card}>
      {/* ///// ADD STORE MODAL */}
      <Modal animationType="fade" transparent={true} visible={modalVisible}>
        <AddStoreModal onClose={closeModal} />
      </Modal>
      {/* ///// END STORE MODAL */}
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 5 }]}>
          <View style={Styles.row}>
            <Image
              source={require("../../../assets/shopify-icon.png")}
              style={styles.icon}
              accessible={false}
            />
            <Text style={Styles.H2}>Add stores to sync products</Text>
          </View>
        </View>
        <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
          <CloseButton onClose={() => console.log("Close")} />
        </View>
      </View>
      <Divider />
      <View style={[Styles.row, { paddingBottom: 20 }]}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text style={Styles.H5}>
            One last step before you start synchronizing this chain with a
            Shopify store.
          </Text>
          <Text style={Styles.H5}>
            You need to set up at least 1 store under this Retail Chain.
          </Text>
        </View>
      </View>
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text>Generate stores that match your Shopify Locations.</Text>
          <FlatButton
            title="Import Locations From Shopify"
            onPress={importHandler}
            isLoading={props.shopifyLoading}
          />
        </View>
      </View>
      <Divider style={{ width: "80%" }} />
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text>Or create your own set of stores.</Text>
          <FlatButton
            title="Add Your First Store"
            onPress={() => setModalVisible(true)}
          />
        </View>
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 20,
    width: "100%",
  },

  icon: {
    width: 40,
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
    alignItems: "center",
    position: "relative",
    marginEnd: 20,
  },
});

export default connect(mapStateToStore)(ShopifyBox);
