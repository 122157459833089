import axios from "axios";

export const SUBMITED_TICKET = "SUBMITED_TICKET";
export const SUPPORT_ERROR = "SUPPORT_ERROR";
export const RESET_TICKET = "RESET_TICKET";

// import constants
import SERVER_CONSTANTS from "../../constants/airstoreServer";

////////// SUBMIT NEW TICKET ////////////////////////////////////////////
export const submitNewTicket = (message, screen) => {
  return async (dispatch, getState) => {
    // Fetch state information
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;

    // Construct the ticket
    const ticket = {
      chainId: chainId,
      message: message,
      screen: screen,
    };
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/support/new-ticket",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            ticket: ticket,
          }),
        }
      );
      dispatch({
        type: SUBMITED_TICKET,
        ticketId: response.data.ticketId,
      });
    } catch (error) {
      dispatch({
        type: SUPPORT_ERROR,
        errorMessage: error.response.data.message,
      });
    }
  };
};

////////// SUBMIT NEW TICKET ////////////////////////////////////////////
export const resetTicket = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_TICKET,
    });
  };
};
