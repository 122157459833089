import React, { useState, useEffect } from "react";
import {
  ScrollView,
  StyleSheet,
  KeyboardAvoidingView,
  View,
  Text,
  Image,
  Platform,
  FlatList,
  Modal,
  ActivityIndicator,
} from "react-native";

// Importing Redux state components
import { connect, useDispatch } from "react-redux";
import * as chainsActions from "../../redux/actions/chains";
import * as authActions from "../../redux/actions/auth";

// IMPORT GLOBAL COMPONENTS
import FlatButton from "../../components/buttons/FlatButton";
import AttentionBox from "../../components/containers/AttentionBox";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";
import { LinearGradient } from "expo-linear-gradient";

// IMPORT LOCAL COMPONENTS
import ChainRow from "../components/ChainRow";
import AddChainModal from "../components/AddChainModal";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Connect login screen with the redux state
const mapStateToStore = (state) => {
  return {
    userRetailChains: state.chains.userRetailChains,
    isError: state.chains.isError,
    errorMessage: state.chains.errorMessage,
    isLoading: state.chains.isFetchigChains,
  };
};

// Rendering each FlatList item
const renderRetailChains = (itemData) => {
  return <ChainRow retailChain={itemData.item} />;
};

////////// START FUNCTION CLASS /////////
const SelectChainScreen = (props) => {
  // Hide header
  useEffect(() => {
    props.navigation.setOptions({ headerShown: false });
  }, []);

  // Fetch user chains
  useEffect(() => {
    dispatch(chainsActions.fetchUserChains());
  }, []);

  // Initiate dispatch
  const dispatch = useDispatch();

  // MODAL STATE
  const [modalVisible, setModalVisible] = useState(false);

  // CLOSE MODAL FUNCTION
  const closeModal = () => {
    setModalVisible(false);
  };

  //////////// RETURN VIEW ////////
  return (
    <LinearGradient
      // Background Linear Gradient
      colors={["#6a0ed1", "#9c06b9"]}
      start={{ x: 0.2, y: 0.2 }}
      end={{ x: 0.7, y: 0.6 }}
      locations={[0.1, 0.9]}
      style={styles.background}
    >
      {/* ///// ADD CHAIN MODAL */}
      <Modal animationType="fade" transparent={true} visible={modalVisible}>
        <AddChainModal onClose={closeModal} />
      </Modal>
      {/* ///// END CHAIN MODAL */}
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <ScrollView>
          <View style={Styles.row}>
            <View style={[Styles.col, styles.imageContainer]}>
              <Image
                source={require("../../../assets/company-logo.png")}
                style={styles.image}
              />
            </View>
          </View>
          <View style={Styles.row}>
            <View style={[Styles.col, styles.content]}>
              <Card style={styles.card}>
                <View style={Styles.row}>
                  <View style={[Styles.col, { flex: 1 }]}>
                    <Text style={Styles.H1}>Select Retail Chain</Text>
                  </View>
                  <View
                    style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}
                  >
                    <FlatButton
                      title="Add Chain"
                      onPress={() => setModalVisible(true)}
                      icon="add-outline"
                    />
                  </View>
                </View>
                <Divider />
                {props.isLoading ? (
                  <ActivityIndicator size="large" color={Colors.primary} />
                ) : !props.isError ? (
                  <FlatList
                    data={props.userRetailChains}
                    keyExtractor={(item) => item.id}
                    renderItem={renderRetailChains}
                    numColumns={1}
                    contentContainerStyle={[Styles.listContainer]}
                  />
                ) : (
                  <AttentionBox>
                    <Text>{props.errorMessage}</Text>
                  </AttentionBox>
                )}

                <View style={Styles.row}>
                  <View style={[Styles.col, { flex: 1 }]}>
                    <FlatButton
                      title="Logout"
                      onPress={() => dispatch(authActions.logout())}
                      buttonStyle={{ backgroundColor: "transparent" }}
                      titleStyle={{ color: Colors.primary }}
                    />
                  </View>
                </View>
              </Card>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },

  inner: {
    flex: 1,
    justifyContent: "center",
  },

  imageContainer: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
  },

  image: {
    width: 270,
    height: 65,
    resizeMode: "contain",
  },

  content: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
    paddingHorizontal: 10,
  },

  card: {
    maxWidth: 600,
  },
});

export default connect(mapStateToStore)(SelectChainScreen);
