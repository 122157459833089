import axios from "axios";

// Import constants
import SERVER_CONSTANTS from "../../constants/airstoreServer";

export const TOGGLE_SCANNED = "TOGGLE_SCANNED";
export const SET_PRODUCT = "SET_PRODUCT";
export const UPDATE_SINGLE_PRODUCT = "UPDATE_SINGLE_PRODUCT";
export const SINGLE_LOADING = "SINGLE_LOADING";
export const ADDED_PRODUCT = "ADDED_PRODUCT";

//////////// FETCH SCANNED PRODUCT //////////////////////////////////////////
// Server will initialize and download catalog information if it doesn't alreadt exist
export const fetchScanned = (barcode, barcodeType) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const storeProducts = getState().products.currentStoreProducts;
    const storeId = getState().stores.currentStore.id;
    const chainId = getState().chains.currentChain.id;
    // STEP 1: Make sure this product is not available in the local memory
    const currentProduct = storeProducts.find(
      (product) => product.internalId === barcode
    );
    if (currentProduct) {
      dispatch({
        type: SET_PRODUCT,
        currentProduct: currentProduct,
      });
      return;
    }
    try {
      // STEP 2: If couldn't find in local memory, send barcode to the server
      dispatch({ type: SINGLE_LOADING });

      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/barcode-scan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            barcode: barcode,
            barcodeType: barcodeType,
            storeId: storeId,
            chainId: chainId,
          }),
        }
      );

      // STEP 3: If fetched existing product => set this product to edit
      if (response.data.status === 200) {
        dispatch({
          type: SET_PRODUCT,
          currentProduct: response.data.product,
        });
      }
      // STEP 3: If created new product => set this product for edit and add it to the store products
      dispatch({
        type: ADDED_PRODUCT,
        currentProduct: response.data.product,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const setCurrentProduct = (product) => {
  return (dispatch) => {
    dispatch({
      type: SET_PRODUCT,
      currentProduct: product,
    });
  };
};

//////////// UPDATE INVENTORY TRACKER //////////////////////////////////////////
export const updateInventory = (internalId, stock, price) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const storeId = getState().stores.currentStore.id;
    const chainId = getState().chains.currentChain.id;
    const currentProduct = getState().singleProduct.currentProduct;
    const currentStoreProducts = getState().products.currentStoreProducts;
    try {
      // STEP 1: Send product details to update
      const response = await axios(SERVER_CONSTANTS.SERVER_URI + "/inventory", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: JSON.stringify({
          storeId: storeId,
          chainId: chainId,
          product: {
            internalId: internalId,
            stock: stock,
            price: price,
          },
        }),
      });
      // STEP 2: Find the product's index in the list of products in redux
      const productIndex = currentStoreProducts.findIndex(
        (product) => product.internalId === internalId
      );

      // STEP 3: Update the product and and list of products
      dispatch({
        type: UPDATE_SINGLE_PRODUCT,
        productIndex: productIndex,
        updatedStock: stock,
        updatedPrice: price,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

//////////// DELETE INVENTORY TRACKER //////////////////////////////////////////
export const deleteInventory = (internalId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const storeId = getState().stores.currentStore.id;
    const chainId = getState().chains.currentChain.id;

    try {
      dispatch({ type: SINGLE_LOADING });
      // STEP 1: Send barcode to the server
      const response = await axios(SERVER_CONSTANTS.SERVER_URI + "/products", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: JSON.stringify({
          storeId: storeId,
          chainId: chainId,
          internalId: internalId,
        }),
      });
      // STEP 2: Reset the current product in redux
      dispatch({
        type: SET_PRODUCT,
        currentProduct: null,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
