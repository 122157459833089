import React from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";

// Import screens
import SettingsScreen from "../screens/ChainSettingsScreen";

const Stack = createNativeStackNavigator();

let ProductsNavigator;
export default ProductsNavigator = (props) => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="SettingsScreen"
        component={SettingsScreen}
        options={{ title: "Retail Chain Settings | Airstore Cloud" }}
      />
    </Stack.Navigator>
  );
};
