import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Modal,
} from "react-native";

// Importing Redux state components
import { useDispatch } from "react-redux";
import * as chainsActions from "../../redux/actions/chains";

// IMPORT GLOBAL COMPONENTS
import ListRow from "../../components/containers/ListRow";
import Ionicons from "@expo/vector-icons/Ionicons";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// IMPORT SECTION COMPONENTS

const renderStores = (itemData) => {
  return <StoreRow store={itemData.item} />;
};

/////////// START FUNCTION CLASS /////////
const ChainRow = (props) => {
  // Initiate dispatch
  const dispatch = useDispatch();

  //////////// RETURN VIEW ////////
  return (
    <ListRow>
      <TouchableOpacity
        onPress={() => {
          dispatch(chainsActions.selectChain(props.retailChain));
        }}
        style={styles.chainRow}
      >
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 3 }]}>
            <Text style={Styles.H1}>{props.retailChain.name}</Text>
            <Text style={Styles.H4}>
              {props.retailChain.role === "owner" ? "Owner" : "Staff"}
            </Text>
          </View>
          <View style={[Styles.col, { flex: 3 }]}>
            <Text style={Styles.smallNote}>Connections</Text>
            <View style={[Styles.row, { marginTop: 5 }]}>
              {props.retailChain.integrations ? (
                props.retailChain.integrations.shopify ? (
                  <View style={styles.connections}>
                    <Image
                      source={require("../../../assets/shopify-icon.png")}
                      style={styles.connectionIcon}
                      accessible={false}
                    />
                  </View>
                ) : (
                  <Text>No connections</Text>
                )
              ) : (
                <Text>No connections</Text>
              )}
            </View>
          </View>
          <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
            <Ionicons name={"chevron-forward"} size={21} />
          </View>
        </View>
      </TouchableOpacity>
    </ListRow>
  );
};

const styles = StyleSheet.create({
  chainRow: {
    flex: 1,
    width: "100%",
    marginVertical: 10,
  },

  retailChainInfo: {
    marginVertical: 10,
  },

  storeIcon: {
    maxWidth: 40,
    width: "100%",
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: "center",
  },

  name: {
    color: "#000000",
    fontSize: 15,
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "500",
  },

  actionButtons: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },

  connectionIcon: {
    width: 30,
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
    alignItems: "center",
    position: "relative",
  },
});

export default ChainRow;
