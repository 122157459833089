import React from "react";
import { Platform } from "react-native";

// Import the needed navigation functions
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

// Import icon packages for the tabs
import Ionicons from "@expo/vector-icons/Ionicons";
import FontAwesome from "@expo/vector-icons/FontAwesome5";

// IMPORT NAVIGATORS
import ProductsNavigator from "./ProductsNavigator";

// IMPORT SCREENS
import BarcodeScannerScreen from "../screens/BarcodeScannerScreen";
import AddProductScreen from "../screens/AddProductScreen";

// IMPORT STYLES
import Colors from "../../styles/Colors";

const Tab = createBottomTabNavigator();

let ProductsManagemetNavigator;
export default ProductsManagemetNavigator = () => {
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarLabelPosition: "below-icon",
        tabBarShowLabel: false,
        tabBarActiveTintColor: Colors.primary,
        headerShown: false,
      }}
    >
      <Tab.Screen
        name="ProductsNavigator"
        component={ProductsNavigator}
        options={{
          title: "Manage Products | Airstore Cloud",
          tabBarIcon: (focused) => {
            return (
              <FontAwesome
                name={"boxes"}
                size={25}
                color={focused.focused ? Colors.primary : Colors.accent}
              />
            );
          },
        }}
      />
      <Tab.Screen
        name="Scanner"
        component={
          Platform.OS === "web" ? AddProductScreen : BarcodeScannerScreen
        }
        options={{
          title: "Add New Product | Airstore Cloud",
          tabBarIcon: (focused) => {
            return (
              <Ionicons
                name={"barcode-outline"}
                size={35}
                color={focused.focused ? Colors.primary : Colors.accent}
              />
            );
          },
        }}
      />
    </Tab.Navigator>
  );
};
