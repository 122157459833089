import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Image, Dimensions } from "react-native";

// IMPORT GLOBAL COMPONENTS
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";
import { LinearGradient } from "expo-linear-gradient";
import Screen from "../../components/containers/Screen";
import FlatButton from "../../components/buttons/FlatButton";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

//// IMPORT LOCAL COMPONENTS

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as subscriptionActions from "../../redux/actions/subscription";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {};
};

/////////////////// START FUNCTION CLASS //////////////////
const ThankYouScreen = (props) => {
  // Initiate dispatch
  const dispatch = useDispatch();

  // FETCH PARAMS
  useEffect(() => {
    if (props.route.params) {
      if (props.route.params.type === "shopify") {
        if (props.route.params.charge_id) {
          dispatch(
            subscriptionActions.confirmShopifySubscription(
              props.route.params.charge_id
            )
          );
        }
      }
    }
  }, []);

  //////////// RETURN VIEW ////////
  return (
    <LinearGradient
      // Background Linear Gradient
      colors={["#6a0ed1", "#9c06b9"]}
      start={{ x: 0.2, y: 0.2 }}
      end={{ x: 0.7, y: 0.6 }}
      locations={[0.1, 0.9]}
      style={styles.background}
    >
      <Screen>
        <View style={Styles.row}>
          <View style={[Styles.col, styles.imageContainer]}>
            <Image
              source={require("../../../assets/company-logo.png")}
              style={styles.image}
            />
          </View>
        </View>
        <Card style={styles.card}>
          <View style={Styles.row}>
            <View style={[Styles.col]}>
              <Text style={Styles.H1}>Thank you for growing with us!</Text>
            </View>
          </View>
          <Divider />
          <FlatButton
            title="Back To Work"
            onPress={() => window.open("https://cloud.airstore.app/", "_self")}
            icon="arrow-redo-outline"
          />
        </Card>
      </Screen>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },

  imageContainer: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
  },

  image: {
    width: 270,
    height: 65,
    resizeMode: "contain",
  },

  card: {
    maxWidth: 600,
    marginTop: 50,
  },
});

export default connect(mapStateToStore)(ThankYouScreen);
