import React from "react";

// import safe area provider
import { SafeAreaProvider } from "react-native-safe-area-context";

// Setup Redux state control
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";

// Importing reducers
import storesReducer from "./app/redux/reducers/stores";
import authReducer from "./app/redux/reducers/auth";
import productsReducer from "./app/redux/reducers/products";
import singleProductReducer from "./app/redux/reducers/singleProduct";
import connectionsReducer from "./app/redux/reducers/connections";
import shopifyReducer from "./app/redux/reducers/shopify";
import viewSettingsReducer from "./app/redux/reducers/viewSettings";
import userReducer from "./app/redux/reducers/user";
import ordersReducer from "./app/redux/reducers/orders";
import chainsReducer from "./app/redux/reducers/chains";
import subscriptionReducer from "./app/redux/reducers/subscription";
import notificationsReducer from "./app/redux/reducers/notifications";
import supportReducer from "./app/redux/reducers/support";

// Combine reducer
const rootReducer = combineReducers({
  auth: authReducer,
  stores: storesReducer,
  products: productsReducer,
  singleProduct: singleProductReducer,
  connections: connectionsReducer,
  shopify: shopifyReducer,
  viewSettings: viewSettingsReducer,
  user: userReducer,
  orders: ordersReducer,
  chains: chainsReducer,
  subscription: subscriptionReducer,
  notifications: notificationsReducer,
  support: supportReducer,
});

// Import root navigator
import MainNavigator from "./app/routes/MainNavigator";

// ONLY ON DEV
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(ReduxThunk))
// );

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

export default function App() {
  return (
    <Provider store={store}>
      <SafeAreaProvider>
        <MainNavigator />
      </SafeAreaProvider>
    </Provider>
  );
}
