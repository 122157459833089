// IMPORT UPDATE HELPER
import update from "immutability-helper";

import { LOGOUT } from "../actions/auth";

import { RESET_CHAIN } from "../actions/chains";

import {
  LOAD_STORES,
  UPDATE_STORES,
  SELECT_STORE,
  STORES_ERROR,
  ADD_STORE,
  REMOVE_STORE,
} from "../actions/stores";

const initialState = {
  chainStores: [],
  currentStore: null,
  isFetchigStores: false,
  isError: false,
  errorMessage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_STORES:
      return {
        ...state,
        isError: false,
        isFetchigStores: true,
      };
    case ADD_STORE:
      return update(state, {
        chainStores: {
          $push: [action.newStore],
        },
      });
    case REMOVE_STORE:
      return update(state, {
        chainStores: {
          $splice: [[action.storeIndex, 1]],
        },
      });
    case UPDATE_STORES:
      return {
        ...state,
        isFetchigStores: false,
        chainStores: action.chainStores,
        currentStore: action.currentStore,
      };
    case SELECT_STORE:
      return {
        ...state,
        isError: false,
        currentStore: action.currentStore,
      };
    case STORES_ERROR:
      return {
        ...state,
        isError: true,
        isFetchigStores: false,
        errorMessage: action.errorMessage,
      };
    case RESET_CHAIN:
      return {
        ...initialState,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
