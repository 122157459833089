// IMPORT UPDATE HELPER
import update from "immutability-helper";

import { LOGOUT } from "../actions/auth";

import { SET_PRODUCTS_VIEW } from "../actions/viewSettings";

const initialState = {
  productsView: "list",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS_VIEW:
      return {
        ...state,
        productsView: action.productsView,
      };
    default:
      return state;
  }
};
