import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const LOAD_STORES = "LOAD_STORES";
export const UPDATE_STORES = "UPDATE_STORES";
export const SELECT_STORE = "SELECT_STORE";
export const STORES_ERROR = "STORES_ERROR";
export const ADD_STORE = "ADD_STORE";
export const REMOVE_STORE = "REMOVE_STORE";

// import constants
import SERVER_CONSTANTS from "../../constants/airstoreServer";

// IMPORT ACTIONS
import * as subscriptionActions from "../actions/subscription";

////////// ADD NEW STORE ////////////////////////////////////////////
export const addStore = (storeName, retailChainId) => {
  return async (dispatch, getState) => {
    // Fetch token from the redux state
    const token = getState().auth.token;
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/stores/add",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            storeName: storeName,
            retailChainId: retailChainId,
          }),
        }
      );
      if (response.status === 202) {
        const newStore = response.data.newStore;
        dispatch({ type: ADD_STORE, newStore: newStore });
        dispatch(subscriptionActions.fetchSubscription());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

////////// FETCH CHAIN STORES ////////////////////////////////////////////
export const fetchChainStores = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOAD_STORES,
    });
    // STEP 1: Fetch token from the redux state
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;
    let currentStore = getState().stores.currentStore;
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/stores/my-stores",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { chainId: chainId },
        }
      );
      // STEP 2: Make sure got any result
      if (response.status === 204) {
        dispatch({
          type: STORES_ERROR,
          errorMessage:
            "No stores found for this Retail Chain, please add one.",
        });
        return;
      }

      // STEP 3: Check if there is saved store before choosing default
      if (!currentStore) {
        currentStore = response.data.chainStores[0];
        await AsyncStorage.setItem(
          "currentStore",
          JSON.stringify(response.data.chainStores[0])
        );
      }

      dispatch({
        type: UPDATE_STORES,
        chainStores: response.data.chainStores,
        currentStore: currentStore,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

////////// DELETE STORE ////////////////////////////////////////////
export const deleteStore = (storeId) => {
  return async (dispatch, getState) => {
    console.log(`Deleting ${storeId}`);
    // Fetch token from the redux state
    const token = getState().auth.token;
    const chainStores = getState().stores.chainStores;
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/stores/delete",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            storeId: storeId,
          }),
        }
      );
      if (response.status === 204) {
        const storeIndex = chainStores.findIndex(
          (store) => store.id === storeId
        );
        dispatch({
          type: REMOVE_STORE,
          storeIndex: storeIndex,
        });
        dispatch(subscriptionActions.fetchSubscription());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

////////// SELECT STORE ////////////////////////////////////////////
export const selectStore = (currentStore, storeId) => {
  return async (dispatch, getState) => {
    const chainStores = getState().stores.chainStores;
    if (!currentStore) {
      currentStore = chainStores.find((store) => store.id === storeId);
    }
    dispatch({
      type: SELECT_STORE,
      currentStore: currentStore,
    });
    try {
      await AsyncStorage.setItem("currentStore", JSON.stringify(currentStore));
    } catch (error) {
      console.log(error);
    }
  };
};
