import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, FlatList, Modal } from "react-native";

// IMPORT GLOBAL COMPONENTS
import Loader from "../../components/loaders/facebookLoader";
import Card from "../../components/containers/Card";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// IMPORT SECTION COMPONENTS
import StoreRow from "./StoreRow";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as storesActions from "../../redux/actions/stores";

// Rendering store rows
const renderStores = (itemData) => {
  return <StoreRow store={itemData.item} />;
};

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    chainStores: state.stores.chainStores,
    currentChain: state.chains.currentChain,
    isFetchigStores: state.stores.isFetchigStores,
    isError: state.stores.isError,
    errorMessage: state.stores.errorMessage,
  };
};

////////// START FUNCTION CLASS /////////
const StoresList = (props) => {
  // Initiate dispatch
  const dispatch = useDispatch();

  // LOAD STORES EFFECT
  useEffect(() => {
    dispatch(storesActions.fetchChainStores());
  }, []);

  ////////////////// RETURN VIEW ///////////////////

  return (
    <Card style={styles.storesSection}>
      {props.isFetchigStores ? (
        <Loader />
      ) : props.isError ? (
        <Text style={styles.errorMessage}>{props.errorMessage}</Text>
      ) : (
        <FlatList
          data={props.chainStores}
          keyExtractor={(item) => item.id}
          renderItem={renderStores}
          numColumns={1}
          contentContainerStyle={[Styles.listContainer]}
        />
      )}
    </Card>
  );
};

const styles = StyleSheet.create({
  storesSection: {
    width: "100%",
    marginTop: 15,
  },

  errorMessage: {
    paddingVertical: "2%",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default connect(mapStateToStore)(StoresList);
