import React, { useEffect } from "react";
import { StyleSheet, View, Text, Image, ActivityIndicator } from "react-native";

// IMPORT GLOBAL COMPONENTS
import CloseButton from "../../components/buttons/CloseButton";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";

// IMPORT LOCAL COMPONENTS
import StoreSelector from "../../stores/components/StoreSelector";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as shopifyActions from "../../redux/actions/shopify";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    isLoading: state.shopify.isLoading,
    defaultStoreOnlineOrders: state.shopify.defaultStoreOnlineOrders,
  };
};

///////// START FUNCTION CLASS ///////////////////////
const ShopifyOrdersBox = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(shopifyActions.fetchShopifySettings());
  }, []);

  const submitHandler = async (storeId) => {
    dispatch(shopifyActions.setDefaultStoreOrders(storeId));
  };

  ////////////// RETURN VIEW /////////////////////
  return (
    <Card style={styles.card}>
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 5 }]}>
          <View style={Styles.row}>
            <Image
              source={require("../../../assets/shopify-icon.png")}
              style={styles.icon}
              accessible={false}
            />
            <Text style={Styles.H2}>Sync orders from Shopify?</Text>
          </View>
        </View>
        <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
          <CloseButton onClose={() => console.log("Close")} />
        </View>
      </View>
      <Divider />
      <View style={[Styles.row, { paddingBottom: 20 }]}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text style={Styles.H5}>
            Synchronizing orders between Shopify and Airstore ensures your
            inventory is up to date.
          </Text>
        </View>
      </View>
      {props.isLoading ? (
        <ActivityIndicator size="large" color={Colors.primary} />
      ) : !props.defaultStoreOnlineOrders ? (
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 1 }]}>
            <Text>
              Choose which store should be the chain's default for orders
              fulfillment.
            </Text>
            <StoreSelector onPress={submitHandler} submitText="Select Store" />
          </View>
        </View>
      ) : (
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 1 }]}>
            <FlatButton
              title="Start Orders Sync"
              onPress={() => dispatch(shopifyActions.startOrdersSync())}
            />
          </View>
        </View>
      )}
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 20,
    width: "100%",
  },

  icon: {
    width: 40,
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
    alignItems: "center",
    position: "relative",
    marginEnd: 20,
  },
});

export default connect(mapStateToStore)(ShopifyOrdersBox);
