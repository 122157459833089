import React from "react";
import { StyleSheet, View, Text, Linking } from "react-native";

// IMPORT GLOBAL COMPONENTS
import FlatButton from "../../components/buttons/FlatButton";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Importing Redux state components
import { useDispatch } from "react-redux";
import * as subscriptionActions from "../../redux/actions/subscription";

///////// START FUNCTION CLASS ///////////////////////
const PlanBox = (props) => {
  const dispatch = useDispatch();

  ////////////// RETURN VIEW  /////////////////////
  return (
    <View style={styles.box}>
      <Text style={Styles.H2}>
        {props.planId === "001"
          ? "Mom & Pop"
          : props.planId === "002"
          ? "Growth"
          : "Unlimited"}
      </Text>
      {props.planId === "003" ? (
        <FlatButton
          title="Request Demo"
          onPress={() => Linking.openURL(`https://airstore.app/request-demo/`)}
        />
      ) : (
        <FlatButton
          title={props.planId === "001" ? "Downgrade" : "Upgrade Now"}
          onPress={() => {
            dispatch(subscriptionActions.upgradePlan(props.planId));
            props.onClose();
          }}
          icon={
            props.planId === "001"
              ? "ios-trending-down-outline"
              : "ios-trending-up-outline"
          }
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  box: {
    width: "100%",
    height: 200,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: Colors.primary,
    marginHorizontal: 10,
  },
});

export default PlanBox;
