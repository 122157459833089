import React from "react";
import { StyleSheet, View } from "react-native";

const ErrorBox = (props) => {
  return <View style={[styles.box, props.style]}>{props.children}</View>;
};

const styles = StyleSheet.create({
  box: {
    marginVertical: 10,
    backgroundColor: "#ffc2ca",
    borderStartWidth: 3,
    borderStartColor: "#780000",
    paddingHorizontal: 10,
    paddingVertical: 15,
    width: "100%",
    borderRadius: 4,
  },
});

export default ErrorBox;
