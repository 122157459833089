import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

// IMPORT ACTIONS
import * as storesActions from "./stores";

// SET CASES
export const FETCH_CHAINS = "FETCH_CHAINS";
export const UPDATE_CHAINS = "UPDATE_CHAINS";
export const CHAINS_ERROR = "CHAINS_ERROR";
export const ADD_CHAIN = "ADD_CHAIN";
export const SELECT_RETAIL_CHAIN = "SELECT_RETAIL_CHAIN";
export const FETCH_STATS = "FETCH_STATS";
export const UPDATE_STATS = "UPDATE_STATS";
export const RESET_CHAIN = "RESET_CHAIN";

// import constants
import SERVER_CONSTANTS from "../../constants/airstoreServer";

////////// ADD NEW RETAIL CHAIN ////////////////////////////////////////////
export const addRetailChain = (chainName) => {
  return async (dispatch, getState) => {
    // Fetch token from the redux state
    const token = getState().auth.token;
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/chains/add",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            chainName: chainName,
          }),
        }
      );
      if (response.status === 202) {
        dispatch({
          type: ADD_CHAIN,
          addedChain: response.data.newChain,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

////////// FETCH USER CHAINS ////////////////////////////////////////////
export const fetchUserChains = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: FETCH_CHAINS,
    });
    // STEP 1: Fetch token from the redux state
    const token = getState().auth.token;
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/chains/my-chains",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      // STEP 2: Make sure got any result
      if (response.status === 204) {
        dispatch({
          type: CHAINS_ERROR,
          errorMessage: "No retail chains",
        });
        return;
      }

      // STEP 3: Dispatch retail chains
      dispatch({
        type: UPDATE_CHAINS,
        userRetailChains: response.data.userRetailChains,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHAINS_ERROR,
        errorMessage:
          "Oops, there is a problem. Please reload or contact support.",
      });
      return;
    }
  };
};

////////// DELETE RETAIL CHAIN ////////////////////////////////////////////
export const deleteRetailChain = (chainId) => {
  return async (dispatch, getState) => {
    console.log(`deleting ${chainId}`);
    // Fetch token from the redux state
    const token = getState().auth.token;
    try {
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/stores/delete/retail-chain",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            chainId: chainId,
          }),
        }
      );
      if (response.status === 204) {
        // Apparntly you can't call the detch function in this file without a dispatch calling function.
        // So this part can be refactored
        dispatch({
          type: FETCH_STORES,
        });
        const fetchResponse = await axios(
          SERVER_CONSTANTS.SERVER_URI + "/stores/my-stores",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        // STEP 2: Make sure got any result
        if (fetchResponse.status === 204) {
          dispatch({
            type: STORES_ERROR,
            errorMessage:
              "No stores or retail chains found, please a store as part of a retail chain",
          });
          return;
        }
        // STEP 3: Sort stores to retail chains
        const userStoresInChains = sortStoresToChains(
          fetchResponse.data.userStores,
          fetchResponse.data.userRetailChains
        );

        dispatch({
          type: UPDATE_STORES,
          userRetailChains: fetchResponse.data.userRetailChains,
          userStores: fetchResponse.data.userStores,
          currentStore: userStoresInChains[0].stores
            ? userStoresInChains[0].stores[0]
            : {},
          userStoresInChains: userStoresInChains,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

////////// SELECT RETAIL CHAIN ////////////////////////////////////////////
export const selectChain = (currentChain) => {
  return async (dispatch, getState) => {
    try {
      AsyncStorage.setItem("currentChain", JSON.stringify(currentChain));
      dispatch({
        type: SELECT_RETAIL_CHAIN,
        currentChain: currentChain,
      });
      dispatch(storesActions.fetchChainStores());
    } catch (error) {
      console.log(error);
    }
  };
};

////////// RESET RETAIL CHAIN ////////////////////////////////////////////
export const resetChain = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: RESET_CHAIN,
      });
      AsyncStorage.removeItem("currentChain");
      AsyncStorage.removeItem("currentStore");
    } catch (error) {
      console.log(error);
    }
  };
};

////////// ADDING STORE OR CHAIN SELECTOR ////////////////////////////////////////////
export const addingSwitcher = (switcher) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_STORE,
      addingSwitcher: switcher,
    });
  };
};

////////// FETCH THE CHAIN STATS ////////////////////////////////////////////
export const fetchChainStats = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;
    try {
      dispatch({ type: FETCH_STATS });
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/chains/stats",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: {
            chainId: chainId,
          },
        }
      );

      dispatch({
        type: UPDATE_STATS,
        chainStats: response.data.chainStats,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
