// IMPORT UPDATE HELPER
import update from "immutability-helper";

import { LOGOUT } from "../actions/auth";
import { RESET_CHAIN } from "../actions/chains";

import {
  SUB_LOADING,
  FETCHED_SUBSCRIPTION,
  SUB_ERROR,
} from "../actions/subscription";

const initialState = {
  isLoading: false,
  isError: false,
  errorMessage: "",
  planName: null,
  planId: null,
  redirectUrl: null,
  productsLimit: null,
  productsUsage: null,
  storesLimit: null,
  storesUsage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUB_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case FETCHED_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false,
        planName: action.planName,
        planId: action.planId,
        productsLimit: action.productsLimit,
        productsUsage: action.productsUsage,
        storesLimit: action.storesLimit,
        storesUsage: action.storesUsage,
        redirectUrl: action.redirectUrl,
      };

    case SUB_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.errorMessage,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case RESET_CHAIN:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
