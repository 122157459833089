import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

// IMPORT ACTIONS
import * as storesActions from "./stores";
import * as userActions from "./user";

import SERVER_CONSTANTS from "../../constants/airstoreServer";

import { LOGIN, LOGOUT } from "./auth";
import { SELECT_RETAIL_CHAIN } from "./chains";
import { SELECT_STORE } from "./stores";

////////// CHECK IF USER HAS TOKEN STORED LOCALLY ////////////////////////////////////////////
export const startup = () => {
  return async (dispatch) => {
    try {
      // STEP 1: Confirm the user token
      const token = await AsyncStorage.getItem("userToken");
      if (!token) {
        console.log("Welcome to Airstore! Please sign in");
        return;
      }
      dispatch({
        type: LOGIN,
        isSignedIn: true,
        token: token,
      });
      // STEP 2: Check token validity from server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/auth/check-token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      // STEP 3: If token is not valid remove from local storage
      if (response.data.message == "Token Expired") {
        await removeDataFromStorage("userToken");
        dispatch({
          type: LOGOUT,
        });
        return;
      }

      // STEP 4: Fetch user profile
      await dispatch(userActions.fetchProfile());

      // STEP 5: Check if there is saved chain locally
      const currentChain = await AsyncStorage.getItem("currentChain");
      if (currentChain) {
        dispatch({
          type: SELECT_RETAIL_CHAIN,
          currentChainId: currentChain.id,
          currentChain: JSON.parse(currentChain),
        });

        // STEP 6: Check if there is saved store locally
        const currentStore = await AsyncStorage.getItem("currentStore");
        if (currentStore) {
          dispatch({
            type: SELECT_STORE,
            currentStore: JSON.parse(currentStore),
          });
        }

        // STEP 7: Fetch stores
        await dispatch(storesActions.fetchChainStores());
      }
      return;
    } catch (error) {
      console.log(error);
    }
  };
};
