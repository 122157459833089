import React from "react";
import { StyleSheet, View, Text, TextInput } from "react-native";

// IMPORT GLOBAL COMPONENTS
import Ionicons from "@expo/vector-icons/Ionicons";

// IMPORT GLOBAL STYLES
import Colors from "../../styles/Colors";
import Styles from "../../styles/Styles";

///////////// START FUNCTION CLASS //////////////////
const DropdownSelector = (props) => {
  // UI element
  return (
    <View style={styles.box}>
      <Text>{props.text}</Text>
      <Ionicons name={"chevron-down-outline"} size={21} />
    </View>
  );
};

const styles = StyleSheet.create({
  box: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderColor: Colors.lightBorder,
    borderWidth: 1,
    borderRadius: 5,
  },
});

export default DropdownSelector;
