import React from "react";
import { View, Text, StyleSheet } from "react-native";

// IMPORT GLOBAL COMPONENTS
import AttentionBox from "../../components/containers/AttentionBox";
import Loader from "../../components/loaders/ContentLoader";
import SignUpBox from "../../auth/components/SignUpBox";
import LoginBox from "../../auth/components/LoginBox";
import * as RootNavigation from "../../routes/RootNavigation";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// IMPORT LOCAL COMPONENTS
import SubmitConnection from "./SubmitConnection";

// Importing Redux state components
import { connect } from "react-redux";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    isRequestVerified: state.connections.isRequestVerified,
    isLoadingConnection: state.connections.isLoading,
    errorMessage: state.connections.errorMessage,
    connectionUsername: state.connections.connectionUsername,
    isChainConnected: state.connections.isChainConnected,
    userName: state.user.profile.name,
    signedUp: state.connections.signedUp,
    userEmail: state.connections.userEmail,
  };
};

////////// START FUNCTION CLASS /////////
const ConnectInboundBox = (props) => {
  //////////// RETURN VIEW ////////

  // IS LOADING SECTION
  if (props.isLoadingConnection || props.isFetchigStores) {
    <View style={Styles.row}>
      <View style={[Styles.col, { flex: 1 }]}>
        <Loader />
      </View>
    </View>;
  }

  // NO CONNECTION REQUEST
  if (!props.queryParams.type) {
    return (
      <AttentionBox>
        <Text style={Styles.p}>No connection request</Text>
      </AttentionBox>
    );
  }

  // CONNECTION EXIST SECTION
  if (props.isChainConnected) {
    return (
      <AttentionBox>
        <Text>
          This Shopify store is already connected to another Retail Chain.
        </Text>
        <Text>
          If you own it, please{" "}
          <Text
            onPress={() => RootNavigation.navigate("LoginNavigator")}
            style={Styles.link}
          >
            Login
          </Text>{" "}
          to see the updated connection.
        </Text>
      </AttentionBox>
    );
  }

  // IS VERIFIED SECTION
  if (props.isRequestVerified) {
    return (
      <View style={[Styles.col, { width: "100%" }]}>
        <Text style={{ fontSize: 16 }}>
          Connecting {props.queryParams.type} account to Airstore.
        </Text>
        <View style={styles.connectionBox}>
          <Text>{props.connectionUsername}</Text>
        </View>
        {props.userEmail && !props.signedUp ? (
          // User entered email and server responded that this user
          // has not yet been signed up
          <View style={[Styles.col, { width: "100%" }]}>
            <Text style={styles.loginText}>
              Almost ready, complete registration to get started...
            </Text>
            <SignUpBox userEmail={props.userEmail} />
          </View>
        ) : props.userEmail ? (
          // User entered email and server responded that this user exist
          // User needs to login
          <View style={[Styles.col, { width: "100%" }]}>
            <Text style={styles.loginText}>
              Welcome back, please login to your account.
            </Text>
            <LoginBox userEmail={props.userEmail} />
          </View>
        ) : (
          // User is not logged in and needs to enter their email address
          // email will be sent to the server to check if there is existing user with this email
          <SubmitConnection queryParams={props.queryParams} />
        )}
      </View>
    );
  }

  // CONNECTION FAILED SECTION
  return (
    <AttentionBox>
      <Text style={Styles.p}>Failed to connect</Text>
      <Text style={Styles.p}>{props.errorMessage}</Text>
    </AttentionBox>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },

  imageContainer: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
  },

  content: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
    paddingHorizontal: 10,
  },

  image: {
    width: 270,
    height: 65,
    resizeMode: "contain",
  },

  connectionBox: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderRadius: 5,
    backgroundColor: Colors.activeItem,
    marginVertical: 10,
  },

  errorMessage: {
    paddingVertical: "2%",
    justifyContent: "center",
    alignItems: "center",
  },

  loginText: {
    fontSize: 16,
    marginVertical: 10,
  },
});

export default connect(mapStateToStore)(ConnectInboundBox);
