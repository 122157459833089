import React from "react";
import { StyleSheet, View } from "react-native";

// IMPORT COMPONENTS
import SupportButton from "../buttons/SupportButton";
import BarcodeButton from "../buttons/BarcodeButton";

const TopBar = (props) => {
  return (
    <View style={styles.bar}>
      <BarcodeButton />
      <SupportButton />
    </View>
  );
};

const styles = StyleSheet.create({
  bar: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
});

export default TopBar;
