import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";

// IMPORT GLOBAL COMPONENTS
import Ionicons from "@expo/vector-icons/Ionicons";
import VerticalDivider from "../../components/dividers/VerticalDivider";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// IMPORT REDUX
import { connect, useDispatch } from "react-redux";
import * as viewActions from "../../redux/actions/viewSettings";

// MAP PROPS TO REDUX
const mapStateToStore = (state) => {
  return {
    productsView: state.viewSettings.productsView,
  };
};

const ViewSelector = (props) => {
  const dispatch = useDispatch();
  return (
    <View style={[Styles.row, styles.selectorRow]}>
      <TouchableOpacity
        onPress={() => dispatch(viewActions.setProductsView("list"))}
        style={[
          props.productsView === "list" ? styles.active : null,
          styles.box,
        ]}
      >
        <Ionicons name={"list-outline"} size={25} />
      </TouchableOpacity>
      <VerticalDivider />
      <TouchableOpacity
        onPress={() => dispatch(viewActions.setProductsView("tile"))}
        style={[
          props.productsView === "tile" ? styles.active : null,
          styles.box,
        ]}
      >
        <Ionicons name={"grid-outline"} size={20} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  selectorRow: {
    paddingStart: 10,
    marginVertical: 10,
  },

  box: {
    padding: 5,
    borderRadius: 5,
  },

  active: {
    backgroundColor: "#ffffff",
  },
});

export default connect(mapStateToStore)(ViewSelector);
