import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  Image,
  View,
  Dimensions,
} from "react-native";

// IMPORT GLOBAL COMPONENTS
import ListRow from "../../components/containers/ListRow";
import UnitBox from "../../components/containers/UnitBox";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

const ProductRow = (props) => {
  // DETERMINE SCREEN SIZE
  const [device, setDevice] = useState("mobile");

  useEffect(() => {
    const { height, width } = Dimensions.get("window");
    const aspectRatio = height / width;
    // mobile device
    if (aspectRatio > 1.6) {
      setDevice("mobile");
      // tablet
    } else if (aspectRatio > 1) {
      setDevice("tablet");
      // desktop
    } else {
      setDevice("desktop");
    }
  }, []);

  //////////////////// RETURN VIEW ///////////////////
  if (device === "mobile") {
    return (
      <ListRow>
        <TouchableOpacity
          style={[Styles.row, { paddingVertical: 10 }]}
          onPress={props.onPress}
        >
          <View style={[Styles.col, { flex: 1 }]}>
            <Image
              source={{
                uri: props.product.catalogInformation.media.mainImageUrl,
              }}
              style={styles.productImage}
            />
          </View>
          <View style={[Styles.col, { flex: 3 }]}>
            <View style={Styles.row}>
              <Text numberOfLines={2} style={Styles.H3}>
                {props.product.catalogInformation.title}
              </Text>
            </View>
            <View style={Styles.row}>
              <View style={[Styles.col, { flex: 1 }]}>
                <UnitBox unit={"Qt."} value={props.product.stock} />
              </View>
              <View style={[Styles.col, { flex: 1 }]}>
                <UnitBox unit={"$"} value={props.product.price} />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </ListRow>
    );
  }

  return (
    <ListRow>
      <TouchableOpacity style={Styles.row} onPress={props.onPress}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Image
            source={{
              uri: props.product.catalogInformation.media.mainImageUrl,
            }}
            style={styles.productImage}
          />
        </View>
        <View style={[Styles.col, { flex: 3 }]}>
          <Text numberOfLines={2} style={Styles.H3}>
            {props.product.catalogInformation.title}
          </Text>
        </View>
        <View style={[Styles.col, { flex: 2 }]}>
          <View style={[Styles.row]}>
            <View style={[Styles.col, { flex: 1 }]}>
              <UnitBox unit={"Qt."} value={props.product.stock} />
            </View>
            <View style={[Styles.col, { flex: 1 }]}>
              <UnitBox unit={"$"} value={props.product.price} />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </ListRow>
  );
};

const styles = StyleSheet.create({
  productImage: {
    maxWidth: 70,
    width: "100%",
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: "center",
  },
});

export default ProductRow;
