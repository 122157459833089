import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  ActivityIndicator,
  Dimensions,
} from "react-native";

// IMPORT GLOBAL COMPONENTS
import Card from "../../components/containers/Card";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as chainsActions from "../../redux/actions/chains";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    chainStats: state.chains.chainStats,
    isLoading: state.chains.isFetchingStats,
    currentChain: state.chains.currentChain,
  };
};

///////// START FUNCTION CLASS ///////////////////////
const StatsRow = (props) => {
  const dispatch = useDispatch();

  // DETERMINE SCREEN SIZE
  const [columns, setColumns] = useState(1);
  useEffect(() => {
    const { height, width } = Dimensions.get("window");
    const aspectRatio = height / width;
    // mobile device
    if (aspectRatio > 1.6) {
      setColumns(2);
      // tablet
    } else if (aspectRatio > 1) {
      setColumns(2);
      // desktop
    } else {
      setColumns(4);
    }
  }, []);

  // FETCH STATS
  useEffect(() => {
    dispatch(chainsActions.fetchChainStats());
  }, [props.currentChain]);

  ////////////// RETURN VIEW /////////////////////
  return (
    <View style={styles.statsRow}>
      <View style={columns === 4 ? styles.containerRow : styles.containerCol}>
        <View style={[Styles.row]}>
          <View style={styles.contentCol}>
            <Card style={styles.card}>
              <Text style={styles.statLabel}>Inventory Value</Text>
              {props.isLoading ? (
                <ActivityIndicator size="large" color={Colors.primary} />
              ) : (
                <Text style={styles.stat}>
                  ${numCommas(props.chainStats.inventoryValue)}
                </Text>
              )}
            </Card>
          </View>
          <View style={styles.contentCol}>
            <Card style={styles.card}>
              <Text style={styles.statLabel}># Of Products</Text>
              {props.isLoading ? (
                <ActivityIndicator size="large" color={Colors.primary} />
              ) : (
                <Text style={styles.stat}>
                  {props.chainStats.countProducts}
                </Text>
              )}
            </Card>
          </View>
        </View>
        <View style={[Styles.row]}>
          <View style={styles.contentCol}>
            <Card style={styles.card}>
              <Text style={styles.statLabel}># Of Stores</Text>
              {props.isLoading ? (
                <ActivityIndicator size="large" color={Colors.primary} />
              ) : (
                <Text style={styles.stat}>{props.chainStats.countStores}</Text>
              )}
            </Card>
          </View>
          <View style={styles.contentCol}>
            <Card style={styles.card}>
              <Text style={styles.statLabel}>Total Revenue</Text>
              {props.isLoading ? (
                <ActivityIndicator size="large" color={Colors.primary} />
              ) : (
                <Text style={styles.stat}>{props.chainStats.chainRevenue}</Text>
              )}
            </Card>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  statsRow: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginVertical: 15,
  },

  containerRow: {
    width: "50%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  containerCol: {
    width: "100%",
  },

  contentCol: {
    flex: 1,
    paddingHorizontal: 10,
    marginBottom: 20,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },

  card: {
    paddingVertical: 20,
  },

  stat: {
    fontSize: 35,
    color: Colors.primary,
    textAlign: "center",
    fontWeight: "600",
  },

  statLabel: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#7d7d7d",
    marginBottom: 20,
  },
});

export default connect(mapStateToStore)(StatsRow);

const numCommas = (number) => {
  let x = number ? number : 0;
  let result;
  if (x >= 10000000) {
    x = Math.floor(x / 100000) / 10;
    result = x.toString() + "M";
  } else if (x >= 1000000) {
    x = Math.floor(x / 10000) / 100;
    result = x.toString() + "M";
  } else if (x >= 100000) {
    x = Math.floor(x / 100) / 10;
    result = x.toString() + "K";
  } else {
    x = Math.floor(x);
    result = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return result;
};
