import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";

// IMPORT GLOBAL COMPONENTS
import CloseButton from "../../components/buttons/CloseButton";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";
import ProductsCounter from "../../shopify/components/ProductsCounter";

// IMPORT LOCAL COMPONENTS

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as shopifyActions from "../../redux/actions/shopify";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {};
};

///////// START FUNCTION CLASS ///////////////////////
const ShopifyBox = (props) => {
  const dispatch = useDispatch();

  ////////////// RETURN VIEW /////////////////////
  return (
    <Card style={styles.card}>
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 5 }]}>
          <View style={Styles.row}>
            <Image
              source={require("../../../assets/shopify-icon.png")}
              style={styles.icon}
              accessible={false}
            />
            <Text style={Styles.H2}>Shopify connection waiting activation</Text>
          </View>
        </View>
        <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
          <CloseButton onClose={() => console.log("Close")} />
        </View>
      </View>
      <Divider />
      <View style={[Styles.row, { paddingBottom: 20 }]}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text style={Styles.H5}>
            Shopify connection is ready to start synchronizing.
          </Text>
          <Text style={Styles.p}>
            Before we start it is recommended that you add valid barcodes to all
            of your products in your Shopify store to ansure we are not creating
            any duplicates. We indetify products based on their barcodes (GTIN /
            UPC / EAN).
          </Text>
        </View>
      </View>
      <ProductsCounter />
      <Divider style={{ width: "100%" }} />
      <View style={Styles.row}>
        <View style={[Styles.col, { flex: 1 }]}>
          <FlatButton
            title="Start Sync"
            onPress={() => dispatch(shopifyActions.shopifySetSync(true))}
            buttonStyle={{ width: "100%" }}
          />
        </View>
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 20,
    width: "100%",
  },

  icon: {
    width: 40,
    height: undefined,
    aspectRatio: 1,
    resizeMode: "contain",
    alignItems: "center",
    position: "relative",
    marginEnd: 20,
  },
});

export default connect(mapStateToStore)(ShopifyBox);
