import React, { useState, useEffect, useCallback } from "react";
import {
  StyleSheet,
  Text,
  View,
  Button,
  Modal,
  Dimensions,
  Image,
} from "react-native";
import { BarCodeScanner } from "expo-barcode-scanner";

// IMPORT SECTION COMPONENTS
import ProductModal from "../components/ProductModal";

// Importing Redux state components
import { useDispatch } from "react-redux";
import * as singleProductActions from "../../redux/actions/singleProduct";

const BarcodeScannerScreen = (props) => {
  // camera permission handler
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [message, setMessage] = useState("Aim the green box to the barcode");
  // modal state
  const [modalVisible, setModalVisible] = useState(false);

  // dispatch action when barcode scanned
  const dispatch = useDispatch();

  const toggleScannedHandler = ({ type, data }) => {
    if (data.length > 8) {
      dispatch(singleProductActions.fetchScanned(data, type));
      setScanned(true);
      setMessage("Scanned barcode: " + data);
      setModalVisible(true);
    } else {
      setMessage("Barcode not supported");
    }
  };

  const resetHandler = () => {
    dispatch(singleProductActions.setCurrentProduct(null));
    setScanned(false);
    setModalVisible(false);
    setMessage("Aim the green box to the barcode");
  };

  const askForCameraPermission = () => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status == "granted");
    })();
  };

  // Request camera permissions
  useEffect(() => {
    askForCameraPermission();
  }, []);

  // Check permissions and return the screens
  if (hasPermission === null) {
    return (
      <View style={styles.screen}>
        <View style={styles.mesageBox}>
          <Text style={styles.message}>Requesting for camera permissions</Text>
        </View>
      </View>
    );
  }

  // If did't grant permissions
  if (hasPermission === false) {
    return (
      <View style={styles.screen}>
        <View style={styles.mesageBox}>
          <Text style={styles.message}>No acces to camera</Text>
        </View>
        <Button
          title={"Allow Camera"}
          onPress={() => askForCameraPermission()}
        />
      </View>
    );
  }

  // The main screen
  return (
    <View style={styles.screen}>
      {/* Setting up modal for product edit */}
      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <ProductModal onClose={resetHandler} />
      </Modal>

      <View style={styles.barcodeBox}>
        <BarCodeScanner
          onBarCodeScanned={scanned ? false : toggleScannedHandler}
          style={styles.cameraView}
        />
        <Image
          source={require("../../../assets/scanner-box.png")}
          style={styles.image}
        />
        {/* <View style={styles.barcodeTarget}></View> */}
        <View style={styles.mesageBox}>
          <Text style={styles.message}>{message}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: "#dcdcdc",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  barcodeBox: {
    flex: 1,
    width: "100%",
    alignItems: "center",
  },

  cameraView: {
    height: "100%",
    width: "100%",
  },

  mesageBox: {
    marginTop: 100,
    height: 50,
    borderRadius: 20,
    backgroundColor: "#ffffff",
    width: "90%",
    alignItems: "center",
    justifyContent: "center",
  },

  message: {
    fontSize: 18,
  },

  barcodeTarget: {
    borderColor: "#199522",
    borderWidth: 3,
    height: 150,
    width: "80%",
    marginTop: -(Dimensions.get("window").height / 1.8),
  },

  scanButton: {
    marginTop: 40,
  },

  image: {
    // flex: 1,
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    // resizeMode: "cover",
    marginTop: -Dimensions.get("window").height,
  },
});

export default BarcodeScannerScreen;
