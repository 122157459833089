import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Image, Dimensions } from "react-native";

// IMPORT GLOBAL COMPONENTS
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";
import { LinearGradient } from "expo-linear-gradient";
import Screen from "../../components/containers/Screen";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

//// IMPORT LOCAL COMPONENTS
import ConnectUserBox from "../components/ConnectUserBox";
import ConnectInboundBox from "../components/ConnectInboundBox";
import MarketingBox from "../components/MarketingBox";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as chainsActions from "../../redux/actions/chains";
import * as shopifyActions from "../../redux/actions/shopify";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userName: state.user.profile.name,
  };
};

////////// START FUNCTION CLASS /////////
const ConnectionsScreen = (props) => {
  // DETERMINE SCREEN SIZE
  const [columns, setColumns] = useState(1);
  useEffect(() => {
    const { height, width } = Dimensions.get("window");
    const aspectRatio = height / width;
    // mobile device
    if (aspectRatio > 1.6) {
      setColumns(1);
      // tablet
    } else if (aspectRatio > 1) {
      setColumns(2);
      // desktop
    } else {
      setColumns(2);
    }
  }, []);

  // Initiate dispatch
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useState({});

  // FETCH PARAMS
  useEffect(async () => {
    if (props.isSignedIn) {
      await dispatch(chainsActions.fetchUserChains());
    }
    if (props.route.params) {
      setQueryParams(props.route.params);
      if (props.route.params.type === "shopify") {
        dispatch(shopifyActions.verifyShopifyRequest(props.route.params));
      }
    }
  }, []);

  //////////// RETURN VIEW ////////
  return (
    <LinearGradient
      // Background Linear Gradient
      colors={["#6a0ed1", "#9c06b9"]}
      start={{ x: 0.2, y: 0.2 }}
      end={{ x: 0.7, y: 0.6 }}
      locations={[0.1, 0.9]}
      style={styles.background}
    >
      <Screen>
        <View style={Styles.row}>
          <View style={[Styles.col, styles.imageContainer]}>
            <Image
              source={require("../../../assets/company-logo.png")}
              style={styles.image}
            />
          </View>
        </View>
        <View style={columns === 2 ? styles.containerRow : styles.containerCol}>
          <View style={columns === 2 ? styles.contentCol : styles.contentRow}>
            <Card style={styles.card}>
              <View style={Styles.row}>
                <View style={[Styles.col]}>
                  <Text style={Styles.H1}>
                    New{queryParams.type ? " " + queryParams.type : null}{" "}
                    connection
                  </Text>
                </View>
              </View>
              <Divider />
              {props.isSignedIn ? (
                <View style={Styles.row}>
                  <View style={[Styles.col, { flex: 1 }]}>
                    <Text style={Styles.H3}>Hey {props.userName},</Text>
                    <ConnectUserBox queryParams={queryParams} />
                  </View>
                </View>
              ) : (
                <View style={Styles.row}>
                  <View style={[Styles.col, { flex: 1 }]}>
                    <Text style={Styles.H3}>Welcome aboard,</Text>
                    <ConnectInboundBox queryParams={queryParams} />
                  </View>
                </View>
              )}
            </Card>
          </View>
          {!props.isSignedIn ? (
            <View style={columns === 2 ? styles.contentCol : styles.contentRow}>
              <Card style={styles.card}>
                <View style={Styles.row}>
                  <View style={[Styles.col]}>
                    <Text style={Styles.H1}>Put your store on autopilot</Text>
                  </View>
                </View>
                <Divider />
                <MarketingBox />
              </Card>
            </View>
          ) : null}
        </View>
      </Screen>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },

  imageContainer: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
  },

  image: {
    width: 270,
    height: 65,
    resizeMode: "contain",
  },

  containerRow: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 50,
  },

  containerCol: {
    width: "100%",
  },

  contentCol: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10,
  },

  contentRow: {
    width: "100%",
    alignItems: "center",
    marginTop: 50,
    paddingHorizontal: 10,
  },

  card: {
    maxWidth: 600,
  },
});

export default connect(mapStateToStore)(ConnectionsScreen);
