import React, { useEffect } from "react";
import { View, Text, StyleSheet, FlatList, Platform } from "react-native";

// IMPORT GLOBAL COMPONENTS
import Screen from "../../components/containers/Screen";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// IMPORT LOCAL COMPONENTS
import ActionBox from "../components/ActionBox";
import StatsRow from "../components/StatsRow";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as chainActions from "../../redux/actions/chains";
import * as notificationsActions from "../../redux/actions/notifications";

// Connect component with the redux state
const mapStateToStore = (state) => {
  return {
    userProfile: state.user.profile,
    notifications: state.notifications.notifications,
    isLoading: state.user.isLoading,
  };
};

////////// START FUNCTION CLASS /////////
const DashboardScreen = (props) => {
  const dispatch = useDispatch();

  // Update information every time this screen loads
  useEffect(() => {
    let unsubscribe = props.navigation.addListener("focus", () => {
      dispatch(chainActions.fetchChainStats());
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe; 
  }, [props.navigation]);

  // LOAD NOTIFICATIONS
  useEffect(() => {
    dispatch(notificationsActions.fetchChainNotifications());
  }, []);

  const renderNotifications = (itemData) => {
    return <ActionBox notification={itemData.item} />;
  };

  //////////// RETURN VIEW ////////
  return (
    <Screen>
      <View style={[Styles.row, { paddingVertical: 10 }]}>
        <View style={[Styles.col, { flex: 1 }]}>
          <Text style={[Styles.H2]}>Hey {props.userProfile.name}</Text>
        </View>
      </View>
      <View style={[Styles.row]}>
        <View style={[Styles.col, { flex: 1 }]}>
          <StatsRow />
        </View>
      </View>
      <View style={[Styles.row]}>
        <View style={styles.notificationsSection}>
          {props.notifications.length > 0 ? (
            <FlatList
              key={"notifications"}
              data={props.notifications}
              keyExtractor={(item) => item.id}
              renderItem={renderNotifications}
              contentContainerStyle={Styles.listContainer}
            />
          ) : (
            <Text style={styles.cleanBoard}>Runway is clear!</Text>
          )}
        </View>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  notificationsSection: {
    width: "100%",
  },

  cleanBoard: {
    fontSize: 40,
    marginTop: 30,
    textAlign: "center",
    color: Colors.accent,
  },
});

export default connect(mapStateToStore)(DashboardScreen);
