import React, { useEffect } from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  Text,
  Image,
  KeyboardAvoidingView,
  Platform,
} from "react-native";

// IMPORT GLOBAL COMPONENTS
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";
import { LinearGradient } from "expo-linear-gradient";

// IMPORT LOCAL COMPONENTS
import SignUpBox from "../components/SignUpBox";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";
import Colors from "../../styles/Colors";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as authActions from "../../redux/actions/auth";

////////// START FUNCTION CLASS /////////
const SignUpScreen = (props) => {
  // Hide header
  useEffect(() => {
    props.navigation.setOptions({ headerShown: false });
  }, []);

  // Handle unique sign-up Params
  const queryParams = JSON.stringify(props.route.params);

  // Initiate dispatch
  const dispatch = useDispatch();

  //////////// RETURN VIEW ////////
  return (
    <LinearGradient
      // Background Linear Gradient
      colors={["#6a0ed1", "#9c06b9"]}
      start={{ x: 0.2, y: 0.2 }}
      end={{ x: 0.7, y: 0.6 }}
      locations={[0.1, 0.9]}
      style={styles.background}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <ScrollView>
          <View style={Styles.row}>
            <View style={[Styles.col, styles.imageContainer]}>
              <Image
                source={require("../../../assets/company-logo.png")}
                style={styles.image}
              />
            </View>
          </View>
          <View style={Styles.row}>
            <View style={[Styles.col, styles.content]}>
              <Card style={styles.card}>
                <View style={Styles.row}>
                  <View style={[Styles.col]}>
                    <Text style={Styles.H1}>Sign Up</Text>
                  </View>
                </View>
                <Divider />
                <SignUpBox />
                <Divider />
                <FlatButton
                  title="Login"
                  onPress={() => props.navigation.navigate("Login")}
                  buttonStyle={{
                    backgroundColor: "transparent",
                    borderColor: Colors.primary,
                    borderWidth: 1,
                  }}
                  titleStyle={{ color: Colors.primary }}
                />
              </Card>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },

  inner: {
    flex: 1,
    justifyContent: "center",
  },

  imageContainer: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
  },

  content: {
    flex: 1,
    alignItems: "center",
    marginTop: 50,
    paddingHorizontal: 10,
  },

  card: {
    maxWidth: 600,
  },

  image: {
    width: 270,
    height: 65,
    resizeMode: "contain",
  },
});

export default SignUpScreen;
