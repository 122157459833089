import React from "react";
import { StyleSheet, View, Text } from "react-native";

// IMPORT GLOBAL COMPONENTS
import CloseButton from "../../components/buttons/CloseButton";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";
import InputText from "../../components/form/InputText";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// Importing Redux state components
import { useDispatch } from "react-redux";
import * as chainActions from "../../redux/actions/chains";

//// IMPORT FORM LIBS
import { Formik } from "formik";
import * as yup from "yup";

const chainSchema = yup.object({
  chainName: yup
    .string()
    .min(4, "Retail chain name must be at least 4 characters")
    .required("Please enter retail chain name"),
});

///////// START FUNCTION CLASS ///////////////////////
const AddChainModal = (props) => {
  const dispatch = useDispatch();

  ////////////// RETURN VIEW  /////////////////////
  return (
    <View style={styles.centeredModal}>
      <Card style={styles.modalBox}>
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 5 }]}>
            <Text style={Styles.H2}>Add Retail Chain</Text>
            <Text style={Styles.smallNote}>
              Retail Chain is simply a group of stores
            </Text>
          </View>
          <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
            <CloseButton onClose={() => props.onClose()} />
          </View>
        </View>
        <Divider />
        <Formik
          initialValues={{
            chainName: "",
          }}
          validationSchema={chainSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await dispatch(chainActions.addRetailChain(values.chainName));
            setSubmitting(false);
            props.onClose();
          }}
        >
          {(formikProps) => (
            <View style={styles.form}>
              <InputText
                placeholder="Retail Chain Name"
                onChangeText={formikProps.handleChange("chainName")}
                value={formikProps.values.chainName}
                onBlur={formikProps.handleBlur("chainName")}
                errorMessage={
                  formikProps.touched.chainName && formikProps.errors.chainName
                }
              />
              <View>
                <FlatButton
                  onPress={formikProps.handleSubmit}
                  title="Add"
                  isLoading={formikProps.isSubmitting}
                />
              </View>
            </View>
          )}
        </Formik>
        <Divider />
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 1 }]}>
            <Text style={Styles.H3}>Note</Text>
            <Text style={Styles.p}>
              Connecting your stores with Shopify, Square, or any other service,
              is done through the retail chain. You can later add stores to this
              chain.
            </Text>
          </View>
        </View>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredModal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingHorizontal: "5%",
  },

  modalBox: {
    maxWidth: 600,
    alignItems: "flex-start",
    marginTop: 15,
  },

  form: {
    width: "100%",
    justifyContent: "flex-start",
  },
});

export default AddChainModal;
