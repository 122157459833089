import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  FlatList,
  Dimensions,
  Text,
  Modal,
} from "react-native";

// IMPORT GLOBAL COMPONENTS
import LoadingList from "../../components/loaders/LoadingList";
import Screen from "../../components/containers/Screen";
import FlatButton from "../../components/buttons/FlatButton";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// IMPORT SECTION COMPONENTS
import ProductTile from "../components/ProductTile";
import ProductModal from "../components/ProductModal";
import ProductRow from "../components/ProductRow";
import ViewSelector from "../components/ViewSelector";

// Importing Redux state components
import { connect, useDispatch } from "react-redux";
import * as productsActions from "../../redux/actions/products";
import * as singleProductActions from "../../redux/actions/singleProduct";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    currentStore: state.stores.currentStore,
    isFetchingProducts: state.products.isFetchingProducts,
    currentStoreProducts: state.products.currentStoreProducts,
    isError: state.products.isError,
    errorMessage: state.products.errorMessage,
    isUpdatingProduct: state.singleProduct.isUpdatingProduct,
    productsView: state.viewSettings.productsView,
  };
};

///////////// START FUNCTION CLASS //////////////////
const ProductsScreen = (props) => {
  // DETERMINE SCREEN SIZE
  const [columns, setColumns] = useState(2);
  useEffect(() => {
    const { height, width } = Dimensions.get("window");
    const aspectRatio = height / width;
    // mobile device
    if (aspectRatio > 1.6) {
      setColumns(2);
      // tablet
    } else if (aspectRatio > 1) {
      setColumns(3);
      // desktop
    } else {
      setColumns(4);
    }
  }, []);

  // modal state
  const [modalVisible, setModalVisible] = useState(false);
  const [productEdited, setProductEdited] = useState(false);

  const dispatch = useDispatch();

  // Fetch products using the reducer
  useEffect(() => {
    if (props.currentStore) {
      dispatch(productsActions.fetchProducts());
    }
  }, [props.currentStore]);

  const editProductHandler = (product) => {
    dispatch(singleProductActions.setCurrentProduct(product));
    setModalVisible(true);
  };

  // CLOSE MODAL FUNCTION
  const closeModal = () => {
    setModalVisible(false);
    setProductEdited(!productEdited);
  };

  // RENDER GRID OF TILES
  const renderGrid = (itemData) => {
    return (
      <ProductTile
        product={itemData.item}
        onPress={() => editProductHandler(itemData.item)}
        columns={columns}
      />
    );
  };

  // RENDER LIST
  const renderList = (itemData) => {
    return (
      <ProductRow
        product={itemData.item}
        onPress={() => editProductHandler(itemData.item)}
      />
    );
  };

  //////////// RETURN VIEW //////////////////////
  // IF LOADING
  if (!props.currentStore) {
    return (
      <View style={styles.screen}>
        <Text style={Styles.screenMessage}>Please add a store first</Text>
        <FlatButton
          title="Add Store"
          onPress={() => props.navigation.navigate("ChainSettings")}
        />
      </View>
    );
  }

  // IF LOADING
  if (props.isFetchingProducts) {
    return (
      <View style={styles.loadingScreen}>
        <LoadingList />
      </View>
    );
  }
  // IF NO PRODUCTS FOUND / ERROR
  if (props.isError) {
    return (
      <View style={styles.screen}>
        <Text style={Styles.screenMessage}>{props.errorMessage}</Text>
        <FlatButton
          title="Reload"
          onPress={() => dispatch(productsActions.fetchProducts())}
        />
      </View>
    );
  }
  // IF LOADING
  return (
    <Screen>
      {/* Setting up modal for product edit */}
      <Modal animationType="fade" transparent={true} visible={modalVisible}>
        <ProductModal onClose={closeModal} />
      </Modal>
      <ViewSelector />
      <ScrollView
        style={[
          styles.productsSection,
          props.productsView === "list" ? styles.listSection : null,
        ]}
      >
        {props.productsView === "list" ? (
          <FlatList
            key={"list"}
            data={props.currentStoreProducts}
            keyExtractor={(item) => item.internalId}
            renderItem={renderList}
            contentContainerStyle={Styles.listContainer}
          />
        ) : (
          <FlatList
            key={"grid"}
            data={props.currentStoreProducts}
            keyExtractor={(item) => item.internalId}
            renderItem={renderGrid}
            numColumns={columns}
            contentContainerStyle={Styles.listContainer}
          />
        )}
      </ScrollView>
    </Screen>
  );
};

const styles = StyleSheet.create({
  productsSection: {
    width: "100%",
  },

  listSection: {
    backgroundColor: "#ffffff",
    padding: 10,
    borderRadius: 5,
  },

  loadingScreen: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },

  screen: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },

  gridContainer: {
    // padding: 10,
    paddingVertical: 20,
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
});

export default connect(mapStateToStore)(ProductsScreen);
