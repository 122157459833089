import React, { useEffect } from "react";
import { Platform } from "react-native";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from "@react-navigation/drawer";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as authActions from "../redux/actions/auth";
import * as storesAction from "../redux/actions/stores";

// IMPORT NAVIGATORS
import ChainSettings from "../chainSettings/routes/SettingsNavigator";
import ProductsManagementNavigator from "../products/routes/ProductsManagementNavigator";

// IMPORT SCREENS
import DashboardScreen from "../dashboard/screens/DashboardScreen";
import OrdersScreen from "../orders/screens/OrdersScreen";

// IMPORT GLOBAL COMPONENTS
import TopBar from "../components/containers/TopBar";
import BarcodeButton from "../components/buttons/BarcodeButton";

// IMPORT LOCAL COMPONENTS
import SelectStoreWidget from "./components/SelectStoreWidget";

const mapStateToStore = (state) => {
  return {
    userName: state.user.profile.name,
  };
};

const CustomDrawerContent = (content) => {
  const dispatch = useDispatch();
  return (
    <DrawerContentScrollView {...content}>
      <DrawerItem label={() => <SelectStoreWidget />} onPress={() => {}} />
      <DrawerItemList {...content} />
      <DrawerItem
        label="Logout"
        onPress={() => dispatch(authActions.logout())}
      />
    </DrawerContentScrollView>
  );
};

const Drawer = createDrawerNavigator();

////////// START FUNCTION CLASS /////////
const AppNavigator = (props) => {
  // Initiate dispatch
  const dispatch = useDispatch();

  return (
    <Drawer.Navigator
      hideStatusBar={false}
      headerStyle={{ height: 80 }}
      screenOptions={{
        drawerItemStyle: { marginVertical: 10, fontSize: 20 },
        drawerHideStatusBarOnOpen: false,
      }}
      drawerContent={(content) => (
        <CustomDrawerContent {...content} props={props} />
      )}
    >
      <Drawer.Screen
        name="Dashboard"
        component={DashboardScreen}
        options={{
          title: "Dashboard",
          headerRight: () => <TopBar />,
        }}
      />

      <Drawer.Screen
        name="ProductsManagementNavigator"
        component={ProductsManagementNavigator}
        options={{
          title: "Products",
          headerRight: () => <TopBar />,
        }}
      />
      <Drawer.Screen
        name="Orders"
        component={OrdersScreen}
        options={{
          title: "Orders",
          headerRight: () => <TopBar />,
        }}
      />
      <Drawer.Screen
        name="ChainSettings"
        component={ChainSettings}
        options={{
          title: "Chain Settings",
          headerRight: () => <TopBar />,
        }}
      />
    </Drawer.Navigator>
  );
};

export default connect(mapStateToStore)(AppNavigator);
