import axios from "axios";

// Import constants
import SERVER_CONSTANTS from "../../constants/airstoreServer";

// Set reducer cases
export const FETCH_ORDERS = "FETCH_ORDERS";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const ORDERS_ERROR = "ORDERS_ERROR";

///////// FETCH ALL ORDERS OF A CHAIN /////////////
export const fetchChainOrders = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;

    // Start fetching products
    dispatch({ type: FETCH_ORDERS });
    try {
      // STEP 1: Fetch orders of the chain from the server
      const response = await axios(SERVER_CONSTANTS.SERVER_URI + "/orders", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        params: { chainId: chainId },
      });

      // If chain has no orders
      if (response.status === 204) {
        dispatch({
          type: ORDERS_ERROR,
          errorMessage: "No orders found for this Retail Chain",
        });
        return;
      }

      //STEP 2: Update state with the orders
      dispatch({
        type: UPDATE_ORDERS,
        chainOrders: response.data.chainOrders,
      });
      return;
    } catch (error) {
      console.log(error);
      dispatch({
        type: ORDERS_ERROR,
        errorMessage: "No orders found for this Retail Chain",
      });
      return;
    }
  };
};
