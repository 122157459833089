import axios from "axios";

// Import constants
import SERVER_CONSTANTS from "../../constants/airstoreServer";

// Set reducer cases
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";

///////// FETCH ALL PRODUCTS OF A SPECIFIC STORE /////////////
export const fetchProducts = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const storeId = getState().stores.currentStore.id;
    const chainId = getState().chains.currentChain.id;

    // Start fetching products
    dispatch({ type: FETCH_PRODUCTS });
    try {
      // STEP 1: Get all store products from the server
      const response = await axios(SERVER_CONSTANTS.SERVER_URI + "/products", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        params: { storeId: storeId, chainId: chainId },
      });
      // If not products were found
      if (response.status === 204) {
        console.log("No products found");
        dispatch({
          type: PRODUCTS_ERROR,
          errorMessage:
            "No porducts found, please add some using the scanner...",
        });
        return;
      }

      //STEP 2: Update state with the products
      dispatch({
        type: UPDATE_PRODUCTS,
        currentStoreProducts: response.data.storeProducts,
      });
      return;
    } catch (error) {
      console.log(error);
      dispatch({
        type: PRODUCTS_ERROR,
        errorMessage:
          "Could not connect to the server, make sure you have internet connection",
      });
      return;
    }
  };
};

export const updateProduct = (barcode, price, stock) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    let data = JSON.stringify({
      barcode: barcode,
      price: price,
      stock: stock,
    });
    let resData;
    try {
      const response = await fetch("http://164.90.246.179:8080/product", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: data,
      });

      resData = await response.json();
      return resData;
    } catch (error) {
      console.log(error);
    }
  };
};

// Add GTIN to the product
export const addGtin = (barcode, gtin) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    let data = JSON.stringify({
      barcode: barcode,
      gtin: gtin,
    });
    let resData;
    try {
      const response = await fetch("http://164.90.246.179:8080/product/gtin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: data,
      });
      resData = await response.json();
      return resData.message;
    } catch (error) {
      console.log(error);
    }
  };
};

// Action to update DB that the product information is wrong
export const wrongInfo = (barcode) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    let data = JSON.stringify({
      barcode: barcode,
    });
    let resData;
    try {
      const response = await fetch("http://164.90.246.179:8080/product/wrong", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: data,
      });
      resData = await response.json();
      return resData.message;
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchProductsOfAnotherStore = (storeId) => {
  return async (dispatch) => {
    let resData;
    let products;
    try {
      const response = await fetch(
        "http://164.90.246.179:8008/products/store/" + storeId,
        {
          method: "GET",
        }
      );

      resData = await response.json();
      if (resData.message === "Not logged in") {
        products = [];
      } else {
        products = resData.products;
      }
    } catch (error) {
      console.log(error);
    }

    dispatch({ type: FETCH_PRODUCTS, products: products });
  };
};
