import { LOGOUT } from "../actions/auth";
import { RESET_CHAIN } from "../actions/chains";

import {
  LOAD_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
} from "../actions/notifications";

const initialState = {
  isLoading: false,
  notifications: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_NOTIFICATIONS:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        isLoading: false,
        notifications: action.notifications,
      };
    case RESET_CHAIN:
      return {
        ...initialState,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
