import React from "react";
import { StyleSheet, View, Text } from "react-native";

// IMPORT GLOBAL STYLES
import Colors from "../../styles/Colors";
import Styles from "../../styles/Styles";

// IMPORT GLOBAL COMPONENTS

///////////// START FUNCTION CLASS //////////////////
const UnitBox = (props) => {
  return (
    <View style={[Styles.row, props.boxStyle]}>
      <View style={styles.unitBox}>
        <Text style={styles.unit}>{props.unit}</Text>
      </View>
      <View style={styles.valueBox}>
        <Text style={styles.value} numberOfLines={1}>
          {props.value || props.value === 0 ? props.value : " "}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  unitBox: {
    borderColor: Colors.lightBorder,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderStartWidth: 1,
    borderTopStartRadius: 6,
    borderBottomStartRadius: 6,
    paddingVertical: 4,
    backgroundColor: Colors.lightBorder,
    width: 30,
    justifyContent: "center",
    alignItems: "center",
  },

  unit: {
    fontSize: 16,
    color: Colors.accent,
  },

  valueBox: {
    borderColor: Colors.lightBorder,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderEndWidth: 1,
    backgroundColor: "#ffffff",
    borderTopEndRadius: 6,
    borderBottomEndRadius: 6,
    paddingVertical: 4,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 5,
    flex: 1,
  },

  value: {
    fontSize: 16,
  },
});

export default UnitBox;
