import axios from "axios";

import SERVER_CONSTANTS from "../../constants/airstoreServer";

export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";

////////// FETCH CHAIN NOTIFICATIONS ////////////////////////////////////////////
export const fetchChainNotifications = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const chainId = getState().chains.currentChain.id;
    try {
      dispatch({ type: LOAD_NOTIFICATIONS });
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/chains/notifications",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { chainId: chainId },
        }
      );
      if (!response.data.profile) {
      }

      // Dispatch information to redux state
      dispatch({
        type: UPDATE_NOTIFICATIONS,
        notifications: response.data.notifications,
      });
      return;
    } catch (error) {
      console.log(error);
    }
  };
};
