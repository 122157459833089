import axios from "axios";

export const LOADING_CONNECTION = "LOADING_CONNECTION";
export const REQUEST_VERIFIED = "REQUEST_VERIFIED";
export const REQUEST_FAILED = "REQUEST_FAILED";
export const READY_FOR_LOGIN = "READY_FOR_LOGIN";
export const CHAIN_CONNECTED = "CHAIN_CONNECTED";

// IMPORT ACTIONS
import * as chainsActions from "./chains";

// import constants
import SERVER_CONSTANTS from "../../constants/airstoreServer";

////////// REGISTER NEW CONNECTION REQUEST ////////////////////////////////////////////
export const updateConnectionRequest = (email) => {
  return async (dispatch, getState) => {
    const connectionUsername = getState().connections.connectionUsername;
    try {
      // STEP 1: REGISTER NEW CONNECTION REQUEST
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/connections/new",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            username: connectionUsername,
            email: email,
          }),
        }
      );

      if (response.status === 200) {
        dispatch({
          type: READY_FOR_LOGIN,
          signedUp: response.data.emailExists,
          userEmail: email,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

////////// CONNECT CHAIN TO INTEGRATION ////////////////////////////////////////////
export const setConnection = (connectionType, chainId) => {
  return async (dispatch, getState) => {
    const connectionUsername = getState().connections.connectionUsername;
    const token = getState().auth.token;
    try {
      dispatch({
        type: LOADING_CONNECTION,
      });
      // STEP 1: send connection request to server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/connections/connect",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            connectionType: connectionType,
            chainId: chainId,
            connectionUsername: connectionUsername,
          }),
        }
      );

      // STEP 2: If request verified update redux state
      dispatch({
        type: CHAIN_CONNECTED,
      });
      console.log("Retail chain is now connected");
    } catch (error) {
      console.log(error);
      dispatch({
        type: REQUEST_FAILED,
        errorMessage: "Connection failed",
      });
    }
  };
};

////////// CREATE NEW CHAIN FROM CONNECTION DETAILS ////////////////////////////////////////////
export const newChainFromConnection = () => {
  return async (dispatch, getState) => {
    const connectionUsername = getState().connections.connectionUsername;
    const token = getState().auth.token;
    try {
      dispatch({
        type: LOADING_CONNECTION,
      });
      // STEP 1: Send the request to server
      const response = await axios(
        SERVER_CONSTANTS.SERVER_URI + "/connections/chain-from-connection",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: JSON.stringify({
            username: connectionUsername,
          }),
        }
      );
      console.log("New retail chain created");
      // STEP 2: Fetch all chains from server
      await dispatch(chainsActions.fetchUserChains());
      const userChains = getState().chains.userRetailChains;
      const selectedChain = userChains.find(
        (chain) => chain.id === response.data.chainId
      );
      if (!selectedChain) {
        console.log("Oops, a problem occured");
        dispatch({
          type: REQUEST_FAILED,
          errorMessage: "Something went wrong. Please contact support",
        });
      } else {
        await dispatch(chainsActions.selectChain(selectedChain));
      }

      // STEP 2: If request verified update redux state
      dispatch({
        type: CHAIN_CONNECTED,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: REQUEST_FAILED,
        errorMessage: "Failed to create a chain from the connection details",
      });
    }
  };
};
