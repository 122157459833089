import React, { useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";

// IMPORT GLOBAL COMPONENTS
import CloseButton from "../../components/buttons/CloseButton";
import FlatButton from "../../components/buttons/FlatButton";
import Card from "../../components/containers/Card";
import Divider from "../../components/dividers/Divider";
import TextArea from "../../components/form/TextArea";
import SuccessBox from "../../components/containers/SuccessBox";
import AttentionBox from "../../components/containers/AttentionBox";

// IMPORT GLOBAL STYLES
import Styles from "../../styles/Styles";

// Importing Redux state components
import { useDispatch, connect } from "react-redux";
import * as supportActions from "../../redux/actions/support";

// Connect stores with the redux state
const mapStateToStore = (state) => {
  return {
    ticketSubmited: state.support.ticketSubmited,
    ticketId: state.support.ticketId,
    isError: state.support.isError,
    errorMessage: state.support.errorMessage,
  };
};

//// IMPORT FORM LIBS
import { Formik } from "formik";
import * as yup from "yup";

/// SET FORM VALIDATION SCHEMA
const ticketSchema = yup.object({
  message: yup
    .string()
    .min(15, "Please elaborate on how we can help")
    .max(500, "Too many characters, please be succinct")
    .required("Please describe how we can help"),
});

///////// START FUNCTION CLASS ///////////////////////
const NewTicketModal = (props) => {
  const dispatch = useDispatch();

  // GET SCREEN NAME FOR THE TICKET
  const navigation = useNavigation();
  const navigationState = navigation.getState();
  const currentScreen = navigationState.routes[navigationState.index];

  ////////////// RETURN VIEW /////////////////////
  return (
    <View style={styles.centeredModal}>
      <Card style={styles.modalBox}>
        <View style={Styles.row}>
          <View style={[Styles.col, { flex: 5 }]}>
            <Text style={Styles.H2}>Contact Support</Text>
          </View>
          <View style={[Styles.col, { flex: 1, alignItems: "flex-end" }]}>
            <CloseButton
              onClose={() => {
                props.onClose();
                dispatch(supportActions.resetTicket());
              }}
            />
          </View>
        </View>
        <Divider />

        {!props.ticketSubmited ? (
          <View style={{ width: "100%" }}>
            <Text style={[Styles.H5, { marginBottom: 10 }]}>
              Please let us know how can we help. We will get back to you no
              later than 24 hours.
            </Text>
            <Formik
              initialValues={{
                message: "",
              }}
              validationSchema={ticketSchema}
              onSubmit={async (values, { setSubmitting }) => {
                await dispatch(
                  supportActions.submitNewTicket(
                    values.message,
                    currentScreen.name
                  )
                );
                setSubmitting(false);
              }}
            >
              {(formikProps) => (
                <View style={styles.form}>
                  <TextArea
                    containerStyle={{ marginBottom: 10 }}
                    placeholder="Describe the issue"
                    onChangeText={formikProps.handleChange("message")}
                    value={formikProps.values.message}
                    onBlur={formikProps.handleBlur("message")}
                    errorMessage={
                      formikProps.touched.message && formikProps.errors.message
                    }
                    returnKeyType="done"
                  />
                  <View>
                    <FlatButton
                      onPress={formikProps.handleSubmit}
                      title="Submit"
                      isLoading={formikProps.isSubmitting}
                    />
                  </View>
                </View>
              )}
            </Formik>
          </View>
        ) : (
          <SuccessBox>
            <Text>Thank you for you vote of confidence!</Text>
            <Text>
              Our team will get back to you via email as soon as possible and no
              later than 24 hours.
            </Text>
            <Text>Support ticket: {props.ticketId}</Text>
          </SuccessBox>
        )}
        {props.isError ? (
          <AttentionBox>
            <Text>{props.errorMessage}</Text>
          </AttentionBox>
        ) : null}
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredModal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingHorizontal: "5%",
  },

  modalBox: {
    maxWidth: 600,
    alignItems: "flex-start",
    marginTop: 15,
  },

  form: {
    width: "100%",
    justifyContent: "flex-start",
  },
});

export default connect(mapStateToStore)(NewTicketModal);
