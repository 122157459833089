import React from "react";
import { StyleSheet, View, Text, TextInput } from "react-native";

// IMPORT GLOBAL STYLES
import Colors from "../../styles/Colors";
import Styles from "../../styles/Styles";
import Ionicons from "@expo/vector-icons/Ionicons";

///////////// START FUNCTION CLASS //////////////////
const BarcodeInput = (props) => {
  // UI element
  return (
    <View style={[Styles.col, props.boxStyle]}>
      <View style={[Styles.row]}>
        <View style={styles.unitBox}>
          <Ionicons name={"barcode-outline"} size={24} />
        </View>
        <View style={styles.valueBox}>
          <TextInput
            style={styles.textInput}
            keyboardType="number-pad"
            placeholder={props.placeholder}
            onChangeText={props.onChangeText}
            value={props.value}
            onBlur={props.onBlur}
            secureTextEntry={props.secureTextEntry}
          />
        </View>
      </View>
      <Text style={styles.errorMessage}>{props.errorMessage}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  unitBox: {
    borderColor: "#000000",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderStartWidth: 1,
    borderTopStartRadius: 6,
    borderBottomStartRadius: 6,
    paddingVertical: 6,
    width: 30,
    justifyContent: "center",
    alignItems: "center",
  },

  unit: {
    fontSize: 20,
    color: Colors.accent,
  },

  valueBox: {
    borderColor: "#000000",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderEndWidth: 1,
    backgroundColor: "#ffffff",
    borderTopEndRadius: 6,
    borderBottomEndRadius: 6,
    paddingVertical: 6,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 5,
    flex: 1,
  },

  textInput: {
    fontSize: 20,
    paddingStart: 5,
    width: "100%",
  },

  errorMessage: {
    color: "crimson",
    padding: 5,
    fontWeight: "bold",
  },
});

export default BarcodeInput;
