export default {
  // HEADERS

  H1: {
    fontSize: 20,
    fontWeight: "600",
  },

  H2: {
    fontSize: 18,
    fontWeight: "600",
  },

  H3: {
    fontSize: 16,
    fontWeight: "500",
  },

  H4: {
    fontSize: 14,
    fontWeight: "500",
  },

  H5: {
    fontSize: 16,
  },

  smallNote: {
    fontSize: 12,
    color: "#CCCCCC",
  },

  p: {
    fontSize: 14,
  },

  screenMessage: {
    fontSize: 28,
    color: "#b8b4b4",
    marginBottom: 10,
  },

  link: {
    fontWeight: 500,
    color: "#153fe8",
  },

  // GRIDS

  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },

  col: {
    padding: 2,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },

  // LISTS

  listContainer: {
    width: "100%",
    paddingHorizontal: 5,
    paddingVertical: 10,
  },

  // FORMS

  picker: {
    borderWidth: 1,
    borderColor: "#cccccc",
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "500",
  },

  pickerItem: {
    fontSize: 14,
    fontWeight: "500",
    height: 30,
  },
};
