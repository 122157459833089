import React from "react";
import { Switch } from "react-native";

const SimpleSwitch = (props) => {
  const toggleSwitch = () => {
    props.onChange(!props.switchState);
  };
  return (
    <Switch
      trackColor={{ false: "#cccccc", true: "#34c759" }}
      thumbColor="#f4f3f4"
      activeThumbColor="#f4f3f4"
      ios_backgroundColor="#f4f3f4"
      onValueChange={toggleSwitch}
      value={props.switchState}
    />
  );
};

export default SimpleSwitch;
