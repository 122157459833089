import React from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";

// IMPORT SCREENS
import ProductsScreen from "../screens/ProductsScreen";
import EditProductScreen from "../screens/EditProductScreen";

const Stack = createNativeStackNavigator();

let ProductsNavigator;
export default ProductsNavigator = (props) => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="AllProducts"
        component={ProductsScreen}
        options={{ title: "Manage Products | Airstore Cloud" }}
      />
      <Stack.Screen
        name="EditProduct"
        component={EditProductScreen}
        options={{ title: "Edit Product | Airstore Cloud" }}
      />
    </Stack.Navigator>
  );
};
